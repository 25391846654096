<template>
    <div>

        <table class="table table-bordered mb-none" id="datatable-details">
            <thead>
            <tr>
                <th style="pointer-events: none; cursor: default;">ID</th>
                <th style="width: 100px;">Прочитано</th>
                <th>Сообщение</th>
                <th>Создано</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="message in messages">
                <template v-if="message.in_range">
                <tr v-bind:class="{ 'readed': message.read}">
                    <td class="center">{{message.id}}</td>
                    <td class="center" ><input type="checkbox" @change="saveMessageRead(message)" v-model="message.read"></td>
                    <td class="center" style = "white-space: pre;">{{message.message}}</td>
                    <td class="center">{{message.created_at}}</td>
                </tr>
            </template>
            </template>
            </tbody>
        </table>

        <div class="form-group">
            <label class="col-md-3 control-label">Выбрать промежуток</label>
            <div class="col-md-8">
                <div class="input-daterange col-md-8 col-md-offset-1 input-group" data-plugin-datepicker>
														<span class="input-group-addon">
															<i class="fa fa-calendar"></i>
														</span>
                    <input class="form-control" name="start" id="start" type="text" v-model="start_date">
                    <span class="input-group-addon">до</span>
                    <input class="form-control" name="end" type="text" v-model="end_date">
                </div>
            </div>
        </div>
        <div class="col-md-5"></div>
        <button class="mb-xs mt-xs mr-xs btn btn-primary col-md-2 col-md-offset-5" @click="makeMagic1()" type="button">Показать за выбранный промежуток</button>


        <div id="loader"><span></span></div>
        <div id="mes-edit"></div>

    </div>
</template>
<script>//

export default {
    created() {

        this.getMessages();
        var date = new Date();
        date.setMonth(date.getMonth() - 1);
        this.start_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        date.setMonth(date.getMonth() + 1);
        this.end_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        localStorage.setItem('date0', this.start_date);
        localStorage.setItem('date1', this.end_date);

    },
    data() {
        return {
            messages: [],
            selected: 0,
            start_date: '',
            end_date: '',
        }
    },
    props:
        {
            path_axios_get_list_messages: {
                type: String,
                default: ''
            },
            path_axios_save_readed_messages: {
                type: String,
                default: ''
            },
        },
    store: ['range'],
    methods: {
        makeMagic1() {
            $('#datatable-details').DataTable().destroy();

            //console.log(localStorage.getItem('date0'));
            //console.log(localStorage.getItem('date1'));

            this.range['date0'] = localStorage.getItem('date0');
            this.range['date1'] = localStorage.getItem('date1');

            var day_start = new Date(localStorage.getItem('date0'));
            day_start.setDate(day_start.getDate() - 1);
            var day_end = new Date(localStorage.getItem('date1'));

            localStorage.setItem('date0', day_start);
            localStorage.setItem('date1', day_end);

            this.start_date = day_start.getFullYear() + '-' + ('0' + (day_start.getMonth()+1)).slice(-2) + '-' + ('0' + (day_start.getDate()+1)).slice(-2);
            this.end_date = day_end.getFullYear() + '-' + ('0' + (day_end.getMonth()+1)).slice(-2) + '-' + ('0' + day_end.getDate()).slice(-2);

            this.messages.forEach(function (item, i) {
                var show10 = 0;
                var show11 = 0;
                var mydate = new Date(item.created_at.slice(0, 11));
                //console.log(item.created_at.slice(0, 11));
                if (this.range['date0'] != 'ull' && day_start >= mydate) show10 = 0; else show10 = 1;
                if (this.range['date1'] != 'ull' && day_end <= mydate) show11 = 0; else show11 = 1;
                if (show10 == 1 && show11 == 1) item.in_range = true;
                else item.in_range = false;
                //console.log(show10, show11);
            }, this);



            /*            this.zakazs.forEach(function (item, i) {
                            //console.log(item.created_at.slice(0, 11));
                        });*/
            setTimeout(() => this.add_jquery(), 500);

        },
        getMessages() {
            axios.get(this.path_axios_get_list_messages).then(response => {
                response.data.forEach(function (item, i) {
                    item.in_range = true;
                    //item.message = item.message.replace(/\\n/g, '<br>');
                    //console.log(JSON.stringify(i, null, 4));
                });
                this.messages = response.data;
                //console.log(JSON.stringify(response.data, null, 4));
            })
                .then(response => {
                    //this.plus_minus();
                    setTimeout(() => this.add_jquery(), 700);
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },

        saveMessageRead(message){
            axios.post(this.path_axios_save_readed_messages, {
                data: message
            })
                .then(function (response) {
                    // handle success
                    //console.log(this.zakazs[id]);
                    //alert(this.zakazs[id]);
                    $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                        //alert(id);
                    });
                    console.log(response);
                })
                .catch(function (error) {
                    // handle error
                    $('#mes-edit1').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                        $('#mes-edit1').delay(1000).fadeOut();
                        //alert(id);
                    });
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        },

        add_jquery() {

            $('#datatable-details').DataTable({
                paging: true,
                aoColumnDefs: [{
                    bSortable: false,
                    aTargets: [0,1,2,3]
                }]
                ,
                aaSorting: [
                [3, 'desc']
            ]
            });
        },
    },
}

</script>
