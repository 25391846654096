<style>
.paid-details-td{
    padding: 0 !important;
}

.paid-details-table {
    width: 100%;
    margin: 0;
    padding: 0;
}

.paid-details-td-sub {
    border: 1px solid #dddddd;
    padding: 8px;
    line-height: 1.42857143;
}    
</style>

<template>
    <div>
        <template v-if="show_sel">
        <v-select :options="partners" @input="show_zakazs(selected)" class="form-control"
                  label="name"
                  placeholder="Введите как минимум 2 символа для поиска"
                  v-model="selected">
        </v-select>
        </template>

        <br>
        <br>
        <table :id="'datatable-details'" class="table table-bordered mb-none">
            <thead>
            <tr>
                <th></th>
                <th style="pointer-events: none; cursor: default;">ID</th>
                <th>Дата заказа</th>
                <th>Сумма вознаграждения</th>
                <th>Выплачен</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="zakaz in zakazs">
                <template v-if="zakaz.show">
                    <tr>
                    <td class="paid-details-td" colspan="5">
                    <table class="paid-details-table">
                    <tr v-bind:class="{'paid': zakaz.id_partner.id_status_partner_pay.name == 'Да', 'no_paid': zakaz.id_partner.id_status_partner_pay.name == 'Нет'}" style="padding: 0;">
                        <td class="text-center paid-details-td-sub" v-on:click="plus_minus(zakaz, zakaz.id_in_vue), zakaz.show = !zakaz.show" style="width: 6.5%;">
                            <i class="fa text-primary h5 m-none"
                                data-toggle
                                style="cursor: pointer;"
                                v-bind:class="{ 'fa-plus-square-o': zakaz.isPlus, 'fa-minus-square-o': zakaz.isMinus}">
                            </i>
                        </td>
                        <td class="paid-details-td-sub" style="width: 9.6%;">{{zakaz.id}}</td>
                        <td class="paid-details-td-sub" style="width: 28.3%">{{zakaz.created_at}}</td>
                        <td class="paid-details-td-sub" style="width: 38%;">{{zakaz.id_partner.sum}}</td>
                        <td class="paid-details-td-sub">{{zakaz.id_partner.id_status_partner_pay.name}}</td>
                    </tr>
                    <tr class="details">
                        <td class='details' colspan="11">
                            <table class="table mb-none">
                                <tbody>


                                <tr>
                                    <td>Состав заказа &darr;</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="2" width="100%">
                                        <table class="table-striped mb-none">
                                            <tr v-for="stuff in zakaz.stuff_in_zakaz">
                                                <td>{{stuff.name}} - <b> {{stuff.kolvo}} шт</b></td>
                                                <td>
                                                    <gallery :images="stuff.img1" :index="stuff.index"
                                                             @close="stuff.index = null"></gallery>
                                                    <div
                                                        :key="imageIndex"
                                                        :style="{ backgroundImage: 'url(' + image + ')', width: '120px', height: '120px' }"
                                                        @click="stuff.index = imageIndex"
                                                        class="image"
                                                        v-for="(image, imageIndex) in stuff.img1"
                                                    ></div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td></td>
                                    <td>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Всего &rarr; <b>{{zakaz.kolvo}} шт.</b></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td v-if="zakaz.id_partner.id_status_partner_pay.id == 4">Комментарий к заказу: {{zakaz.comments}}</td>
                                    <td v-else></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>


                                </tbody>
                            </table>
                        </td>
                    </tr>
                   </table></td></tr>
                </template>
                <template v-else>
                    <template v-if="zakaz.in_range">
                        <tr v-bind:class="{'paid': zakaz.id_partner.id_status_partner_pay.name == 'Да', 'no_paid': zakaz.id_partner.id_status_partner_pay.name == 'Нет'}">
                            <td class="text-center" v-on:click="plus_minus(zakaz, zakaz.id_in_vue), zakaz.show = !zakaz.show"><i
                                class="fa text-primary h5 m-none"
                                data-toggle
                                style="cursor: pointer;"
                                v-bind:class="{ 'fa-plus-square-o': zakaz.isPlus, 'fa-minus-square-o': zakaz.isMinus}"></i>
                            </td>
                            <td>{{zakaz.id}}</td>
                            <td>{{zakaz.created_at}}</td>
                            <td>{{zakaz.id_partner.sum}}</td>
                            <td>{{zakaz.id_partner.id_status_partner_pay.name}}</td>
                        </tr>
                    </template>
                </template>
            </template>
            </tbody>
        </table>

        <template v-if="show">

            <!--<div class="panel-body">
                <div class="col-md-3">
                    <span class="show-grid-block">Карта
                        <input @blur="onBlur()" @focus="onFocus()" class="form-control"
                               type="text"
                               v-model="selected.card_number">
                            </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">Яндекс
                        <input @blur="onBlur()" @focus="onFocus()" class="form-control"
                               type="text"
                               v-model="selected.yandex">
                            </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">Киви
                        <input @blur="onBlur()" @focus="onFocus()" class="form-control"
                               type="text"
                               v-model="selected.qiwi">
                    </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">PayPal
                        <input @blur="onBlur()" @focus="onFocus()" class="form-control"
                               type="text"
                               v-model="selected.paypal">
                    </span>
                </div>
            </div>-->

            <div class="panel-body">
                <div class="col-md-3">
                    <span class="show-grid-block">Всего выплачено: <b>{{paid}} руб.</b></span>
                </div>

                <div class="col-md-3">
                    <span class="show-grid-block">Остаток по вылатам: <b>{{not_paid}} руб.</b></span>
                </div>


            </div>

            <div class="panel-body">
                <button @click="pleasePayMe(selected)" class="mb-xs mt-xs mr-xs btn btn-primary" type="button">Запрос на выплату {{not_paid}} руб.
                </button>
            </div>


        </template>

        <br>
        <br>
        <br>
        <div class="form-group">
            <label class="col-md-3 control-label">Выбрать промежуток</label>
            <div class="col-md-8">
                <div class="input-daterange col-md-8 col-md-offset-1 input-group" data-plugin-datepicker>
														<span class="input-group-addon">
															<i class="fa fa-calendar"></i>
														</span>
                    <input class="form-control" name="start" id="start" type="text" v-model="start_date">
                    <span class="input-group-addon">до</span>
                    <input class="form-control" name="end" type="text" v-model="end_date">
                </div>
            </div>
        </div>
        <div class="col-md-5"></div>
        <button @click="makeMagic1()" class="mb-xs mt-xs mr-xs btn btn-primary" type="button">Показать</button>

        <div id="loader"><span></span></div>
        <div id="mes-edit"></div>

    </div>
</template>
<script>//

import VueGallery from 'vue-gallery'

Vue.component('vue-gallery', VueGallery);

export default {
    created() {
        this.getCurrentUser();

        var date = new Date();
        date.setMonth(date.getMonth() - 1);
        this.start_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        date.setMonth(date.getMonth() + 1);
        this.end_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        localStorage.setItem('date0', this.start_date);
        localStorage.setItem('date1', this.end_date);

    },
    components: {
        'gallery': VueGallery
    },
    store: ['range'],
    data() {
        return {
            partners: [],
            zakazs: [],
            selected: 0,
            percent: 0,
            partnerInfo: [],
            show: 0,
            paid: 0,
            not_paid: 0,
            stuff_to_zakaz: [],
            start_date: '',
            end_date: '',
            currentUser: [],
            show_sel: 0,
        }
    },
    props:
        {

            path_axios_save_updated_partner: {
                type: String,
                default: ''
            },

            path_axios_get_list_partner: {
                type: String,
                default: ''
            },
            path_axios_get_list_zakaz_of_partner: {
                type: String,
                default: ''
            },
            path_axios_get_list_zakaz_detailed: {
                type: String,
                default: ''
            },
            path_axios_pay_to_partner: {
                type: String,
                default: ''
            },
            path_axios_get_current_user: {
                type: String,
                default: ''
            },
        },
    methods: {
        makeMagic1() {
/*
	    if ( $.fn.DataTable.isDataTable('#datatable-details') ) {
  		$('#datatable-details').DataTable().destroy();
	    }
*/
            //$('#datatable-details').DataTable().destroy();

            this.range['date0'] = localStorage.getItem('date0');
            this.range['date1'] = localStorage.getItem('date1');

            var day_start = new Date(localStorage.getItem('date0'));
            //day_start.setDate(day_start.getDate() - 1);
            var day_end = new Date(localStorage.getItem('date1'));

            this.start_date = day_start.getFullYear() + '-' + ('0' + (day_start.getMonth()+1)).slice(-2) + '-' + ('0' + (day_start.getDate())).slice(-2);
            this.end_date = day_end.getFullYear() + '-' + ('0' + (day_end.getMonth()+1)).slice(-2) + '-' + ('0' + day_end.getDate()).slice(-2);
            day_end.setDate(day_end.getDate() + 1);

            //this.zakazs.forEach(function (item, i) {
            for (let key in this.zakazs) {
                var show10 = 0;
                var show11 = 0;
                var mydate = new Date(this.zakazs[key].zakaz_date);
                //console.log(mydate, day_start);
                if (this.range['date0'] != 'ull' && day_start >= mydate) show10 = 0; else show10 = 1;
                if (this.range['date1'] != 'ull' && day_end <= mydate) show11 = 0; else show11 = 1;
                if (show10 == 1 && show11 == 1) this.zakazs[key].in_range = true;
                else this.zakazs[key].in_range = false;
            //}, this);
            }

            //localStorage.setItem('date0', day_start);
            //localStorage.setItem('date1', day_end);

            //setTimeout(() => this.add_jquery(), 500);
            this.add_jquery();

        },

        getCurrentUser()
        {
            //console.log(JSON.stringify('response.data', null, 4));
            axios.get(this.path_axios_get_current_user).then(response => {
                //response.data.role == 1 ? item.show = true : item.show = false;
                this.currentUser = response.data;
                this.currentUser.role == 1 ? this.show_sel = true : this.show_sel = false;
                //console.log(JSON.stringify(this.currentUser.role, null, 4));
            })
                .then(response => {
                    this.getPartners();
                    console.log(response);
                })
                .catch(function (error) {
                    //alert(error.response);
                    console.log(error.response);
                });
        },

        getPartners() {
                axios.post(this.path_axios_get_list_zakaz_of_partner, {
                    data: this.currentUser.id
                })
                    .then(response => {
                        // handle success
                        this.partnerInfo = response.data['info'];
                        delete response.data['info'];

                        for (let key in response.data) {
                            response.data[key].isPlus = true;
                            response.data[key].isMinus = false;
                            response.data[key].in_range = true;
                            response.data[key].stuff_in_zakaz = [{
                                index: null,
                                img1: [response.data[key].tovar.info[0].img.split('\n')[0],],
                                id: response.data[key].tovar[0].id,
                                id_zakaz: response.data[key].tovar[0].id_zakaz,
                                id_partner: response.data[key].id_partner.id,
                                kolvo: response.data[key].kolvo,
                                name: response.data[key].tovar.info[0].name,
                            }]
                        }
                        //console.log(JSON.stringify(response.data, null, 4));
                        //this.zakazs = Object.entries(response.data);
                        this.zakazs = response.data;
                        this.percent = this.partnerInfo.percent;

                    })
                    .then(response => {
                        this.not_paid = 0;
                        this.paid = 0;
                        
                        for (let key in this.zakazs) {
                            if (this.zakazs[key].id_partner.id_status_partner_pay.name == "Нет") {
                                this.not_paid += this.zakazs[key].id_partner.sum;
                            }
                            if (this.zakazs[key].id_partner.id_status_partner_pay.name == "Да") {
                                this.paid += this.zakazs[key].id_partner.sum;
                            }

                        }

 //                       this.add_jquery();
                        this.makeMagic1();
                        console.log(response);
                    })
                    .catch(function (error) {
                        console.log(error.response);
                    });
            //alert(JSON.stringify(partner_name.id, null, 4));
        },

        onFocus() {
            this.old_partner = JSON.stringify(this.selected);
        },
        //записываем изменения в базу
        onBlur() {

            //alert(JSON.stringify(this.selected, null, 4));
            //var input = document.getElementById('dateInput');
            //alert(input.value);

            //console.log(id);

            if (this.old_partner != JSON.stringify(this.selected))
                axios.post(this.path_axios_save_updated_partner, {
                    data: this.selected
                })
                    .then(function (response) {
                        // handle success
                        //console.log(this.zakazs[id]);
                        //alert(this.zakazs[id]);
                        $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                            $('#mes-edit').delay(1000).fadeOut();
                            //alert(id);
                        });
                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        $('#mes-edit1').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                            $('#mes-edit1').delay(1000).fadeOut();
                            //alert(id);
                        });
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
        },

        pleasePayMe(partner_name) {

            if (!partner_name)
            this.partners.forEach(function (item, i) {
                //console.log(this.currentUser.id);
                if (item.id_backend_users.id == this.currentUser.id) {partner_name = item;}
            }, this);

            //alert(JSON.stringify(partner_name, null, 4));
            //var input = document.getElementById('dateInput');
            //alert(input.value);

            //console.log(partner_name.id);

            //if (partner_name)

            var id_zakazs_mas = [];
            this.zakazs.forEach(function (item, i) {
                if (item.id_partner.id_status_partner_pay.name == "Нет") {
                    id_zakazs_mas.push(item.id);
                }
            }, this);

            var send = {
                id: partner_name.id,
                money: this.not_paid,
                id_zakazs: id_zakazs_mas,
            };

            //console.log(JSON.stringify(send, null, 4));

            axios.post(this.path_axios_pay_to_partner, {
                data: send
            })
                .then(response => {
                    // handle success
                    //console.log(this.zakazs[id]);
                    //alert(this.zakazs[id]);
                    $('#mes-edit').text('Запрос на выплату отправлен').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                        //alert(id);
                    });
                    this.show_zakazs(partner_name);
                    console.log(response);
                })
                .catch(function (error) {
                    // handle error
                    $('#mes-edit1').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                        $('#mes-edit1').delay(1000).fadeOut();
                        //alert(id);
                    });
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        },

        add_jquery() {

    	    let dtable = $('#datatable-details');

            if ( $.fn.DataTable.isDataTable('#datatable-details') ) {
                dtable.DataTable().destroy();
            }

            setTimeout(()=> {
                dtable.DataTable({
                    paging: true,
                    bFilter: false,
                    aoColumnDefs: [{
                        bSortable: false,
                        aTargets: [0, 1, 2, 3, 4]
                    }]
                    ,
                    aaSorting: [
                        [1, 'desc']
                    ],
                    "bAutoWidth": false,
                    "bPaginate": true,
                    "bInfo": true,
                });
            }, 300);
        },            

        plus_minus(zakaz, id) {

            if (zakaz.isPlus == true) {
                zakaz.isPlus = false;
                zakaz.isMinus = true;
            } else {
                zakaz.isPlus = true;
                zakaz.isMinus = false;
            }

        },
    },
}

</script>
