<template>
    <div>
        <template v-if="show_sel">
        <v-select :options="providers" @input="show_tovars(selected.id_in_vue)" class="form-control"
                  label="name"
                  placeholder="Введите как минимум 2 символа для поиска"
                  v-model="selected">
        </v-select>
        </template>

        <br>
        <br>

        <template v-if="show">

            <table class="table table-bordered mb-none col-md-3">
                <tbody>
                <tr>
                    <th>Всего выплачено:</th>
                    <td><b>{{pay}} руб.</b></td>
                </tr>

                <tr>
                    <th>Остаток к выплате:</th>
                    <td><b>{{not_pay}} руб.</b></td>
                </tr>

                <tr>
                    <th>Отправлено на сумму:</th>
                    <td><b>{{send_all_pay}} руб.</b></td>
                </tr>

                <tr>
                    <th>Средний чек:</th>
                    <td><b>{{average}} руб.</b></td>
                </tr>

                <tr>
                    <th>Вернулось:</th>
                    <td><b>{{count_back}} шт.</b></td>
                </tr>

                <tr>
                    <th>Количество отправленного товара:</th>
                    <td><b>{{send}} шт.</b></td>
                </tr>

                <tr>
                    <th>Всего вернулось:</th>
                    <td><b>{{count_all_back}} шт.</b></td>
                </tr>

                </tbody>
            </table>
            <p>&nbsp;</p>
        </template>

        <br>

        <table class="table table-bordered mb-none" id="datatable-details" style="padding-top: 115px;">
            <thead>
            <tr>
                <th style="pointer-events: none; cursor: default;">ID</th>
                <th>Дата отправки</th>
                <th>Наименование</th>
                <th>Кол-во</th>
                <th>Размер</th>
                <th>Фото</th>
                <!--<th>Сумма к выплате</th>-->
                <th>Статус заказа</th>
                <th>Трек-номер</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="zakaz in zakazs">
                <template v-if="zakaz.in_range">
                    <tr v-bind:class="{ 'paid': zakaz.paid}">
                        <td>{{zakaz.id}}</td>
                        <td>{{zakaz.date}}</td>
                        <td>{{zakaz.name}}</td>
                        <td>{{zakaz.kolvo}}</td>
                        <td>{{zakaz.size}}</td>
                        <td>
                            <gallery :images="zakaz.img" :index="zakaz.index" @close="zakaz.index = null"></gallery>
                            <div
                                :key="imageIndex"
                                :style="{ backgroundImage: 'url(' + image + ')', width: '120px', height: '120px' }"
                                @click="zakaz.index = imageIndex"
                                class="image"
                                v-for="(image, imageIndex) in zakaz.img"
                            ></div>
                        </td>
                        <!--<td>{{zakaz.sum}}</td>-->
                        <td>{{zakaz.status}}</td>
                        <td>{{zakaz.track}}</td>
                    </tr>
                </template>
            </template>
            </tbody>
        </table>

        <div class="form-group">
            <label class="col-md-3 control-label">Выбрать промежуток</label>
            <div class="col-md-8">
                <div class="input-daterange col-md-8 col-md-offset-1 input-group" data-plugin-datepicker>
														<span class="input-group-addon">
															<i class="fa fa-calendar"></i>
														</span>
                    <input class="form-control" name="start" id="start" type="text" v-model="start_date">
                    <span class="input-group-addon">до</span>
                    <input class="form-control" name="end" type="text" v-model="end_date">
                </div>
            </div>
        </div>
        <div class="col-md-5"></div>
        <button @click="makeMagic1()" class="mb-xs mt-xs mr-xs btn btn-primary col-md-2 col-md-offset-5" type="button">Показать за выбранный
            промежуток
        </button>


        <div id="loader"><span></span></div>
        <div id="mes-edit"></div>
        <div id="mes-edit1"></div>

    </div>
</template>
<script>//

import VueGallery from 'vue-gallery'

Vue.component('vue-gallery', VueGallery);

export default {
    created() {
        this.getCurrentUser();
        var date = new Date();
        date.setMonth(date.getMonth() - 1);
        this.start_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        date.setMonth(date.getMonth() + 1);
        this.end_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        localStorage.setItem('date0', this.start_date);
        localStorage.setItem('date1', this.end_date);

    },
    components: {
        'gallery': VueGallery
    },
    data() {
        return {
            providers: [],
            zakazs: [],
            selected: 0,
            show: 0,
            send: 0,
            pay: 0,
            not_pay: 0,
            send_all_pay: 0,
            average: 0,
            start_date: '',
            end_date: '',
            currentUser: [],
            show_sel: 0,
            count_back: 0,
            count_all_back: 0,
        }
    },
    props:
        {

            path_axios_save_updated_zakaz_of_provider: {
                type: String,
                default: ''
            },

            path_axios_get_list_provider: {
                type: String,
                default: ''
            },
            path_axios_get_current_user: {
                type: String,
                default: ''
            },
        },
    store: ['range'],
    methods: {
        setDate(id) {
            $("#dateInput" + id).datepicker({}).on("changeDate", function (e) {
                var event = new Event('input');
                this.dispatchEvent(event);
            });
            this.onBlur(id);
        },

        makeMagic1() {
            $('#datatable-details').DataTable().destroy();

            //console.log(localStorage.getItem('date0'));
            //console.log(localStorage.getItem('date1'));

            this.range['date0'] = localStorage.getItem('date0');
            this.range['date1'] = localStorage.getItem('date1');

            var day_start = new Date(localStorage.getItem('date0'));
            var day_end = new Date(localStorage.getItem('date1'));

            this.start_date = day_start.getFullYear() + '-' + ('0' + (day_start.getMonth() + 1)).slice(-2) + '-' + ('0' + (day_start.getDate())).slice(-2);
            this.end_date = day_end.getFullYear() + '-' + ('0' + (day_end.getMonth() + 1)).slice(-2) + '-' + ('0' + day_end.getDate()).slice(-2);
            day_start.setDate(day_start.getDate() - 1);

            this.pay = 0;
            this.not_pay = 0;
            this.send = 0;
            this.send_all_pay = 0;
            this.count_back = 0;
            this.count_all_back = 0;
            //this.send_all_pay = 0;
            this.zakazs.forEach(function (item, i) {
                var show10 = 0;
                var show11 = 0;
                var mydate = new Date(item.date);
                //console.log(mydate, day_start);
                if (this.range['date0'] != 'ull' && day_start >= mydate) show10 = 0; else show10 = 1;
                if (this.range['date1'] != 'ull' && day_end <= mydate) show11 = 0; else show11 = 1;
                if (show10 == 1 && show11 == 1) {
                    item.in_range = true;
                    //console.log(JSON.stringify(item, null, 4));
                    if (item.fake != 1)
                        if (item.paid == true) this.pay += item.sum;
                        else if (item.back != 1) this.not_pay += item.sum;
                    this.send += item.kolvo;
                    if (item.back == 1) this.count_back++;
                } else {
                    item.in_range = false;
                    //this.send += item.kolvo;
                }
                if (item.back == 1) this.count_all_back++;
            }, this);

            this.send_all_pay = this.pay + this.not_pay;
            if (this.send) this.average = Math.round(this.send_all_pay / this.send);
            else this.average = 0;
            /*            this.zakazs.forEach(function (item, i) {
                            //console.log(item.created_at.slice(0, 11));
                        });*/

            //this.start_date = day_start.getFullYear() + '-' + ('0' + (day_start.getMonth()+1)).slice(-2) + '-' + ('0' + (day_start.getDate())).slice(-2);
            //this.end_date = day_end.getFullYear() + '-' + ('0' + (day_end.getMonth()+1)).slice(-2) + '-' + ('0' + day_end.getDate()).slice(-2);

            //localStorage.setItem('date0', day_start);
            //localStorage.setItem('date1', day_end);

            setTimeout(() => this.add_jquery(), 1000);

        },

        getCurrentUser()
        {
            //console.log(JSON.stringify('response.data', null, 4));
            axios.get(this.path_axios_get_current_user).then(response => {
                //response.data.role == 1 ? item.show = true : item.show = false;
                this.currentUser = response.data;
                this.currentUser.role == 1 ? this.show_sel = true : this.show_sel = false;
                //console.log(JSON.stringify(this.currentUser.role, null, 4));
            })
                .then(response => {
                    this.getProviders();
                    console.log(response);
                })
                .catch(function (error) {
                    //alert(error.response);
                    console.log(error.response);
                });
        },

        getProviders() {
            axios.get(this.path_axios_get_list_provider).then(response => {
                response.data.forEach(function (item, i) {
                    item.selected = '0';
                    item.id_in_vue = i;
                });
                this.providers = [];
                this.providers = response.data;
                //console.log(JSON.stringify(this.providers[0], null, 4));
            })
                .then(response => {
                    this.providers.forEach(function (item, i) {
                        //console.log(item.id, this.currentUser.id, item.id_in_vue);
                        if (item.id == this.currentUser.id) {this.show_tovars(item.id_in_vue);}
                    }, this);
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
            //this.show = 0;
        },
        show_tovars(provider_id_in_vue) {

            this.show = 1;
            this.zakazs = [];
            $('#datatable-details').DataTable().destroy();

            this.send = 0;
            this.pay = 0;
            this.not_pay = 0;
            this.send_all_pay = 0;

            let fuck = 0;

            //console.log(JSON.stringify(this.providers[provider_id_in_vue], null, 4));
            this.providers[provider_id_in_vue].provider_tovar.forEach(function (item, i) {
                //console.log(JSON.stringify(item, null, 4));

                //this.count_all_back = item['count_all_back'];
                //delete item['count_all_back'];

                item.tovar_zakaz.forEach(function (item1, i) {
                     {
                         //if (item.tovar_zakaz)
                        //сonsole.log(JSON.stringify(item1.id_zakaz, null, 4));
                        if (item1.id_zakaz.id_status.id == 14) var status = 'Выплачен';
                        else var status = item1.id_zakaz.id_status.status_name;

                        if (status == 'Выплачен') {
                            var paid = true;
                        } else paid = false;

                            this.zakazs.push({
                                id: item1.id_zakaz.id,
                                date: item1.id_zakaz.created_at.slice(0, 11),
                                track: item1.id_zakaz.track_number,
                                name: item.id_tovar.name,
                                kolvo: item1.kolvo,
                                img: [item.id_tovar.img.split('\n')[0],],
                                index: null,
                                status: status,
                                sum: item1.kolvo * item.id_tovar.price * (100 - item1.id_zakaz.skidka) / 100 * this.providers[provider_id_in_vue].prov_percent / 100,
                                id_tov_zak: item1.id,
                                size: item1.size,
                                paid: paid,
                                in_range: true,
                                fake: item1.id_zakaz.already_fake,
                                back: item1.id_zakaz.already_back,
                                //pay_status == 'Выплачено' ?
                            });
                            //console.log(JSON.stringify([item.id_tovar.img.split('\n')[0],], null, 4));
                            fuck += item1.kolvo;
                            this.send += item1.kolvo;
                            this.send_all_pay += item1.kolvo * item.id_tovar.price * (100 - item1.id_zakaz.skidka) / 100 * this.providers[provider_id_in_vue].prov_percent / 100;
                            if (item1.id_zakaz.id_status.id == 14)
                                this.pay += item1.kolvo * item.id_tovar.price * (100 - item1.id_zakaz.skidka) / 100 * this.providers[provider_id_in_vue].prov_percent / 100;
                            else
                                this.not_pay += item1.kolvo * item.id_tovar.price * (100 - item1.id_zakaz.skidka) / 100 * this.providers[provider_id_in_vue].prov_percent / 100;
                        //else fuck += item1.kolvo;
                    }
                }, this);
            }, this);
            //console.log(JSON.stringify(this.send, null, 4));
            //console.log(JSON.stringify(this.send_all_pay, null, 4));
            if (fuck) this.average = Math.round(this.send_all_pay / fuck);
            else this.average = 0;

            //this.average = (this.send_all_pay/this.send).toFixed(2);



            //console.log(JSON.stringify(this.providers[provider_id_in_vue], null, 4));
            setTimeout(() => this.add_jquery(), 1000);
            setTimeout(() => this.makeMagic1(), 1000);
            //this.makeMagic1();
        },

        add_jquery() {

            $('#datatable-details').DataTable({
                paging: true,
                aoColumnDefs: [{
                    bSortable: false,
                    aTargets: [0, 1, 2, 3, 4, 5, 6, 7]
                }],
                aaSorting: [
                    [1, 'desc']
                ]
            });

        },
    },
}

</script>
