

<template>
    <div>


        <div class="row">
            <div class="col-md-12  row-margin">
                <button @click="filter()" class="mb-xs mt-xs mr-xs btn btn-primary col-md-2 col-md-offset-5" type="button">Отфильтровать</button>
            </div>
            <div class="col-md-6 to-middle row-margin">
                <div class="dataTables_filter">
                    <input type="search" class="form-control" placeholder="Поиск" @input="searching_in_base()" v-model="searchString">
                </div>
            </div>
        </div>

        <div class="row no-margin">
            <div class="header_tab">
                <div style="display: inline-block;">
                    <select class="select" v-model="pageSize" @change="changePageSize()">
                        <option value="10">10</option>
                        <option value="25" checked>25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="all">Все</option>
                    </select>
                </div>
                <div style="display: inline-block;">записей на странице</div>
            </div>

            <div class="header_tab">
                <div class="dataTables_paginate paging_bs_normal" id="datatable-details_paginate">
                    <ul class="pagination no-margin">
                        <li class="prev disabled">
                            <button @click="prevPage" :disabled="pageNumber==1" class="fa fa-chevron-left"></button>
                        </li>
                        <template v-for="i in 3">
                            <li v-if="i + pageNumber - 1 <= pageQnt" @click="getNPage(i + pageNumber - 1)" :class="{ active: i==1 }" :disabled="i==1" >
                                <a href="#">{{ i + pageNumber - 1 }}</a>
                            </li>
                        </template>
                        <li class="next">
                            <button @click="nextPage" :disabled="pageNumber>=pageQnt-2" class="fa fa-chevron-right"></button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <table class="table table-bordered mb-none" id="datatable-details">
            <thead>
            <tr>
                <th></th>
                <th style="pointer-events: none; cursor: default;">ID</th>
                <th>Дата</th>
                <th>Статус</th>
                <th>Стоим. посылки</th>
                <th>Почтовые расходы</th>
                <th>Кол-во товара</th>
                <th>Служба доставки</th>
                <th class="center"><i class="fa fa-envelope-o"></i></th>
                <th>Трек-номер</th>
                <th class="center"><i class="fa fa-plus-square"></i></th>
                <th>ЧС</th>
                <th>Ист.</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="zakaz in all_zakazs">
                <template v-if="!zakaz.no_show">
                    <template v-if="zakaz.in_range">
                        <template v-if="zakaz.show">
                            <tr v-bind:class="{ 'problem': zakaz.zakaz_problem, 'done': zakaz.zakaz_done}">
                                <td class="td-small-field" v-on:click="plus_minus(zakaz)"
                                    :style="{ backgroundColor: colorBgLine(zakaz), color: colorText(zakaz) }">
                                    <i class="fa text-primary h5 m-none" data-toggle style="cursor: pointer;"
                                        :class="{ 'fa-plus-square-o': zakaz.isPlus, 'fa-minus-square-o': zakaz.isMinus}">
                                    </i>
                                </td>

                                <td class="td-middle-field" :style="{ backgroundColor: colorBgLine(zakaz), color: colorText(zakaz) }">
                                    {{zakaz.id}}
                                </td>

                                <td class="td-biggest-field" :style="{ backgroundColor: colorBgLine(zakaz), color: colorText(zakaz) }">
                                    {{zakaz.created_at}}
                                </td>

                                <td class="td-big-field" :style="{ backgroundColor: colorBgLine(zakaz), color: colorText(zakaz, 1) }">
                                    <select @change="onBlur(zakaz.id_in_vue)" class="form-control1" v-model="zakaz.id_status">
                                        <option :value="select.id" v-for="select in selects_status_name">{{select.status}}</option>
                                    </select>
                                </td>

                                <td  class="center td-middle-field" :style="{ backgroundColor: colorBgLine(zakaz), color: colorText(zakaz) }">
                                    {{Number(zakaz.sum) * (100 - +(zakaz.skidka)) / 100 + Number(zakaz.pochta_costs)}}
                                </td>

                                <td class="td-big-field">
                                    <input style="width:100%;" @blur="onBlur(zakaz.id_in_vue)" @focus="onFocus(zakaz.id_in_vue)" 
                                        class="form-control1" v-model="zakaz.pochta_costs">
                                </td>

                                <td class="td-middle-field">
                                    <input class="center" v-model="zakaz.kolvo">
                                </td>

                                <td class="td-biggest-field" >
                                    <select @change="onBlur(zakaz.id_in_vue)" class="form-control1" v-model="zakaz.delivery">
                                        <option :value="select.id" v-for="select in selects_delivery">{{select.name}}</option>
                                    </select>
                                </td>

                                <td class="td-small-field" v-bind:class="{ 'mail': zakaz.already_send }">
                                    <input  type="checkbox" @change="onBlur(zakaz.id_in_vue)" v-model="zakaz.already_send">
                                </td>

                                <td class="td-biggest-field">
                                    <input style = "width: 100%;" @blur="onBlur(zakaz.id_in_vue)" @focus="onFocus(zakaz.id_in_vue)" class="form-control1" v-model="zakaz.track_number">
                                </td>

                                <td class="td-small-field" v-bind:class="{ 'combined': zakaz.combined }">
                                    <input type="checkbox" v-model="zakaz.to_combine">
                                </td>

                                <td class="td-small-field" v-bind:class="{ 'black_list': zakaz.black_list }">
                                    <input @change="onBlur(zakaz.id_in_vue)"  type="checkbox" v-model="zakaz.black_list">
                                </td>

                                <td class="td-small-field" v-bind:class="{ 'history': zakaz.history }"></td>

                            </tr>
                            <tr class="details">
                                <td class='details' colspan="14">
                                    <table class="table mb-none">
                                        <tbody>
                                            <tr>
                                                <td>Состав заказа &darr;</td>
                                                <td></td>
                                                <td>Данные клиента &darr;</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td width="40%">
                                                    <ol type="1">
                                                        <li v-for="stuff in zakaz.stuff">

                                                            <br>
                                                            <br>
                                                            <template v-if="zakaz.editing">
                                                            <table class="table-bordered">
                                                                <tr>
                                                                    <td colspan="4">
                                                                        <span class="show-grid-block" style="padding-left: 5px;">Цена: <b>{{products.find(item => item.id == stuff.id_tov).price || 0}}</b> руб., Стоимость: <b>{{products.find(item => item.id == stuff.id_tov).price * stuff.kolvo || 0}}</b> руб.</span>
                                                                        <div class="input-group btn-group"
                                                                            style="width: 386px; top: 6px; margin-left: 5px; margin-right: 5px;">
                                                                    <span class="input-group-addon">
                                                                        <i class="fa fa-shopping-cart"></i>
                                                                    </span>
                                                                            <v-select :options="products" @input="connect(stuff, zakaz)" class="form-control populate"
                                                                                    label="name"
                                                                                    placeholder="Введите как минимум 2 символа для поиска"
                                                                                    v-model="stuff.name">
                                                                            </v-select>
                                                                        </div>
                                                                        <br>
                                                                        <!--
                                                                                                        @input="plusp(newprod.id_on_page)"-->


                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="padding-left: 5px;">Количество</td>
                                                                    <td style="width: 20px;"></td>
                                                                    <td style="padding-left: 5px;">Фото</td>
                                                                    <td></td>
                                                                </tr>

                                                                    <tr>
                                                                        <td style="padding-left: 5px;">
                                                                            <div class="input-group form-control1" style="width:150px;">
                                                                                <input :value="stuff.kolvo"
                                                                                    maxlength="3" readonly style="float: left;" type="text">
                                                                                <div class="spinner-buttons input-group-btn"
                                                                                    style="top: -7px; position: relative; left: 15px;">
                                                                                    <button @click="plus(zakaz, stuff), onBlur(zakaz.id_in_vue)"
                                                                                            class="btn btn-default spinner-up"
                                                                                            style="border: 1px solid #af7171; line-height: 1.31;"
                                                                                            type="button">
                                                                                        <i class="fa fa-angle-up"></i>
                                                                                    </button>
                                                                                    <button @click="minus(zakaz, stuff), onBlur(zakaz.id_in_vue)"
                                                                                            class="btn btn-default spinner-down"
                                                                                            style="border: 1px solid #af7171; line-height: 1.31;"
                                                                                            type="button">
                                                                                        <i class="fa fa-angle-down"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td></td>
                                                                        <td rowspan="3">
                                                                            <gallery :images="[products.find(item => item.id == stuff.id_tov).img]" :index="stuff.index" @close="stuff.index = null"></gallery>
                                                                            <div
                                                                                :key="imageIndex"
                                                                                :style="{ backgroundImage: 'url(' + image + ')', width: '100px', height: '100px' }"
                                                                                @click="stuff.index = imageIndex"
                                                                                class="image"
                                                                                v-for="(image, imageIndex) in [products.find(item => item.id == stuff.id_tov).img]"
                                                                            ></div>
                                                                        </td>
                                                                        <td>

                                                                            <div class="col-md-2"><span class="show-grid-block"><br><button
                                                                                @click="delProduct(zakaz, stuff.id_in_vue), onBlur(zakaz.id_in_vue)"
                                                                                class="mb-xs mt-xs mr-xs btn btn-xs btn-primary"
                                                                                type="button">Удалить товар</button></span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="padding-left: 5px;">Размер</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="padding-left: 5px; padding-bottom: 5px; padding-top: 6px;">
                                                                            <input @blur="onBlur(zakaz.id_in_vue)"
                                                                                @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                                                style="width: 150px;"
                                                                                v-model="stuff.size">
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>

                                                            </table>
                                                            </template>

                                                            <template v-else>
                                                                <table class="table-bordered">
                                                                    <tr>
                                                                        <td colspan="4">
                                                                            <span class="show-grid-block" style="padding-left: 5px;">Цена: <b>{{products.find(item => item.id == stuff.id_tov).price || 0}}</b> руб., Стоимость: <b>{{products.find(item => item.id == stuff.id_tov).price * stuff.kolvo || 0}}</b> руб.</span>
                                                                            <div class="input-group btn-group"
                                                                                style="width: 386px; top: 6px; margin-left: 5px; margin-right: 5px;">
                                                                                <input class="form-control1" readonly
                                                                                    style="width: 300px;"
                                                                                    v-model="products.find(item => item.id == stuff.id_tov).name">
                                                                            </div>
                                                                            <br>

                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="padding-left: 5px;">Количество</td>
                                                                        <td style="width: 20px;"></td>
                                                                        <td style="padding-left: 5px;">Фото</td>
                                                                        <td></td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td style="padding-left: 5px;">
                                                                            <input :value="stuff.kolvo" class="form-control1" readonly
                                                                                style="width:150px;float: left;">
                                                                        </td>
                                                                        <td></td>
                                                                        <td rowspan="3">
                                                                            <gallery :images="[products.find(item => item.id == stuff.id_tov).img]" :index="stuff.index" @close="stuff.index = null"></gallery>
                                                                            <div
                                                                                :key="imageIndex"
                                                                                :style="{ backgroundImage: 'url(' + image + ')', width: '100px', height: '100px' }"
                                                                                @click="stuff.index = imageIndex"
                                                                                class="image"
                                                                                v-for="(image, imageIndex) in [products.find(item => item.id == stuff.id_tov).img]"
                                                                            ></div>
                                                                        </td>
                                                                        <td>

                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="padding-left: 5px;">Размер</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="padding-left: 5px; padding-bottom: 5px; padding-top: 6px;">
                                                                            <input class="form-control1" readonly
                                                                                style="width: 150px;"
                                                                                v-model="stuff.size">
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>

                                                                </table>
                                                            </template>

                                                            <br>
                                                        </li>
                                                    </ol>
                                                    <div class="panel-body">
                                                        <div class="col-md-6">
                                                            <button @click="addProduct(zakaz)" class="mb-xs mt-xs mr-xs btn btn-xs btn-primary" type="button">Добавить ещё
                                                                товар
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td></td>
                                                <template v-if="zakaz.id_client.foreign_adr == null">

                                                    <td class="row-addr-block">

                                                        <table class="table-striped mb-none addr-block" width="100%">
                                                            <tr>
                                                                <td class="td-address">ФИО:</td>
                                                                <td>&nbsp</td>
                                                                <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                                        @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                                        v-model="zakaz.id_client.name_1">
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="td-address">Телефон:</td>
                                                                <td>&nbsp</td>
                                                                <td>
                                                                    <input @blur="onBlur(zakaz.id_in_vue)"
                                                                        @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                                        v-model="zakaz.id_client.tel">
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td colspan="3">
                                                                    <i :class="zakaz.id_client.editor == false ? 'fa fa-pencil addr-edit' : 'fa fa-times addr-edit'" 
                                                                        @click="zakaz.id_client.editor=!zakaz.id_client.editor"></i>
                                                                </td>
                                                            </tr>

                                                            <template v-if="zakaz.id_client.editor == false">
                                                                <tr>
                                                                    
                                                                    <td class="td-address">Адрес:</td>
                                                                    <td>&nbsp</td>
                                                                    <textarea class="addr-edit-textarea" name="text" rows="6" readonly>{{
                                                                         (
                                                                           ( zakaz.id_client.index_adr !== null && zakaz.id_client.index_adr != '0' ? zakaz.id_client.index_adr + " " : "") +
                                                                           ( zakaz.id_client.city !== null ? zakaz.id_client.city + " " : "") + 
                                                                           ( zakaz.id_client.street !== null ? zakaz.id_client.street + " " : "") + 
                                                                           ( zakaz.id_client.house !== null ? zakaz.id_client.house + " " : "") + 
                                                                           ( zakaz.id_client.stroenie !== null ? zakaz.id_client.stroenie + " " : "") + 
                                                                           ( zakaz.id_client.flat !== null ? zakaz.id_client.flat : "")
                                                                         ).trim() 
                                                                        
                                                                    }}</textarea>
                                                                </tr>
                                                            </template>
                                                            <template v-else>
                                                                <tr>
                                                                    <td class="td-address">Город:</td>
                                                                    <td>&nbsp</td>
                                                                    <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                                            @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                                            v-model="zakaz.id_client.city"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="td-address">Улица:</td>
                                                                    <td>&nbsp</td>
                                                                    <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                                            @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                                            v-model="zakaz.id_client.street">
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="td-address">Дом:</td>
                                                                    <td>&nbsp</td>
                                                                    <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                                            @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                                            v-model="zakaz.id_client.house"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="td-address">Строение:</td>
                                                                    <td>&nbsp</td>
                                                                    <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                                            @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                                            v-model="zakaz.id_client.stroenie">
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="td-address">Квартира:</td>
                                                                    <td>&nbsp</td>
                                                                    <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                                            @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                                            v-model="zakaz.id_client.flat"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="td-address">Индекс:</td>
                                                                    <td>&nbsp</td>
                                                                    <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                                            @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                                            v-model="zakaz.id_client.index_adr">
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                        </table>

                                                    </td>
                                                </template>

                                                <template v-else>
                                                    <td class="row-addr-block">
                                                        <table class="table-striped mb-none addr-block" width="100%">
                                                            <tr>
                                                                <td class="td-address">ФИО:</td>
                                                                <td>&nbsp</td>
                                                                <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                                        @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                                        v-model="zakaz.id_client.name_1">
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="3">
                                                                    <i :class="zakaz.id_client.editor == false ? 'fa fa-pencil addr-edit' : 'fa fa-times addr-edit'" 
                                                                        @click="zakaz.id_client.editor=!zakaz.id_client.editor"></i>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <template v-if="zakaz.id_client.editor == false">
                                                                    <td class="td-address">Адрес (иностранный заказ):</td>
                                                                    <td>&nbsp</td>
                                                                    <td>
                                                                        <textarea rows="6" class="addr-edit-textarea" name="text" readonly>{{
                                                                                ( zakaz.id_client.foreign_adr ).trim() 
                                                                        }}</textarea>
                                                                    </td>
                                                                </template>
                                                                <template v-else>
                                                                    <td>Адрес (иностранный заказ)</td>
                                                                    <td>&nbsp</td>
                                                                    <td>
                                                                        <textarea rows="6" @blur="onBlur(zakaz.id_in_vue)"
                                                                            @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                                            v-model="zakaz.id_client.foreign_adr">{{
                                                                                ( zakaz.id_client.foreign_adr ).trim()
                                                                            }}</textarea>
                                                                    </td>
                                                                </template>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </template>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Всего &rarr; <b>{{zakaz.kolvo}} шт.</b></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Комментарий к заказу &darr;</td>

                                            </tr>

                                            <tr>
                                                <td><b>Комментарий к заказу</b>
                                                    <textarea class="form-control1" @blur="onBlur(zakaz.id_in_vue)" @focus="onFocus(zakaz.id_in_vue)" cols="45"
                                                            name="text" rows="10" v-model="zakaz.comments"></textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table class="table mb-none" v-if="zakaz.history">
                                        <tbody>


                                        <tr>
                                            <td>Прошлые заказы &darr;</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td width="100%">
                                                <table class="table-striped mb-none">
                                                    <template v-for="zakaz1 in zakaz.history1"
                                                            v-if="zakaz.id != zakaz1.id">
                                                    <tr style="border: 1px solid  #555555;;">
                                                        <td>ID: {{zakaz1.id}}</td>
                                                        <td>Сумма заказа: {{zakaz1.sum * (100 - zakaz1.skidka) / 100}} руб.</td>
                                                        <td>&nbsp</td>
                                                        <td width="40%">
                                                            <ol type="1">
                                                                <li v-for="stuff in zakaz1.stuff">
                                                                    {{findElement(stuff, 'name', 'none')}} - <b> {{stuff.kolvo}} шт</b>

                                                                </li>
                                                            </ol>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Дата заказа: </td>
                                                        <td>{{zakaz1.info.created_at}}</td>
                                                        <td>&nbsp</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Тип доставки: </td>
                                                        <td v-if="zakaz1.info.delivery !== null && zakaz1.info.delivery > 0">{{selects_delivery[zakaz1.info.delivery-1].name}}</td>
                                                        <td v-else>НЕ ОТПРАВЛЕН</td>
                                                        <td>&nbsp</td>
                                                        <td v-if="zakaz1.info.delivery !== null">Трек #: {{zakaz1.info.track_number}}</td>
                                                        <td v-else></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 280px;">Статус заказа: </td>
                                                        <td><b>{{selects_status_name[zakaz1.id_status].status}}</b></td>
                                                        <td>&nbsp</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="vertical-align:top;">Комментарий к заказу:</td>
                                                        <td colspace="3" v-if="zakaz1.comments !== null">
                                                            <textarea cols="45" name="text" rows="10" v-model="zakaz1.comments"></textarea>
                                                        </td>
                                                        <td v-else>НЕТ КОММЕНТАРИЕВ</td>
                                                    </tr>
                                                    </template>
                                                </table>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td>


                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                        </tr>


                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </template>


                        <template v-else>
                            <tr v-bind:class="{ 'problem': zakaz.zakaz_problem, 'done': zakaz.zakaz_done}">
                                <td class="td-small-field" v-on:click="plus_minus(zakaz)" 
                                    :style="{ backgroundColor: colorBgLine(zakaz), color: colorText(zakaz) }">
                                    <i class="fa text-primary h5 m-none" data-toggle style="cursor: pointer;"
                                        v-bind:class="{ 'fa-plus-square-o': zakaz.isPlus, 'fa-minus-square-o': zakaz.isMinus}">
                                    </i>
                                </td>

                                <td class="td-middle-field" :style="{ backgroundColor: colorBgLine(zakaz), color: colorText(zakaz) }">{{zakaz.id}}
                                    <span style="display: none;">
                                        {{zakaz.id_client.name_1}}
                                        {{zakaz.track_number}}
                                        {{zakaz.delivery}}
                                            <li v-for="stuff in zakaz.stuff">
                                                {{stuff.name}}
                                            </li>
                                    </span>
                                </td>

                                <td class="td-biggest-field" :style="{ backgroundColor: colorBgLine(zakaz), color: colorText(zakaz) }">
                                    {{zakaz.created_at}}
                                </td>

                                <td class="td-big-field" :style="{ backgroundColor: colorBgLine(zakaz), color: colorText(zakaz) }">
                                        <select @change="onBlur(zakaz.id_in_vue)" class="form-control1" v-model="zakaz.id_status">
                                            <option :value="select.id" v-for="select in selects_status_name">{{select.status}}</option>
                                        </select>
                                </td>

                                <td class="center td-middle-field" :style="{ backgroundColor: colorBgLine(zakaz), color: colorText(zakaz) }">
                                    {{Number(zakaz.sum) * (100 - +(zakaz.skidka)) / 100 + Number(zakaz.pochta_costs)}}
                                </td>

                                <td class="td-big-field">
                                    <input style="width:100%;" @blur="onBlur(zakaz.id_in_vue)" @focus="onFocus(zakaz.id_in_vue)" class="form-control1" v-model="zakaz.pochta_costs">
                                </td>

                                <td class="td-middle-field">
                                    <input class="center" v-model="zakaz.kolvo">
                                </td>

                                <td class="td-biggest-field">
                                    <select @change="onBlur(zakaz.id_in_vue)" class="form-control1" v-model="zakaz.delivery">
                                        <option :value="select.id" v-for="select in selects_delivery">{{select.name}}</option>
                                    </select>
                                </td>

                                <td class="td-small-field" v-bind:class="{ 'mail': zakaz.already_send }">
                                    <input type="checkbox" @change="onBlur(zakaz.id_in_vue)" v-model="zakaz.already_send">
                                </td>

                                <td class="td-biggest-field">
                                    <input style = "width: 100%;" @blur="onBlur(zakaz.id_in_vue)" @focus="onFocus(zakaz.id_in_vue)" class="form-control1" v-model="zakaz.track_number">
                                </td>

                                <td class="td-small-field" v-bind:class="{ 'combined': zakaz.combined }">
                                    <input  type="checkbox" v-model="zakaz.to_combine">
                                </td>

                                <td class="td-small-field" v-bind:class="{ 'black_list': zakaz.black_list }">
                                    <input @change="onBlur(zakaz.id_in_vue)" type="checkbox" v-model="zakaz.black_list">
                                </td>

                                <td class="td-small-field" v-bind:class="{ 'history': zakaz.history }"></td>
                            </tr>
                        </template>
                    </template>
                </template>
            </template>
            </tbody>
        </table>
 
        <div class="row datatables-footer">
            <div class="col-sm-12 col-md-6">
                <div class="dataTables_info" id="datatable-details_info" role="status" aria-live="polite">
                    Показано с {{ orderQnt == 0 ? orderQnt : (pageNumber - 1) * (pageSize != "all" ? pageSize : 1) + 1 }} по {{ pageNumber * (pageSize != "all" ? pageSize : 1) > orderQnt ? orderQnt : pageNumber * (pageSize != "all" ? pageSize : 1) }} из {{ orderQnt }} записей
                </div>
            </div>
            
            <div class="col-sm-12 col-md-6">
                <div class="dataTables_paginate paging_bs_normal" id="datatable-details_paginate">
                    <ul class="pagination">
                        <li class="prev disabled">
                            <button @click="prevPage" :disabled="pageNumber==1" class="fa fa-chevron-left"></button>
                        </li>
                        <template v-for="i in 3">
                            <li v-if="i + pageNumber - 1 <= pageQnt" @click="getNPage(i + pageNumber - 1)" :class="{ active: i==1 }" :disabled="i==1" >
                                <a href="#">{{ i + pageNumber - 1 }}</a>
                            </li>
                        </template>
                        <li class="next">
                            <button @click="nextPage" :disabled="pageNumber>=pageQnt-2" class="fa fa-chevron-right"></button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer-block">
            <div class="form-group">
                <button @click="combine()" class="mb-xs mt-xs mr-xs btn btn-primary col-md-2 col-md-offset-5" type="button">
                    Объединить выбранные заказы
                </button>
            </div>


            <div class="form-group">
                <label class="col-md-3 control-label">Выбрать промежуток</label>
                <div class="col-md-8">
                    <div class="input-daterange col-md-8 col-md-offset-1 input-group" data-plugin-datepicker>
                        <span class="input-group-addon"><i class="fa fa-calendar"></i></span>
                        <input class="form-control" id="calendar_from" name="start" type="text" v-model="start_date">
                        <span class="input-group-addon">до</span>
                        <input class="form-control" id="calendar_to" name="end" type="text" v-model="end_date">
                    </div>
                </div>
            </div>
            <div class="col-md-5"></div>
            <button @click="ajaxLoadData()" class="mb-xs mt-xs mr-xs btn btn-primary col-md-2 col-md-offset-5" type="button">
                Показать за выбранный промежуток
            </button>
        </div>

        <div id="loader"><span></span></div>
        <div id="mes-edit"></div>
        <div id="mes-edit1"></div>

    </div>
</template>

<script>

import VueGallery from 'vue-gallery'

Vue.component('vue-gallery', VueGallery);

export default {

    components: {
        'gallery': VueGallery
    },
    data() {
        return {
            all_zakazs: [],
            test: '',
            focused: false,
            selects_status_name: [],
            selects_from_name: [],
            selects_utm: [],
            selects_delivery: [],
            problem: true,
            old_zakaz: '',
            start_date: '',
            end_date: '',
            products: [],
            pageNumber: 1,  // по умолчанию 0
            pageQnt:0,      //число страниц
            pageSize: 25,    //число строк на странице
            orderQnt : 0,    //общее число заказов в интервале дат
            searchString : ''
        }
    },
    props:
        {
            orderlist : Object,
            path_axios_get_list_zakaz: {
                type: String,
                default: ''
            },
            path_axios_save_updated_zakaz_from_list: {
                type: String,
                default: ''
            },
            path_axios_post_combine_zakazs: {
                type: String,
                default: ''
            },
            path_axios_post_searching_order: {
                type: String,
                default: ''
            },
    },

    store: ['range'],

    created () {
        this.loadBaseTab();
    },

    methods: {
        colorBgLine (zakaz, tdID = 0) {
            if (zakaz.already_send) return 'blue';

            let id = zakaz.id_status;
            switch(id) {
                case 1:
                case 2:
                case 3:
                case 4:
                case 11:
                    return 'yellow';
                case 0:                    
                case 5:
                case 15:
                    return 'red';
                case 14:
                    return 'green';
                case 6:
                case 7:
                case 8:
                case 9:
                    return 'pink';
                case 10:
                case 12:
                    return 'transparent';
                case 13:
                    if (tdID) return 'yellow';
                    else return 'transparent';
                case 17:
                    return 'blue';
                default: 
                    return 'black';

            }
        },

        colorText (zakaz) {
            if (zakaz.already_send) return 'white';

            let id = zakaz.id_status;
        	const values = [/*11,*/0, 5,15,14,16,17] ;
            if (values.includes(id)) return 'white';
            else return '#777';
        },

        nextPage(){
            if (this.pageNumber + 1 < this.pageQnt) this.pageNumber++;
            this.paginated();
        },
        prevPage(){
            if (this.pageNumber - 1 >= 0) this.pageNumber--;
            this.paginated();
        },

        changePageSize() {
            this.pageNumber = 1;
            this.paginated();
        },

        paginated(){
            this.ajaxSendDataRequest({
                    pagination  : 'true',
                    page_limit  : this.pageSize,
                    page_number : this.pageNumber
            });
        },

        getNPage(pageN) {
            this.pageNumber = pageN;
            this.paginated();
        },

        loadBaseTab() {

            let response = this.orderlist;
            delete this.orderlist;  

            this.selects_status_name = response.data['status'];
            this.selects_from_name = response.data['from'];
            this.selects_utm = response.data['utm'];
            this.selects_delivery = response.data['delivery'];
            this.products = response.data['products'];
            this.pageSize = response.data['options']['page_limit'];
            this.pageQnt = Math.ceil(response.data['fullsize']/this.pageSize);
            this.start_date = response.data['date'].date0;
            this.end_date = response.data['date'].date1;
            this.orderQnt = response.data['fullsize'];

            delete response.data['date'];
            delete response.data['options'];
            delete response.data['status'];
            delete response.data['from'];
            delete response.data['utm'];
            delete response.data['products'];
            delete response.data['delivery'];
            delete response.data['fullsize'];

            //console.log(JSON.stringify(response.data, null, 4));

            for (let key in response.data) {
                if (response.data[key]['id_partner']) response.data[key]['utm'] = response.data[key]['id_partner']['id_partner']['utm'];
                else response.data[key]['utm'] = 0;

                    response.data[key]['isPlus'] = true;
                    response.data[key]['isMinus'] = false;
                    response.data[key]['id_in_vue'] = key;
                    response.data[key]['in_range'] = true;
                    response.data[key]['show'] = false;
                    response.data[key]['to_combine'] = false;
                    response.data[key]['no_change'] = false
                    response.data[key]['no_show'] = false;
                    response.data[key]['index'] = null;
                    response.data[key]['id_client'].editor = false;

                    for (let key1 in response.data[key]['stuff']) {
                        response.data[key]['stuff'][key1]['name'] = 
                            this.products.find(item => item.id == response.data[key]['stuff'][key1].id_tov).name;
                    }

                    switch (response.data[key]['id_status']) {
                        case 15:
                            response.data[key]['zakaz_problem'] = true;
                            break;
                        case 14:
                            response.data[key]['zakaz_done'] = true;
                            break;
                        default:
                            response.data[key]['zakaz_problem'] = false;
                            response.data[key]['zakaz_done'] = false;
                    }

                    let mas_status = [0, 1, 2, 3, 4, 5];
                    if (mas_status.includes(response.data[key]['id_status'])) { 
                        response.data[key]['editing'] = true;
                    }
                    else {
                        response.data[key]['editing'] = false;
                    }
            }


            //let size = Object.keys(response.data).length;
            //this.all_zakazs.length = 0;
            //sessionStorage.removeItem('all_zakazs');
            this.all_zakazs = response.data;

            this.redraw_datatable();
        },

        ajaxLoadData() {
            this.ajaxSendDataRequest({
                    pagination : 'true',
                    page_limit : this.pageSize,
                    page_number: 1 
            });
        },

        ajaxSendDataRequest(optionsTab) {

            axios.post(this.path_axios_get_list_zakaz, {
                data: { 
                    date0 : $('#calendar_from').val(),
                    date1 : $('#calendar_to').val(),
                    dictionary : false, 
                    options : optionsTab
                }
            })
            .then(response => {

                this.pageSize = response.data['options']['page_limit'];
                this.pageQnt = Math.ceil(response.data['fullsize']/this.pageSize);
                this.start_date = response.data['date'].date0;
                this.end_date = response.data['date'].date1;
                this.orderQnt = response.data['fullsize'];

                delete response.data['fullsize'];
                delete response.data['options'];
                delete response.data['date'];

                for (let key in response.data) {
                    if (response.data[key]['id_partner']) 
                        response.data[key]['utm'] = response.data[key]['id_partner']['id_partner']['utm'];
                    else 
                        response.data[key]['utm'] = 0;

                    response.data[key]['isPlus'] = true;
                    response.data[key]['isMinus'] = false;
                    response.data[key]['id_in_vue'] = key;
                    response.data[key]['in_range'] = true;
                    response.data[key]['show'] = false;
                    response.data[key]['to_combine'] = false;
                    response.data[key]['no_change'] = false
                    response.data[key]['no_show'] = false;
                    response.data[key]['index'] = null;
                    response.data[key]['id_client'].editor = false;
                    for (let key1 in response.data[key]['stuff']) {
                        response.data[key]['stuff'][key1]['name'] = 
                            this.products.find(item => item.id == response.data[key]['stuff'][key1].id_tov).name;
                    }

                    switch (response.data[key]['status']) {
                        case 15:
                            response.data[key]['zakaz_problem'] = true;
                            break;
                        case 14:
                            response.data[key]['zakaz_done'] = true;
                            break;
                        default:
                            response.data[key]['zakaz_problem'] = false;
                            response.data[key]['zakaz_done'] = false;
                    }

                    let mas_status = [0, 1, 2, 3, 4, 5];
                    if (mas_status.includes(response.data[key]['id_status'])) { 
                        response.data[key]['editing'] = true;
                    }
                    else {
                        response.data[key]['editing'] = false;
                    }
                }
                this.all_zakazs.length = 0;
                sessionStorage.removeItem('all_zakazs');
                this.all_zakazs = response.data;
                
                this.redraw_datatable();
            })
            .catch(function (error) {
                console.log(error.response);
            });
        },

        connect(stuff, zakaz) {
            //console.log(JSON.stringify(stuff, null, 4));
            stuff.id_tov = stuff.name.id;
            stuff.price = this.products.find(item => item.id == stuff.id_tov).price;
            stuff.img = [stuff.name.img];
            //stuff.img = [this.products.find(item => item.id == stuff.id_tov).img];
            stuff.name = this.products.find(item => item.id == stuff.id_tov).name;
            zakaz.sum += stuff.kolvo * this.products.find(item => item.id == stuff.id_tov).price;
            zakaz.kolvo += +stuff.kolvo;
            this.onBlur(zakaz.id_in_vue);
        },

        plus: function (zakaz, tovar) {
            if (this.products.find(item => item.id == tovar.id_tov).price) {
                zakaz.kolvo++;
                tovar.kolvo++;
            }
            var sum = 0;
            zakaz.stuff.forEach(function (tovar, index) {
                if (this.products.find(item => item.id == tovar.id_tov).price) sum += this.products.find(item => item.id == tovar.id_tov).price * tovar.kolvo * (100 - zakaz.skidka) / 100;
            }, this);
            zakaz.sum = sum;
        },

        minus(zakaz, tovar) {
            if (tovar.kolvo > 1 && this.products.find(item => item.id == tovar.id_tov).price) {
                tovar.kolvo--;
                zakaz.kolvo--;
            }
            var sum = 0;
            zakaz.stuff.forEach(function (tovar, index) {
                if (this.products.find(item => item.id == tovar.id_tov).price) sum += this.products.find(item => item.id == tovar.id_tov).price * tovar.kolvo * (100 - zakaz.skidka) / 100;
            }, this);
            zakaz.sum = sum;
        },
        
        //добавление нового продукта на страницу по нажатию кнопки "добавить продукт".
        addProduct(zakaz) {
            zakaz.stuff.push({id_tov: '1', img: ['images/crm/noimage.jpg'], index: null, id_in_vue: zakaz.stuff.length, kolvo: '1', name: '', size: null, price: ''});
        },

        //удаление продукта по нажатию на кнопку "удалить продукт".
        delProduct(zakaz, id) {
            zakaz.stuff.splice(id, 1);
            zakaz.stuff.forEach(function (tovar, index) {
                tovar.id_in_vue = index;
            });
            var sum = 0;
            var kolvo = 0;
            zakaz.stuff.forEach(function (tovar, index) {
                sum += this.products.find(item => item.id == tovar.id_tov).price * tovar.kolvo * (100 - zakaz.skidka) / 100;
                kolvo += tovar.kolvo;
            }, this);
            zakaz.sum = sum;
            zakaz.kolvo = kolvo;
        },

        filter() {
            for (let key in this.all_zakazs) {
                this.all_zakazs[key].in_range = false;
                if (this.all_zakazs[key].status == '12')
                    this.all_zakazs[key].stuff.forEach(function (item1, i) {
                        if (item1.prov) this.all_zakazs[key].in_range = true;
                    }, this);
            }
            this.redraw_datatable();
        },

        combine() {
            var mss_of_id = [];
            for (let key in this.all_zakazs) {
                if (this.all_zakazs[key].to_combine == true)
                    mss_of_id.push({
                        id: this.all_zakazs[key].id
                    });
            }
            axios.post(this.path_axios_post_combine_zakazs, {
                data: mss_of_id
            })
                .then(function (response) {
                    // handle success
                    $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                        //alert(id);
                    });
                    location.reload();
                    console.log(response);
                })
                .catch(function (error) {
                    // handle error
                    $('#mes-edit1').text('Объединение не удалось').delay(500).fadeIn(1000, function () {
                        $('#mes-edit1').delay(1000).fadeOut();
                        //alert(id);
                    });
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        },

        onFocus(id) {
            this.old_zakaz = JSON.stringify(this.all_zakazs[id]);
        },

        //записываем изменения в базу
        onBlur(id) {
            //if (this.all_zakazs[id].status == '16') this.all_zakazs[id].back = 1;
            //console.log(JSON.stringify(this.zakazs[id].stuff, null, 4));
            switch (this.all_zakazs[id].id_status) {
                case 15:
                    this.all_zakazs[id].zakaz_problem = true;
                    this.all_zakazs[id].zakaz_done = false;
                    break;
                case 14:
                    this.all_zakazs[id].zakaz_done = true;
                    this.all_zakazs[id].zakaz_problem = false;
                    break;
                default:
                    this.all_zakazs[id].zakaz_problem = false;
                    this.all_zakazs[id].zakaz_done = false;
            }
            //console.log(JSON.stringify(this.all_zakazs[id], null, 4));
            if (this.all_zakazs[id].no_change == 1)
                $('#mes-edit1').text('Изменения для этого товара недействительны').delay(500).fadeIn(1000, function () {
                    $('#mes-edit1').delay(1000).fadeOut();
                });
            else if (this.old_zakaz != JSON.stringify(this.all_zakazs[id])) {
                var mas_status = [10, 12, 13, 14, 15];
                if (mas_status.includes(this.all_zakazs[id].id_status))
                {
                    this.all_zakazs[id].no_change = 1;
                    this.all_zakazs['no_show'] = true;
                }
                axios.post(this.path_axios_save_updated_zakaz_from_list, {
                    data: this.all_zakazs[id]
                })
                    .then(response => {
                        // handle success
                        $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                            $('#mes-edit').delay(1000).fadeOut();

                        });
                        if (mas_status.includes(this.all_zakazs[id].id_status))
                        {
                            this.ajaxLoadData();
                        }
                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        $('#mes-edit1').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                            $('#mes-edit1').delay(1000).fadeOut();
                            //alert(id);
                        });
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
            }
        },

        redraw_datatable() {
            setTimeout(()=> {
                
                let datatable = $('#datatable-details');
                try {
                    if ( $.fn.DataTable.isDataTable('#datatable-details') ) {
                        this.destroy_datatable();
                    }

                    datatable.DataTable({
                        paging: false,
                        bFilter: false,
                        "bAutoWidth": false,
                        "bPaginate": false,
                        "bInfo": false,
                    });

                    datatable.DataTable().draw('page');//reset().draw('page');
                } catch (e) {}
                
                $('.panel-title').html('Всего заказов: ' + this.orderQnt);

            }, 100);
        },

        destroy_datatable() {

            if ( $.fn.DataTable.isDataTable('#datatable-details') ) {
                let datatable = $('#datatable-details');
                datatable.DataTable().reset();
                for (let key in this.all_zakazs) {
                    this.all_zakazs[key].isPlus  = true;
                    this.all_zakazs[key].isMinus = false;
                    this.all_zakazs[key].show    = false;
                }
                datatable.DataTable().destroy();
            }
        },

        plus_minus(zakaz) {

            let conditionClass = zakaz.isPlus;
            let conditionShow  = zakaz.show;

            for (let key in this.all_zakazs) {
                this.all_zakazs[key].isPlus  = true;
                this.all_zakazs[key].isMinus = false;
                this.all_zakazs[key].show    = false;
            }
            zakaz.show = !conditionShow;
            zakaz.isPlus = !conditionClass;
            zakaz.isMinus = conditionClass
        },

        setDate(id) {
            $("#dateInput" + id).datepicker({}).on("changeDate", function (e) {
                var event = new Event('input');
                this.dispatchEvent(event);
            });
            this.onBlur(id);
        },

        searching() {
            let $data = this.all_zakazs;
            let srchString = this.searchString;

            $.each($data, function(index){

                let x = $data[index].id_client,
                    y = $data[index];
                let string = (x.black_list != null ? x.black_list : '') + 
                             (x.city != null ? x.city : '') + 
                             (x.foreign_adr  != null ? x.foreign_adr : '') +  
                             (x.index_adr  != null ? x.index_adr : '') + 
                             (x.name_1  != null ? x.name_1 : '') + 
                             (x.street  != null ? x.street : '') + 
                             (x.tel  != null ? x.tel : '') + 
                             (y.track_number  != null ? y.track_number : '') +  
                             (y.stuff[0].name  != null ? y.stuff[0].name : '');
                
                string = string.toLowerCase();
                if (string.indexOf(srchString.toLowerCase()) >= 0) 
                    $data[index].no_show = false;
                else 
                    $data[index].no_show = true;
            });

            this.all_zakazs = $data;
            this.redraw_datatable();
            
        },

        startLoadingAnimation() {
            let imgObj = $("#loader");
            imgObj.show();
            
            let centerY = $(window).scrollTop() + ($(window).height() - imgObj.height())/2;
            let centerX = $(window).scrollLeft() + ($(window).width() - imgObj.width())/2;
            
            imgObj.offset({top:centerY, left:centerX});
        },
    
        stopLoadingAnimation() 
        {
            $("#loader").hide();
        },

        searching_in_base() {

            let self = this;

            if (this.searchString.length) {
                if (!sessionStorage.getItem('all_zakazs'))
                    sessionStorage.setItem('all_zakazs', JSON.stringify(this.all_zakazs));  

                self.startLoadingAnimation();              

                axios.post(this.path_axios_post_searching_order, {
                    data: { 
                        findstring : self.searchString
                    }
                })
                .then(response => {
                    for (let key in response.data) {
                        if (response.data[key]['id_partner']) 
                            response.data[key]['utm'] = response.data[key]['id_partner']['id_partner']['utm'];
                        else 
                            response.data[key]['utm'] = 0;

                        response.data[key]['isPlus'] = true;
                        response.data[key]['isMinus'] = false;
                        response.data[key]['id_in_vue'] = key;
                        response.data[key]['in_range'] = true;
                        response.data[key]['show'] = false;
                        response.data[key]['to_combine'] = false;
                        response.data[key]['no_change'] = false
                        response.data[key]['no_show'] = false;
                        response.data[key]['index'] = null;
                        response.data[key]['id_client'].editor = false;

                        for (let key1 in response.data[key]['stuff']) {
                            response.data[key]['stuff'][key1]['name'] = 
                                self.products.find(item => item.id == response.data[key]['stuff'][key1].id_tov).name;
                        }

                        switch (response.data[key]['status']) {
                            case 15:
                                response.data[key]['zakaz_problem'] = true;
                                break;
                            case 14:
                                response.data[key]['zakaz_done'] = true;
                                break;
                            default:
                                response.data[key]['zakaz_problem'] = false;
                                response.data[key]['zakaz_done'] = false;
                        }

                        let mas_status = [1, 2, 3, 4, 5];
                        if (mas_status.includes(response.data[key]['id_status'])) { 
                            response.data[key]['editing'] = true;
                        }
                        else {
                            response.data[key]['editing'] = false;
                        }
                    }
                    self.all_zakazs.length = 0;
                    self.all_zakazs = response.data;
                    self.stopLoadingAnimation();
                    //this.redraw_datatable();
                })
                .catch(function (error) {
                    self.stopLoadingAnimation();
                    console.log(error.response);
                });

            } else {

                this.all_zakazs = JSON.parse( sessionStorage.getItem('all_zakazs') );
                sessionStorage.removeItem('all_zakazs');
            }
        },

        findElement (tovar, index, nothing) {
                for (let prodkey in this.products){
                if (this.products[prodkey].id == tovar.id_tov) {
                    return this.products[prodkey][index];
                }				        
            }
            return nothing;
        },

    },
}

</script>

<style>
#datatable-details {
    overflow-x: scroll;
}
.select {
    position: relative;
    display: inline-block;
}
.select:after {
    width: 18px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid #ccc;
    background: none;
}
.select {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
    border-radius: 4px;
    color: #555;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: #FFF;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    line-height: 1.42857;
    display: block;
    height: 34px;
    padding: 0 0 0 0px;
    overflow: hidden;
    position: relative;
    border: 1px solid #aaa;
    white-space: nowrap;
    width: 70px;
}

button.fa {
    color: #777777;
    background-color: #ffffff;
    border-color: #dddddd;
    color: #0088cc;
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    border: 1px solid #dddddd;
    margin-left: -1px;
}
.no-margin {
    margin: 0;
}

.row-margin {
    margin-top: 15px;
    margin-bottom: 15px;
}

.to-middle {
    left: 25%;
}

.row {
    width:100%;
}

.header_tab {
    width: 50%;
    float: left;
    margin: 0;
    padding: 0;
}

.td-address {
    width:75px;
}

.addr-edit {
    font-size: 16px;
    position: relative;
    top: 15px;
    left: 101%;
}

.addr-edit-textarea {
    background-color: #eee;
    width: 100%;
    border: 1px solid #af7171;
}
.row-addr-block {
    vertical-align: top;
}
.row-addr-block .table-striped {
    margin-top:30px;
}

.addr-block input{
    margin-bottom:5px;
}

input[type="radio"], input[type="checkbox"],
.zakaz-kolvo {
    width:30px;
}

.td-small-field {
    width:1%;
}
.td-middle-field {
    width:5%;
}
.td-big-field {
    width:10%;
}
.td-biggest-field {
    width:15%;
}

.footer-block {
    display:table;
    margin: 0 auto;
    width: 100%;
}

.mail {
    background-color: blue;
}

@media only screen and (max-width: 767px) {
body {
    overflow-y: auto;
}

.to-middle {
    margin: 15px auto;
    left: 0 !important;
    right: 0;
    top: 0;
    bottom: 0;
    display: table;
}

.content-body {
    padding: 0;
}
.page-header,
.col-md-12 {
    padding: 0;
    margin:0;
}
.btn-space button{
    width: 40%;
    float: left;
    margin-top: 5%;
}
.row {
    margin-left: 0;
    margin-right: 0;
}
#form-control-size {
    width: 100%;
}
.panel-body {
    padding: 5px 10px 5px 10px;
}
.panel-body .panel-body {
    padding: 5px 0 5px 0;
}
.input-group .form-control {
    position: initial;
}
.footer-block button {
    width:100%;    
}

.footer-block {
    width: auto;
}
}
</style>