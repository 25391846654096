<template>
    <div>
        <table class="table table-bordered table-striped mb-none" id="datatable-details">
            <thead>
            <tr>
                <th style="pointer-events: none; cursor: default;">ID</th>
                <th>Процент</th>
                <th>Суммарные выплаты</th>
                <th>Кол-во заказов</th>
                <th>Кол-во товаров</th>
                <th>ФИО</th>
                <th>E-Mail</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="provider in providers">
                <tr class="gradeX">

                    <td>{{provider.id}}</td>
                    <td><input class="form-control1" @focus="onFocus(provider.id_in_vue)"
                               @blur="onBlur(provider.id_in_vue)" v-model="provider.percent"></td>
                    <td>{{provider.data.sum_sell * provider.percent / 100 || 0}}</td>
                    <td>{{provider.data.count_zakaz || 0}}</td>
                    <td>{{provider.data.count_tov || 0}}</td>
                    <td><input class="form-control1" @focus="onFocus(provider.id_in_vue)"
                               @blur="onBlur(provider.id_in_vue)" v-model="provider.name"></td>
                    <td><input class="form-control1" @focus="onFocus(provider.id_in_vue)"
                               @blur="onBlur(provider.id_in_vue)" v-model="provider.email"></td>
                </tr>
            </template>
            </tbody>
        </table>

        <p>&nbsp;</p>
        <div class="form-group">
            <label class="col-md-3 control-label">Выбрать промежуток</label>
            <div class="col-md-8">
                <div class="input-daterange col-md-8 col-md-offset-1 input-group" data-plugin-datepicker>
														<span class="input-group-addon">
															<i class="fa fa-calendar"></i>
														</span>
                    <input class="form-control" name="start" id="start" type="text" v-model="start_date">
                    <span class="input-group-addon">до</span>
                    <input class="form-control" name="end" type="text" v-model="end_date">
                </div>
            </div>
        </div>
        <div class="col-md-5"></div>
        <button class="mb-xs mt-xs mr-xs btn btn-primary col-md-2 col-md-offset-5" @click="makeMagic2()"
                type="button">Показать за выбранный промежуток
        </button>


        <div id="mes-edit"></div>
        <div id="mes-edit1"></div>

    </div>
</template>
<script>//

export default {
    created() {
        var date = new Date();
        date.setMonth(date.getMonth() + 1);
        this.start_date = date.getFullYear() + '-' + ('0' + (date.getMonth())).slice(-2) + '-' + '01';
        this.end_date = date.getFullYear() + '-' + ('0' + (date.getMonth())).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        localStorage.setItem('date0', this.start_date);
        localStorage.setItem('date1', this.end_date);
        this.makeMagic2();
    },
    data() {
        return {
            providers: [],
            old_provider: '',
            pays: [],
            providers_show: [],
            start_date: '',
            end_date: '',
        }
    },
    props:
        {
            path_axios_save_updated_provider_from_list: {
                type: String,
                default: ''
            },
            path_axios_get_list_provider: {
                type: String,
                default: ''
            },
        },
    methods: {
        makeMagic2() {
            let mass = {};
            mass['date0'] = localStorage.getItem('date0');
            mass['date1'] = localStorage.getItem('date1');

            var day_start = new Date(localStorage.getItem('date0'));
            var day_end = new Date(localStorage.getItem('date1'));

            this.start_date = day_start.getFullYear() + '-' + ('0' + (day_start.getMonth()+1)).slice(-2) + '-' + ('0' + (day_start.getDate())).slice(-2);
            this.end_date = day_end.getFullYear() + '-' + ('0' + (day_end.getMonth()+1)).slice(-2) + '-' + ('0' + day_end.getDate()).slice(-2);

            axios.post(this.path_axios_get_list_provider, {
                data: mass
            })
                .then(response => {
                    //console.log(JSON.stringify(response.data, null, 4));
                    this.providers = Array.from(response.data['provider']);
                    delete response.data['provider'];
                    this.pays = response.data;
                    this.providers.forEach(function (item, i) {
                        if (response.data[item.id]) item.data = response.data[item.id];
                        else item.data = [];
                        item.id_in_vue = i;
                    }, this);
                    //console.log(JSON.stringify(this.providers, null, 4));
                    //console.log(response);
                })
                .then(response => {
                    this.add_jquery();
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },

        onFocus(id) {
            this.old_provider = JSON.stringify(this.providers[id]);
        },
        //записываем изменения в базу
        onBlur(id) {
            //console.log(JSON.stringify(this.providers[id], null, 4));

            if (this.old_provider != JSON.stringify(this.providers[id]))
                axios.post(this.path_axios_save_updated_provider_from_list, {
                    data: this.providers[id]
                })
                    .then(function (response) {
                        // handle success
                        $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                            $('#mes-edit').delay(1000).fadeOut();
                        });
                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        $('#mes-edit1').text('Ошибка сохранения').delay(500).fadeIn(1000, function () {
                            $('#mes-edit1').delay(1000).fadeOut();
                            //alert(id);
                        });
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
        },

        add_jquery() {

            $('#datatable-details').DataTable({
                paging: false,
                bFilter: false,
                aoColumnDefs: [{
                    bSortable: false,
                    aTargets: [0, 1, 2, 3, 4, 5]
                }],
                "bAutoWidth": false,
                "bPaginate": false,
                "bInfo": false,
            });

            $('#datatable-details').DataTable().destroy();
        },
    },
}

</script>
