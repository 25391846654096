

<template>
    <div>

        <button class="mb-xs mt-xs mr-xs btn btn-primary col-md-2 col-md-offset-5" @click="addNewNote()" type="button">Добавить</button>

        <table class="table table-bordered mb-none" id="datatable-details">
            <thead>
            <tr>
                <th style="pointer-events: none; cursor: default; width: 4.484%;">ID</th>
                <th width="16.143%">Дата записи</th>
                <th width="41.565%">Товар</th>
                <th width="28.699%">Данные о заказчике</th>
                <th>DEL</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="note in notes">
                <template v-if="note.show_crm">
                    <tr>
                        <td colspan="6">
                            <table>
                                <tr>
                                    <td width="20.627%" class="no-margin">
                                        <tr>
                                            <td class="center no-margin input-fields" style="width: 25%;">{{note.id}}</td>
                                            <td class="center no-margin input-fields" >{{note.created_at}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" class="button-to-crm no-margin">
                                                <br><br>
                                                <button @click="createOrder(note.id)" class="mb-xs mt-xs mr-xs btn btn-xs btn-primary" type="button">Сохранить в CRM</button>
                                            </td>
                                        </tr>
                                    </td>
                                    <td width="41.565%" class="no-margin">
                                        <ol type="1">
                                            <li v-for="stuff in note.stuff">
                                                <table class="table-bordered">
                                                    <tr>
                                                        <td colspan="4">
                                                                <span class="show-grid-block" style="padding-left: 5px;">Цена: <b>{{findElement(stuff, 'price', 0)}}</b> руб., Стоимость: <b>{{findElement (stuff, 'price', 0) * stuff.kolvo }}</b> руб.</span>
                                                            <div class="input-group btn-group"
                                                                    style="width: 386px; top: 6px; margin-left: 5px; margin-right: 5px;">
                                                                <span class="input-group-addon">
                                                                    <i class="fa fa-shopping-cart"></i>
                                                                </span>
                                                                <v-select :options="products" @input="connect(stuff, note)" class="form-control populate"
                                                                            label="name"
                                                                            placeholder="Введите как минимум 2 символа для поиска"
                                                                            v-model="stuff.name">
                                                                </v-select>
                                                            </div>
                                                            <br>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-left: 5px;">Количество</td>
                                                        <td style="width: 20px;"></td>
                                                        <td style="padding-left: 5px;">Фото</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-left: 5px;">
                                                            <div class="input-group form-control1" style="width:150px;">
                                                                <input :value="stuff.kolvo"
                                                                        maxlength="3" readonly style="float: left;" type="text">
                                                                <div class="spinner-buttons input-group-btn" style="top: -7px; position: relative; left: 15px;">
                                                                    <button @click="plus(note, stuff), onBlur(note.id_in_vue)"
                                                                            class="btn btn-default spinner-up"
                                                                            style="border: 1px solid #af7171; line-height: 1.31;"
                                                                            type="button">
                                                                        <i class="fa fa-angle-up"></i>
                                                                    </button>
                                                                    <button @click="minus(note, stuff), onBlur(note.id_in_vue)"
                                                                            class="btn btn-default spinner-down"
                                                                            style="border: 1px solid #af7171; line-height: 1.31;"
                                                                            type="button">
                                                                        <i class="fa fa-angle-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                        <td rowspan="3">
                                                            <gallery :images="[findElement(stuff, 'img', '')]" :index="stuff.index" @close="stuff.index = null"></gallery>
                                                            <div
                                                                :key="imageIndex"
                                                                :style="{ backgroundImage: 'url(' + image + ')', width: '100px', height: '100px' }"
                                                                @click="stuff.index = imageIndex"
                                                                class="image"
                                                                v-for="(image, imageIndex) in [findElement(stuff, 'img', '')]"
                                                            ></div>
                                                        </td>
                                                        <td>

                                                            <div class="col-md-2"><span class="show-grid-block"><br><button
                                                                @click="delProduct(note, stuff.id_in_vue), onBlur(note.id_in_vue)"
                                                                class="mb-xs mt-xs mr-xs btn btn-xs btn-primary"
                                                                type="button">Удалить товар</button></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-left: 5px;">Размер</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-left: 5px; padding-bottom: 5px; padding-top: 6px;">
                                                            <input @blur="onBlur(note.id_in_vue)"
                                                                    @focus="onFocus(note.id_in_vue)" class="form-control1"
                                                                    style="width: 150px;"
                                                                    v-model="stuff.size">
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </table>
                                                <br>
                                            </li>
                                        </ol>
                                        <div class="panel-body">
                                            <div class="col-md-6">
                                                <button @click="addProduct(note)" class="mb-xs mt-xs mr-xs btn btn-xs btn-primary" type="button">Добавить ещё
                                                    товар
                                                </button>
                                            </div>
                                        </div>
                                    </td>

                                    <td class="input-fields no-margin" width="28.699%">
                                        <label>ФИО
                                            <textarea @blur="onBlur(note.id_in_vue)" @focus="onFocus(note.id_in_vue)" class="form-control1" style="height: 32px;" v-model="note.name"></textarea>
                                        </label>
                                        <label>Телефон
                                            <textarea @blur="onBlur(note.id_in_vue)" @focus="onFocus(note.id_in_vue)" class="form-control1" style="height: 32px;" v-model="note.tel"></textarea>
                                        </label>
                                        <label>Адрес
                                            <textarea @blur="onBlur(note.id_in_vue)" @focus="onFocus(note.id_in_vue)" class="form-control1" style="height: 32px;" v-model="note.address"></textarea>
                                        </label>
                                        <label>Комментарий
                                            <textarea @blur="onBlur(note.id_in_vue)" @focus="onFocus(note.id_in_vue)" class="form-control1" style="height: 32px;" v-model="note.comment"></textarea>
                                        </label>
                                    </td>
                                    <td class="center" style="vertical-align: top;">
                                        <button class="mb-xs mt-xs mr-xs btn btn-primary" @click="delNote(note.id)" type="button"> 
                                            <i class="fa fa-trash-o"></i>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </template>
            </template>
            </tbody>
        </table>


        <div id="loader"><span></span></div>
        <div id="mes-edit"></div>

    </div>
</template>
<script>


import VueGallery from 'vue-gallery'

Vue.component('vue-gallery', VueGallery);

export default {
    components: {
        'gallery': VueGallery
    },
    created() {

        this.getNotes();

    },
    data() {
        return {
            notes: [],
            selected: 0,
            start_date: '',
            end_date: '',
            newID: '',
            products: [],
        }
    },
    props:
        {
            path_axios_get_list_notes: {
                type: String,
                default: ''
            },
            path_axios_delete_note: {
                type: String,
                default: ''
            },
            path_axios_add_note: {
                type: String,
                default: ''
            },
            path_axios_save_note: {
                type: String,
                default: ''
            },
            path_axios_create_order: {
                type: String,
                default: ''
            },        },
    methods: {

        saveMessageRead(message) {
            axios.post(this.path_axios_save_readed_messages, {
                data: message
            })
                .then(function (response) {
                    // handle success
                    $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                        //alert(id);
                    });
                    console.log(response);
                })
                .catch(function (error) {
                    // handle error
                    $('#mes-edit1').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                        $('#mes-edit1').delay(1000).fadeOut();
                        //alert(id);
                    });
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        },

        add_jquery() {

            let datatable = $('#datatable-details');
            if ( $.fn.DataTable.isDataTable('#datatable-details') ) {
                datatable.DataTable().destroy();
            }


            //if (jQuery().DataTable) {
                
                datatable.DataTable({
                    destroy: true,
                    paging: true,
                    bFilter: true,
                    'searching' : true,
                    aoColumnDefs: [{
                        bSortable: false,
                        //aTargets: [0, 1, 2, 3, 4, 5 ,6],
                        "defaultContent": "-",
                        "targets": "_all"   
                    }],
                    aaSorting: [
                        [1, 'desc']
                    ],
                    "bAutoWidth": false,
                    "bPaginate": true,
                    "bInfo": true,
                });
            //}

        },

        getNotes() {
            axios.get(this.path_axios_get_list_notes).then(response => {
                

                this.products = response.data['products'];
                delete response.data['products'];

                for (let key in response.data) {
                    response.data[key]['id_in_vue'] = key;
                    response.data[key]['sum'] = 0;
                }

                this.notes.length = 0;
                this.notes = response.data;
                //console.log(JSON.stringify(response.data, null, 4));
            })
                .then(response => {
                    setTimeout(() => this.add_jquery(), 500);
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },

        addNewNote()
        {
            axios.post(this.path_axios_add_note, {data: "newrow"}).then(response => {
            })
                .then(response => {
		            document.location.reload(true);
                    //setTimeout(() => this.add_jquery(), 500);
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },

        delNote(id)
        {
            axios.post(this.path_axios_delete_note, {
                data: id
            })
                .then(response => {
                    // handle success
                    $('#mes-edit').text('Запись удалена').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                    });
                    document.location.reload(true);
                    console.log(response);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        },
        
        createOrder(id)
        {
            if (confirm("Вы действительно хотите создать новый заказ из данной заметки?") ) {
                axios.post(this.path_axios_create_order, {
                    data: id
                })
                .then(response => {
                    document.location.reload(true);
                    console.log(response);
                })
                .catch(function (error) {
                    // handle error
                    if (error.response) {
                        $('#mes-edit').text((error.response.data)).delay(500).fadeIn(1000, function () {
                            $('#mes-edit').delay(1000).fadeOut();
                        });
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                })
                .finally(function () {
                    // always executed
                });
            }
        },

        onFocus(id) {
            this.old_note = JSON.stringify(this.notes[id]);
        },

        //записываем изменения в базу
        onBlur(id) {
            if (this.old_note != JSON.stringify(this.notes[id])) {
                axios.post(this.path_axios_save_note, {
                    data: this.notes[id]
                })
                    .then(function (response) {
                        // handle success
                        $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                            $('#mes-edit').delay(1000).fadeOut();
                            //alert(id);
                        });
                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
            }
        },

        findElement (tovar, index, nothing) {
            if (typeof(tovar.id_tov) !== 'undefuned' || tovar.id_tov >= 0)
                for (let prodkey in this.products){
                    if (this.products[prodkey].id == tovar.id_tov) {
                        return this.products[prodkey][index];
                    }				        
                }
            return nothing;
        },

        addProduct(zakaz) {
            
            let stuff_length = zakaz.stuff.push({id_tov: '0', img: ['images/crm/noimage.jpg'], index: null, 
		        id_in_vue: zakaz.stuff.length, kolvo: '0', name: '', size: null, price: ''});

            this.onBlur(zakaz.id_in_vue);
        },

        //удаление продукта по нажатию на кнопку "удалить продукт".
        delProduct(zakaz, id) {
            zakaz.stuff.splice(id, 1);
            zakaz.stuff.forEach(function (tovar, index) {
                tovar.id_in_vue = index;
            });
            var sum = 0;
            var kolvo = 0;

            zakaz.stuff.forEach(function (tovar, index) {
                for (let prodkey in this.products){
                    if (this.products[prodkey].id == tovar.id_tov) {
                        sum += this.products[prodkey]['price'] * tovar.kolvo;// * (100 - zakaz.skidka) / 100;
                        kolvo += tovar.kolvo;

                        break;
                    }
                }
            }, this);

            zakaz.sum = sum;
            zakaz.kolvo = kolvo;
        },

        connect(stuff, zakaz) {
            
            stuff.id_tov = stuff.name.id;

            for (let prodkey in this.products){
                if (this.products[prodkey].id == stuff.id_tov) {
                    stuff.price = this.products[prodkey]['price'];
                    stuff.name = this.products[prodkey]['name'];
                    break;
                }
            }

            stuff.img = [stuff.name.img];
            stuff.kolvo = stuff.kolvo > 0 ? stuff.kolvo : 1;
            zakaz.sum += stuff.kolvo * stuff.price;
            zakaz.kolvo += stuff.kolvo;
            this.onBlur(zakaz.id_in_vue);
            
        },
        
        plus: function (zakaz, tovar) {
            
	        let price = this.findElement(tovar, 'price', -1);

            if (price >= 0) {
                zakaz.kolvo++;
                tovar.kolvo++;
            }

            var sum = 0;

            zakaz.stuff.forEach(function (tovar, index) {

                for (let prodkey in this.products){
                    if (this.products[prodkey].id == tovar.id_tov) {
                        sum += this.products[prodkey]['price'] * tovar.kolvo;// * (100 - zakaz.skidka) / 100;
                        break;
                    }
                }

            }, this);
            zakaz.sum = sum;
        },

        minus(zakaz, tovar) {

	        let price = this.findElement(tovar, 'price', -1);

            if (tovar.kolvo > 1 && price >= 0) {
                tovar.kolvo--;
                zakaz.kolvo--;
            }

            var sum = 0;

            zakaz.stuff.forEach(function (tovar, index) {

	        for (let prodkey in this.products){
                if (this.products[prodkey].id == tovar.id_tov) {
                    sum += this.products[prodkey]['price'] * tovar.kolvo ;//* (100 - zakaz.skidka) / 100;
                    break;
                }
	        }

            }, this);
            
	        zakaz.sum = sum;
        },
    }
}

</script>

<style>
textarea.form-control1 {
    height: 32px;
}
.input-fields {
    padding: 0 10px;
    vertical-align: top;
}

.input-fields label {
    width: 100%;
}

.button-to-crm {
    text-align: center;
    padding-top: 30px;
}
.no-margin {
    margin: 0;
    padding: 0;
    vertical-align: top;
}
</style>