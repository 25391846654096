<template>
    <div>
        <table class="table table-bordered table-striped mb-none" id="datatable-details">
            <thead>
            <tr>
                <th></th>
                <th style="pointer-events: none; cursor: default;">ID</th>
                <th>Логин</th>
                <th style="width: 160px;">UTM</th>
                <th>Процент</th>
                <th>Кол-во заказов</th>
                <th>Суммарные выплаты</th>
                <th>Остаток к выплате</th>
                <th>E-Mail</th>

            </tr>
            </thead>
            <tbody>
            <template v-for="partner in partners">
                <template v-if="partner.show">
                    <tr class="gradeX">
                        <td class="text-center" v-on:click="plus_minus(partner, partner.id_in_vue), partner.show = !partner.show"><i
                                class="fa text-primary h5 m-none"
                                data-toggle
                                style="cursor: pointer;"
                                v-bind:class="{ 'fa-plus-square-o': partner.isPlus, 'fa-minus-square-o': partner.isMinus}"></i>
                        </td>
                        <td>{{partner.id}}</td>
                        <td><input class="form-control1 name" @focus="onFocus(partner.id_in_vue)" @blur="onBlur(partner.id_in_vue)" v-model="partner.id_backend_users.name"></td>
                        <td><input class="form-control1 utm" @focus="onFocus(partner.id_in_vue)" @blur="onBlur(partner.id_in_vue)" v-model="partner.utm"></td>
                        <td><input class="form-control1 percent" @focus="onFocus(partner.id_in_vue)" @blur="onBlur(partner.id_in_vue)" v-model="partner.percent"></td>
                        <td>{{partner.kolvo}}</td>
                        <td>{{partner.all_pays}}</td>
                        <td>{{partner.ost_pays}}</td>
                        <td><input class="form-control1 email" @focus="onFocus(partner.id_in_vue)" @blur="onBlur(partner.id_in_vue)" v-model="partner.id_backend_users.email"></td>
                    </tr>
                    <tr class="details">
                        <td class='details' colspan="11">
                            <table class="table mb-none">
                                <tbody>
                                <tr>
                                    <td>Данные партнёра &darr;</td>
                                </tr>
                                <tr>
                                    <td>
                                        <table class="table-striped mb-none">
                                            <tr>
                                                <td>Кредитная карта:</td>
                                                <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                <td><input class="form-control1" @focus="onFocus(partner.id_in_vue)" @blur="onBlur(partner.id_in_vue)" v-model="partner.card_number"></td>
                                                <td>&nbsp</td>
                                                <td>ФИО:</td>
                                                <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                <td style="width: 300px;"><input class="form-control1" @focus="onFocus(partner.id_in_vue)" @blur="onBlur(partner.id_in_vue)" v-model="partner.p_name"></td>
                                            </tr>
                                            <tr>
                                                <td>Яндекс-кошелёк:</td>
                                                <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                <td><input class="form-control1" @focus="onFocus(partner.id_in_vue)" @blur="onBlur(partner.id_in_vue)" v-model="partner.yandex"></td>
                                            </tr>
                                            <tr>
                                                <td>Киви-кошелёк:</td>
                                                <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                <td><input class="form-control1" @focus="onFocus(partner.id_in_vue)" @blur="onBlur(partner.id_in_vue)" v-model="partner.qiwi"></td>
                                            </tr>
                                            <tr>
                                                <td>PayPal:</td>
                                                <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                <td><input class="form-control1" @focus="onFocus(partner.id_in_vue)" @blur="onBlur(partner.id_in_vue)" v-model="partner.paypal"></td>
                                            </tr>
                                        </table>

                                    </td>
                                </tr>
                                <tr>
                                </tr>


                                </tbody>
                            </table>
                        </td>
                    </tr>
                </template>

                <template v-else>
                    <tr class="gradeX">
                        <td class="text-center" v-on:click="plus_minus(partner, partner.id_in_vue), partner.show = !partner.show"><i
                                class="fa text-primary h5 m-none"
                                data-toggle
                                style="cursor: pointer;"
                                v-bind:class="{ 'fa-plus-square-o': partner.isPlus, 'fa-minus-square-o': partner.isMinus}"></i>
                        </td>
                        <td>{{partner.id}}</td>
                        <td><input class="form-control1 name" @focus="onFocus(partner.id_in_vue)" @blur="onBlur(partner.id_in_vue)" v-model="partner.id_backend_users.name"></td>
                        <td><input class="form-control1 utm" @focus="onFocus(partner.id_in_vue)" @blur="onBlur(partner.id_in_vue)" v-model="partner.utm"></td>
                        <td><input class="form-control1 percent" @focus="onFocus(partner.id_in_vue)" @blur="onBlur(partner.id_in_vue)" v-model="partner.percent"></td>
                        <td>{{partner.kolvo}}</td>
                        <td>{{partner.all_pays}}</td>
                        <td>{{partner.ost_pays}}</td>
                        <td><input class="form-control1 email" @focus="onFocus(partner.id_in_vue)" @blur="onBlur(partner.id_in_vue)" v-model="partner.id_backend_users.email"></td>
                    </tr>
                </template>
            </template>
            </tbody>
        </table>

        <div id="loader"><span></span></div>
        <div id="mes-edit"></div>

    </div>
</template>
<script>//

export default {
    created() {
        this.getPartners();
        //this.add_jquery();

    },
    data() {
        return {
            partners: [],
            old_partner: '',
        }
    },
    props:
        {
            path_axios_save_updated_partner_from_list: {
                type: String,
                default: ''
            },
            path_axios_get_list_partner: {
                type: String,
                default: ''
            },
        },
    methods: {
        getPartners() {

            //получаем список всех партнёров
            axios.get(this.path_axios_get_list_partner).then(response => {
                //console.log(JSON.stringify(response.data, null, 4));
                response.data.forEach(function (item, i) {
                    item.isPlus = true;
                    item.isMinus = false;
                    item.id_in_vue = i;
                }, this);
                this.partners = response.data;

                //console.log(JSON.stringify(this.partners[0], null, 4));
            }).then(response => {
                    this.add_jquery();
                    //this.plus_minus();

            })
                .catch(function (error) {
                    console.log(error.response);
                });
        },

        onFocus(id)
        {
            this.old_partner = JSON.stringify(this.partners[id]);
            //console.log(this.zakazs[id].zakaz_date);
        },
        //записываем изменения в базу
        onBlur(id) {
            //console.log(JSON.stringify(this.partners[id], null, 4));

            if (this.old_partner != JSON.stringify(this.partners[id]))

            axios.post(this.path_axios_save_updated_partner_from_list, {
                data: this.partners[id]
            })
                .then(function (response) {
                    // handle success
                    $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                    });
                    console.log(response);
                })
                .catch(function (error) {
                    // handle error
                    $('#mes-edit1').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                        $('#mes-edit1').delay(1000).fadeOut();
                        //alert(id);
                    });
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        },

        add_jquery() {

	    let dtable = $('#datatable-details');

	    if ( $.fn.DataTable.isDataTable('#datatable-details') ) {
  		dtable.DataTable().destroy();
	    }

/*
            dtable.DataTable({
                paging: true,
                bFilter: false,
                aoColumnDefs: [{
                    bSortable: false,
                    aTargets: [0, 1, 2, 3, 4, 5, 6, 7, 8]
                }]
                ,
                aaSorting: [
                    [1, 'desc']
                ],
                "bAutoWidth": false,
                "bPaginate": true,
                "bInfo": true,
            });
*/

            var datatableInit = function () {
                var $table = $('#datatable-details');

                // format function for row details
                var fnFormatDetails = function (datatable, tr) {
                    var data = datatable.fnGetData(tr);
                };

                // initialize
                var datatable = $table.dataTable({
                    aoColumnDefs: [{
                        bSortable: false,
                        aTargets: [0, 1, 2, 3, 4, 5, 6, 7, 8]
                    }],
                    aaSorting: [
                        [1, 'asc']
                    ]
                });
            };

            $(function () {
                datatableInit();
            });

        },

        plus_minus(partner, id) {
            if (partner.isPlus == true) {
                partner.isPlus = false;
                partner.isMinus = true;
            } else {
                partner.isPlus = true;
                partner.isMinus = false;
            }
        },
    },
}

</script>
