<template>
    <div>
        <table class="table table-bordered table-striped mb-none">
            <thead>
            <tr>
                <th style="pointer-events: none; cursor: default;">ID</th>
                <th>ФИО</th>
                <th>UTM</th>
                <th>Начислено</th>
                <th>Выплачено</th>
                <th>Заказов</th>
                <th>Спорные</th>
                <th>Выплатить</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="partner in partners1">
                <tr class="gradeX">
                    <td>{{partner.id}}</td>
                    <td>{{partner.name}}</td>
                    <td>{{partner.utm}}</td>
                    <td>{{partner.zakazs_sum}}</td>
                    <td>{{partner.zakazs_paid}}</td>
                    <td>{{partner.count_zakazs}}</td>
                    <td>{{partner.count_fake}}</td>
                    <td>
                        <button @click="payToPartner(partner.id_in_partner)" class="mb-xs mt-xs mr-xs btn btn-primary col-md-8" type="button">
                            {{partner.not_paid}} руб.
                        </button>
                    </td>
                </tr>
            </template>
            </tbody>

        </table>
        <p>&nbsp;</p>

        <div class="form-group">
            <label class="col-md-3 control-label">Выбрать промежуток</label>
            <div class="col-md-8">
                <div class="input-daterange col-md-8 col-md-offset-1 input-group" data-plugin-datepicker>
														<span class="input-group-addon">
															<i class="fa fa-calendar"></i>
														</span>
                    <input class="form-control" id="start" name="start" type="text" v-model="start_date">
                    <span class="input-group-addon">до</span>
                    <input class="form-control" name="end" type="text" v-model="end_date">
                </div>
            </div>
        </div>
        <div class="col-md-5"></div>
        <button @click="makeMagic1()" class="mb-xs mt-xs mr-xs btn btn-primary col-md-2 col-md-offset-5" type="button">Показать за выбранный промежуток</button>

        <div id="mes-edit"></div>

    </div>
</template>
<script>//

export default {
    created() {
        var date = new Date();
        date.setMonth(date.getMonth() - 1);
        this.start_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        date.setMonth(date.getMonth() + 1);
        this.end_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        localStorage.setItem('date0', this.start_date);
        localStorage.setItem('date1', this.end_date);
        this.makeMagic1();

    },
    data() {
        return {
            partners: [],
            partners1: [],
            zakazs_dates: [],
            start_date: '',
            end_date: '',
        }
    },
    props:
        {
            path_axios_get_list_partners_for_stat: {
                type: String,
                default: ''
            },
            path_axios_post_save_paid_from_partners_stat: {
                type: String,
                default: ''
            },
        },
    store: ['range'],
    methods: {
        makeMagic1() {

            this.range['date0'] = localStorage.getItem('date0');
            this.range['date1'] = localStorage.getItem('date1');

            var day_start = new Date(localStorage.getItem('date0'));
            var day_end = new Date(localStorage.getItem('date1'));


            this.start_date = day_start.getFullYear() + '-' + ('0' + (day_start.getMonth() + 1)).slice(-2) + '-' + ('0' + (day_start.getDate())).slice(-2);
            this.end_date = day_end.getFullYear() + '-' + ('0' + (day_end.getMonth() + 1)).slice(-2) + '-' + ('0' + (day_end.getDate())).slice(-2);

            day_end.setDate(day_end.getDate() + 1);

            this.partners1 = [];
            var temp_mas = [];


            let mass = {};
            mass['date0'] = localStorage.getItem('date0');
            mass['date1'] = localStorage.getItem('date1');

            axios.post(this.path_axios_get_list_partners_for_stat, {
                data: mass
            })
                .then(response => {

                    this.partners = response.data;
                    //console.log(JSON.stringify(response.data, null, 4));

                    var keys = Object.keys(this.partners);
                    for (let i = 0; i < keys.length; i++) {
                        temp_mas = [];
                        temp_mas.count_zakazs = 0;
                        temp_mas.count_fake = 0;
                        temp_mas.zakazs_paid = 0;
                        temp_mas.zakazs_sum = 0;
                        temp_mas.not_paid = 0;
                        this.partners[keys[i]].forEach(function (item1, i) {
                            if (item1.paid != 4) temp_mas.not_paid += item1.sum;
                            if (item1.paid == 1) temp_mas.not_paid -= item1.sum;
                            temp_mas.utm = item1.id_partner.utm;
                            temp_mas.name = item1.id_partner.id_backend_users.name;
                            temp_mas.id = item1.id_partner.id_backend_users.id;
                            temp_mas.id_in_partner = item1.id_partner.id;

                                if (item1.paid == 4) temp_mas.count_fake++;
                                else temp_mas.zakazs_sum += item1.sum;
                                temp_mas.count_zakazs++;
                                if (item1.paid == 1) temp_mas.zakazs_paid += item1.sum;
                        }, this);
                        this.partners1.push(temp_mas);
                    }
                });
        },
        //записываем изменения в базу
        payToPartner(partner_id) {

            axios.post(this.path_axios_post_save_paid_from_partners_stat, {
                data: partner_id
            })
                .then(response => {
                    // handle success
                    $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                    });
                    this.makeMagic1();
                    console.log(response);
                })
                .catch(function (error) {
                    // handle error
                    $('#mes-edit1').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                        $('#mes-edit1').delay(1000).fadeOut();
                        //alert(id);
                    });
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        },


    },
}

</script>
