<template>
    <div>
        <div v-if="not_paid > 0" class="panel-body">
            <button @click="pleasePayMe(currentPartner)" class="mb-xs mt-xs mr-xs btn btn-primary"  
                type="button">Запрос на выплату {{not_paid}} руб.</button>
        </div>
        <template v-if="show_sel">
        <v-select :options="partners" @input="show_pays(selected)" class="form-control"
                  label="name"
                  placeholder="Введите как минимум 2 символа для поиска"
                  v-model="selected">
        </v-select>
        </template>

        <br>
        <br>
        <table :id="'datatable-details'" class="table table-bordered mb-none">
            <thead>
            <tr>
                <th>Месяц</th>
                <th>Начислено</th>
                <th>Выплачено</th>
                <th>Заказов</th>
                <th>Спорные</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="month in mas_months">
                        <tr>
                            <td>{{month.month}}, {{month.year}}</td>
                            <td>{{month.zakazs_sum}}</td>
                            <td>{{month.zakazs_paid}}</td>
                            <td>{{month.count_zakazs}}</td>
                            <td>{{month.count_fake}}</td>
                        </tr>
            </template>
            <tr>
                <td><b>Всего</b></td>
                <td><b>{{zakazs_sum}}</b></td>
                <td><b>{{zakazs_paid}}</b></td>
                <td><b>{{count_zakazs}}</b></td>
                <td><b>{{count_fake}}</b></td>
            </tr>
            </tbody>
        </table>
        <br>
        <br>
        <br>
        <div id="mes-edit"></div>
    </div>


</template>


<script>

export default {
    created() {
        this.getCurrentUser();
    },
    data() {
        return {
            partners: [],
            currentUser: 0,
            currentPartner: 0,
            pays: [],
            selected: 0,
            mas_months:[],
            zakazs_sum: 0,
            zakazs_paid: 0,
            count_zakazs: 0,
            count_fake: 0,
            show_sel: 0,
            not_paid: 0,
            not_paid_ids : [],
        }
    },
    props:
        {
           path_axios_get_list_partner: {
                type: String,
                default: ''
            },
            path_axios_post_list_pays_of_partner: {
                type: String,
                default: ''
            },
            path_axios_get_current_user: {
                type: String,
                default: ''
            },
            path_axios_pay_to_partner: {
                type: String,
                default: ''
            },
        },
    methods: {

        getCurrentUser()
        {
            let self = this
            axios.get(this.path_axios_get_current_user).then(response => {
                self.currentUser = response.data;
                self.currentUser.role == 1 ? self.show_sel = true : self.show_sel = false;
            })
                .then(response => {
                    self.getPartners();
                    console.log(response);
                })
                .catch(function (error) {
                    //alert(error.response);
                    console.log(error.response);
                });
        },

        getPartners() {
            axios.get(this.path_axios_get_list_partner).then(response => {
                response.data.forEach(function (item, i) {
                    item.name = item.id_backend_users.name;
                    item.selected = '0';
                });
                this.partners = response.data;
                //console.log(JSON.stringify(this.partners, null, 4));
            })
                .then(response => {
                    this.partners.forEach(function (item, i) {
                        //console.log(item.id_backend_users, this.currentUser.id, item.id_in_vue);
                        //console.log(JSON.stringify(item, null, 4));
                        if (item.id_backend_users.id == this.currentUser.id) {this.show_pays(item);}
                    }, this);
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
        show_pays(partner_name) {
            //this.show = 1;
            let self = this;

            $('#datatable-details').DataTable().destroy();
            if (partner_name)
                this.currentPartner = partner_name;
                axios.post(this.path_axios_post_list_pays_of_partner, {
                    data: partner_name.id
                })
                    .then(response => {
                        let month = new Date();
                        month.setMonth(month.getMonth() + 1);
                        for (let i = 0; i < 12; i++) {
                            month.setMonth(month.getMonth() - 1);
                            self.mas_months.push({
                                id: i,
                                month: month.toLocaleString('ru', {
                                    month: 'long'
                                }),
                                year: month.getFullYear(),
                            });
                        }

                        self.not_paid = 0;

                        for(let firstKey in response.data) {

                            let date = firstKey.split('-');
                            let keyMonth = parseInt(date.pop()) - 1;
                            let keyYear = parseInt(date.pop());

                            self.mas_months.forEach(function (item, i) {

                                if (keyMonth == i && keyYear == item.year)
                                {
                                    item.count_zakazs = response.data[firstKey].count_zakazs;
                                    item.zakazs_paid = response.data[firstKey].zakazs_paid;
                                    item.zakazs_sum = response.data[firstKey].zakazs_sum;
                                    item.count_fake = response.data[firstKey].count_fake;

                                    if (item.zakazs_sum - item.zakazs_paid > 0) {
                                        for(let secondKey in response.data[firstKey]) {
                                            if (isNaN(parseInt(secondKey))) break;
                                            if (response.data[firstKey][secondKey].paid != 1) {
                                                self.not_paid += response.data[firstKey][secondKey].sum;
                                                self.not_paid_ids.push(response.data[firstKey][secondKey].id_zakaz.id);
                                            }

                                        }
                                    }

                                    if (item.count_zakazs) self.count_zakazs += item.count_zakazs;
                                    if (item.zakazs_paid) self.zakazs_paid += item.zakazs_paid;
                                    if (item.zakazs_sum) self.zakazs_sum += item.zakazs_sum;
                                    if (item.count_fake) self.count_fake += item.count_fake;
                                }


                            }, self);
                        }
                    })
                    .catch(function (error) {
                        console.log(error.response);
                    });
            //alert(JSON.stringify(mas_months, null, 4));
        },
        pleasePayMe(partner_name) {

            let send = {
                id: partner_name.id,
                money: this.not_paid,
                id_zakazs: this.not_paid_ids,
            };


            axios.post(this.path_axios_pay_to_partner, {
                data: send
            })
                .then(response => {
                    $('#mes-edit').text('Запрос на выплату отправлен').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                    });
                    this.not_paid = 0;
                    //this.show_zakazs(partner_name);
                    console.log(response);
                })
                .catch(function (error) {
                    $('#mes-edit').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                    });
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        },
    },
}

</script>
