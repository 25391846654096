<template>
    <div>
        <template v-if="show_sel">
            <v-select :options="providers" @input="show_pays(selected)" class="form-control"
                      label="name"
                      placeholder="Введите как минимум 2 символа для поиска"
                      v-model="selected">
            </v-select>
        </template>

        <br>
        <br>

        <template v-if="show">
            <v-select :options="years" class="form-control"
                      label="name"
                      placeholder="Введите как минимум 2 символа для поиска"
                      v-model="selected_year"
                      @input="show_pays(selected)">
            </v-select>
        </template>

        <br>
        <br>

        <table class="table table-bordered mb-none" id="datatable-details">
            <thead>
            <tr>
                <th style="pointer-events: none; cursor: default; width:16px;">Месяц</th>
                <th>Отправленно на сумму</th>
                <th>Отправлено штук</th>
                <th>Средний чек</th>
                <th>Вернулось</th>
                <th>Выплачено</th>
                <th>Остаток</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="pay in mas_months">
                    <td>{{pay.month}}</td>
                    <td>{{pay.sum_send}}</td>
                    <td>{{pay.count_send}}</td>
                    <td>{{pay.average}}</td>
                    <td>{{pay.count_back}}</td>
                    <td>{{pay.sum_sell}}</td>
                    <td>{{pay.sum_ost}}</td>
                </tr>
            <tr>
                <td><b>Всего</b></td>
                <td><b>{{all_stat.sum_send}}</b></td>
                <td><b>{{all_stat.count_send}}</b></td>
                <td><b>{{all_stat.average}}</b></td>
                <td><b>{{all_stat.count_back}}</b></td>
                <td><b>{{all_stat.sum_sell}}</b></td>
                <td><b>{{all_stat.sum_ost}}</b></td>
            </tr>
            </tbody>
        </table>


        <div id="loader"><span></span></div>
        <div id="mes-edit"></div>

    </div>
</template>
<script>//

export default {
    created() {
        this.getCurrentUser();
    },
    data() {
        return {
            providers: [],
            pays: [],
            pays_show: [],
            selected: 0,
            selected_year: 0,
            years: [],
            show: 0,
            currentUser: [],
            show_sel: 0,
            mas_months: [],
            all_stat: [],
        }
    },
    props:
        {
            path_axios_get_list_provider: {
                type: String,
                default: ''
            },
            path_axios_get_current_user: {
                type: String,
                default: ''
            },
            path_axios_get_stats_for_provider_pays: {
                type: String,
                default: ''
            },
        },
    methods: {
        getCurrentUser() {
            //console.log(JSON.stringify('response.data', null, 4));
            axios.get(this.path_axios_get_current_user).then(response => {
                //response.data.role == 1 ? item.show = true : item.show = false;
                this.currentUser = response.data;
                this.currentUser.role == 1 ? this.show_sel = true : this.show_sel = false;
                //console.log(JSON.stringify(this.currentUser.role, null, 4));
            })
                .then(response => {
                    this.getProviders();
                    console.log(response);
                })
                .catch(function (error) {
                    //alert(error.response);
                    console.log(error.response);
                });
        },
        getProviders() {
            axios.get(this.path_axios_get_stats_for_provider_pays).then(response => {

                this.providers = Array.from(response.data['provider']);
                delete response.data['provider'];
                this.pays = response.data;
                //console.log(JSON.stringify(response.data, null, 4));
                //console.log(JSON.stringify(this.pays, null, 4));
            })
                .then(response => {
                    this.providers.forEach(function (item, i) {
                        //console.log(item.id, this.currentUser.id, item.id_in_vue, this.selected);
                        if (item.id == this.currentUser.id) {
                            this.show_pays(item);
                        }
                    }, this);
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
        show_pays(provider) {

            $('#datatable-details').DataTable().destroy();
            this.show = 1;
            this.years = [];
            this.pays_show = [];
            this.all_stat = [];

            this.pay_money = 0;
            this.pay_times = 0;
            
            for (let key in this.pays) {
                if (key.slice(0, -7) == provider.id) {
                    this.pays_show.push({
                        //id: item.id,
                        month: key.substr(-2, 2),
                        year: key.slice(-7, -3),
                        sum_send: Math.round(this.pays[key].sum_send * provider.percent / 100),
                        sum_sell: Math.round(this.pays[key].sum_sell * provider.percent / 100),
                        sum_ost: Math.round(this.pays[key].sum_ost * provider.percent / 100),
                        count_send: this.pays[key].count_send,
                        count_back: this.pays[key].count_back,
                        average: Math.round(this.pays[key].average * provider.percent / 100),
                        //percent: provider.percent
                    });
                    this.years.push(
                        key.slice(-7, -3)
                    );
                }
            }

            function uniqueVal(value, index, self) {
                return self.indexOf(value) === index;
            }

            this.years = this.years.filter(uniqueVal).sort().reverse();

            if (this.selected_year == 0 || this.selected_year == null) this.selected_year = this.years[0];
            if (!this.years[0]) this.selected_year = null;

            this.all_stat.sum_send = 
            this.all_stat.sum_sell = 
            this.all_stat.sum_ost = 
            this.all_stat.count_send =
            this.all_stat.average = 
            this.all_stat.count_back = 0;


            let flag = 0;
            let month = new Date();
            let currentYear = this.selected_year ? this.selected_year : month.getFullYear();

            this.pays_show.sort((a, b) => (a.year == b.year && a.month > b.month) ? 1 : -1);

            this.pays_show.forEach(function (item, i) {
                if (item.year == currentYear) {
                    month.setMonth(item.month - 1);
                    this.mas_months.push({
                        id: i,
                        month: month.toLocaleString('ru', {
                            month: 'long'
                        }),
                        year: currentYear,
                        sum: 0,
                        sum_send : item.sum_send, 
                        sum_sell : item.sum_sell, 
                        sum_ost : item.sum_ost,
                        count_send : item.count_send,
                        count_back : item.count_back,
                        average : item.average
                    });

                    if (item.average) flag++;
                    this.all_stat.sum_send += item.sum_send;
                    this.all_stat.sum_sell += item.sum_sell;
                    this.all_stat.sum_ost += item.sum_ost;
                    this.all_stat.count_send += item.count_send;
                    this.all_stat.count_back += item.count_back;
                    this.all_stat.average += item.average;

                }
            }, this);
            if (flag != 0) this.all_stat.average /= flag;

            this.mas_months.reverse();

            $('#datatable-details').DataTable({
                paging: false,
                bFilter: false,
                aoColumnDefs: [{
                    bSortable: false,
                    aTargets: [0, 1, 2, 3, 4, 5]
                }],
                "bPaginate": false,
                "bInfo": false,
                aaSorting: [
                    [1, 'desc']
                ]
            });
        },
    },
}

</script>
