<template>
    <div>
        <v-select :options="partners" @input="show_zakazs(selected)" class="form-control"
                  label="name"
                  placeholder="Введите как минимум 2 символа для поиска"
                  v-model="selected">
        </v-select>

        <br>
        <br>
        <table :id="'datatable-details'" class="table table-bordered mb-none">
            <thead>
            <tr>
                <th></th>
                <th style="pointer-events: none; cursor: default;">ID</th>
                <th>Дата заказа</th>
                <th>Сумма вознаграждения</th>
                <th>Выплачен</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="zakaz in zakazs">
                <template v-if="zakaz.show">
                    <tr v-bind:class="{'paid': zakaz.id_partner.id_status_partner_pay.name == 'Да', 'no_paid': zakaz.id_partner.id_status_partner_pay.name == 'Нет'}">
                        <td class="text-center"
                            v-on:click="plus_minus(zakaz, zakaz.id_in_vue), zakaz.show = !zakaz.show"><i
                            class="fa text-primary h5 m-none"
                            data-toggle
                            style="cursor: pointer;"
                            v-bind:class="{ 'fa-plus-square-o': zakaz.isPlus, 'fa-minus-square-o': zakaz.isMinus}"></i>
                        </td>
                        <td>{{zakaz.id}}</td>
                        <td>{{zakaz.created_at}}</td>
                        <td>{{zakaz.id_partner.sum}}</td>
                        <td>{{zakaz.id_partner.id_status_partner_pay.name}}</td>
                    </tr>
                    <tr class="details">
                        <td class='details' colspan="11">
                            <table class="table mb-none">
                                <tbody>


                                <tr>
                                    <td>Состав заказа &darr;</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="2" width="100%">
                                        <table class="table-striped mb-none">
                                            <tr v-for="stuff in zakaz.stuff_in_zakaz">
                                                <td>{{stuff.name}} - <b> {{stuff.kolvo}} шт</b></td>
                                                <td>
                                                    <gallery :images="stuff.img1" :index="stuff.index"
                                                             @close="stuff.index = null"></gallery>
                                                    <div
                                                        :key="imageIndex"
                                                        :style="{ backgroundImage: 'url(' + image + ')', width: '120px', height: '120px' }"
                                                        @click="stuff.index = imageIndex"
                                                        class="image"
                                                        v-for="(image, imageIndex) in stuff.img1"
                                                    ></div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td></td>
                                    <td>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Всего &rarr; <b>{{zakaz.kolvo}} шт.</b></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td v-if="zakaz.id_partner.id_status_partner_pay.id == 4">Комментарий к заказу: {{zakaz.comments}}</td>
                                    <td v-else></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>


                                </tbody>
                            </table>
                        </td>
                    </tr>
                </template>
                <template v-else>
                    <template v-if="zakaz.in_range">
                        <tr v-bind:class="{'paid': zakaz.id_partner.id_status_partner_pay.name == 'Да', 'no_paid': zakaz.id_partner.id_status_partner_pay.name == 'Нет'}">
                            <td class="text-center"
                                v-on:click="plus_minus(zakaz, zakaz.id_in_vue), zakaz.show = !zakaz.show"><i
                                class="fa text-primary h5 m-none"
                                data-toggle
                                style="cursor: pointer;"
                                v-bind:class="{ 'fa-plus-square-o': zakaz.isPlus, 'fa-minus-square-o': zakaz.isMinus}"></i>
                            </td>
                            <td>{{zakaz.id}}</td>
                            <td>{{zakaz.created_at}}</td>
                            <td>{{zakaz.id_partner.sum}}</td>
                            <td>{{zakaz.id_partner.id_status_partner_pay.name}}</td>
                        </tr>
                    </template>
                </template>
            </template>
            </tbody>
        </table>

        <template v-if="show">

            <div class="panel-body">
                <div class="col-md-3">
                    <span class="show-grid-block">Карта
                        <input @blur="onBlur()" @focus="onFocus()" class="form-control"
                               type="text"
                               v-model="selected.card_number">
                            </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">Яндекс
                        <input @blur="onBlur()" @focus="onFocus()" class="form-control"
                               type="text"
                               v-model="selected.yandex">
                            </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">Киви
                        <input @blur="onBlur()" @focus="onFocus()" class="form-control"
                               type="text"
                               v-model="selected.qiwi">
                    </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">PayPal
                        <input @blur="onBlur()" @focus="onFocus()" class="form-control"
                               type="text"
                               v-model="selected.paypal">
                    </span>
                </div>
            </div>

            <div class="panel-body">
                <div class="col-md-3">
                    <span class="show-grid-block">Всего выплачено: <b>{{paid}} руб.</b></span>
                </div>

                <div class="col-md-3">
                    <span class="show-grid-block">Остаток по вылатам: <b>{{not_paid}} руб.</b></span>
                </div>

                <div class="col-md-3">
                    <span class="show-grid-block">ФИО: <b>{{selected.name_self}}</b></span>
                </div>
            </div>

            <div class="panel-body">
                <button @click="pleasePayMe(selected)" class="mb-xs mt-xs mr-xs btn btn-primary" type="button">Запрос на
                    выплату {{not_paid}} руб.
                </button>
            </div>


        </template>

        <br>
        <br>
        <br>
        <div class="form-group">
            <label class="col-md-3 control-label">Выбрать промежуток</label>
            <div class="col-md-8">
                <div class="input-daterange col-md-8 col-md-offset-1 input-group" data-plugin-datepicker>
														<span class="input-group-addon">
															<i class="fa fa-calendar"></i>
														</span>
                    <input class="form-control" name="start" id="start" type="text" v-model="start_date">
                    <span class="input-group-addon">до</span>
                    <input class="form-control" name="end" type="text" v-model="end_date">
                </div>
            </div>
        </div>
        <div class="col-md-5"></div>
        <button @click="makeMagic1()" class="mb-xs mt-xs mr-xs btn btn-primary" type="button">Показать</button>

        <div id="loader"><span></span></div>
        <div id="mes-edit"></div>

    </div>
</template>
<script>//

import VueGallery from 'vue-gallery'

Vue.component('vue-gallery', VueGallery);

export default {
    created() {

        this.getPartners();
        var date = new Date();
        date.setMonth(date.getMonth() - 1);
        this.start_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        date.setMonth(date.getMonth() + 1);
        this.end_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        localStorage.setItem('date0', this.start_date);
        localStorage.setItem('date1', this.end_date);

    },
    components: {
        'gallery': VueGallery
    },
    store: ['range'],
    data() {
        return {
            partners: [],
            zakazs: [],
            selected: 0,
            percent: 0,
            show: 0,
            paid: 0,
            not_paid: 0,
            stuff_to_zakaz: [],
            start_date: '',
            end_date: '',
        }
    },
    props:
        {

            path_axios_save_updated_partner: {
                type: String,
                default: ''
            },

            path_axios_get_list_partner: {
                type: String,
                default: ''
            },
            path_axios_get_list_zakaz_of_partner: {
                type: String,
                default: ''
            },
            path_axios_get_list_zakaz_detailed: {
                type: String,
                default: ''
            },
            path_axios_pay_to_partner: {
                type: String,
                default: ''
            },
        },
    methods: {
        makeMagic1() {
            $('#datatable-details').DataTable().destroy();

            //console.log(localStorage.getItem('date0'));
            //console.log(localStorage.getItem('date1'));

            this.range['date0'] = localStorage.getItem('date0');
            this.range['date1'] = localStorage.getItem('date1');

            var day_start = new Date(localStorage.getItem('date0'));
            //day_start.setDate(day_start.getDate() - 1);
            var day_end = new Date(localStorage.getItem('date1'));

            this.start_date = day_start.getFullYear() + '-' + ('0' + (day_start.getMonth() + 1)).slice(-2) + '-' + ('0' + (day_start.getDate())).slice(-2);
            this.end_date = day_end.getFullYear() + '-' + ('0' + (day_end.getMonth() + 1)).slice(-2) + '-' + ('0' + day_end.getDate()).slice(-2);
            day_end.setDate(day_end.getDate() + 1);

            this.zakazs.forEach(function (item, i) {
                var show10 = 0;
                var show11 = 0;
                var mydate = new Date(item.created_at);
                //console.log(mydate, day_start, day_end);
                if (this.range['date0'] != 'ull' && day_start >= mydate) show10 = 0; else show10 = 1;
                if (this.range['date1'] != 'ull' && day_end <= mydate) show11 = 0; else show11 = 1;
                if (show10 == 1 && show11 == 1) item.in_range = true;
                else item.in_range = false;
            }, this);

            /*            this.zakazs.forEach(function (item, i) {
                            //console.log(item.created_at.slice(0, 11));
                        });*/

            //localStorage.setItem('date0', day_start);
            //localStorage.setItem('date1', day_end);

            setTimeout(() => this.add_jquery(), 500);

        },
        getPartners() {
            axios.get(this.path_axios_get_list_partner).then(response => {
                response.data.forEach(function (item, i) {
                    item.name_self = item.name;
                    item.name = item.id_backend_users.name;
                    item.selected = '0';
                });
                this.partners = response.data;
                //console.log(JSON.stringify(this.partners[0], null, 4));
            })
                .then(function (response) {
                    //this.plus_minus();
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });


            //получаем детали заказа для раскрывашки
            axios.get(this.path_axios_get_list_zakaz_detailed).then(response => {

                this.stuff_to_zakaz = response.data;

                this.stuff_to_zakaz.forEach(function (item, i) {
                    item.img1 = [item.id_tov.img.split('\n')[0],];
                    item.index = null;
                });
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
        show_zakazs(partner_name) {
            this.show = 1;
            $('#datatable-details').DataTable().destroy();
            if (partner_name)

                axios.post(this.path_axios_get_list_zakaz_of_partner, {
                    data: partner_name.id
                })
                    .then(response => {
                        // handle success

                        this.percent = partner_name.percent;
                        response.data.forEach(function (item, i) {
                            item.isPlus = true;
                            item.isMinus = false;
                            item.in_range = true;
                            item.stuff_in_zakaz = [];
                            this.stuff_to_zakaz.forEach(function (item1, i) {
                                if (item1.id_zakaz == item.id)
                                    item.stuff_in_zakaz.push({
                                        index: null,
                                        img1: [item1.id_tov.img.split('\n')[0],],
                                        id: item1.id,
                                        id_zakaz: item1.id_zakaz,
                                        id_partner: item1.id_partner,
                                        kolvo: item1.kolvo,
                                        name: item1.id_tov.name,
                                    });
                            }, this);
                        }, this);
                        //console.log(JSON.stringify(response.data, null, 4));
                        this.zakazs = response.data;
                    })
                    .then(response => {
                        this.not_paid = 0;
                        this.paid = 0;
                        this.zakazs.forEach(function (item, i) {
                            if (item.id_partner.id_status_partner_pay.name == "Нет") {
                                this.not_paid += item.id_partner.sum;
                            }
                            if (item.id_partner.id_status_partner_pay.name == "Да") {
                                this.paid += item.id_partner.sum;
                            }

                        }, this);
                        this.add_jquery();
                        this.makeMagic1();
                        console.log(response);
                    })
                    .catch(function (error) {
                        console.log(error.response);
                    });
            //alert(JSON.stringify(partner_name.id, null, 4));
        },

        onFocus() {
            this.old_partner = JSON.stringify(this.selected);
        },
        //записываем изменения в базу
        onBlur() {

            //alert(JSON.stringify(this.selected, null, 4));
            //var input = document.getElementById('dateInput');
            //alert(input.value);

            //console.log(id);

            if (this.old_partner != JSON.stringify(this.selected))
                axios.post(this.path_axios_save_updated_partner, {
                    data: this.selected
                })
                    .then(function (response) {
                        // handle success
                        //console.log(this.zakazs[id]);
                        //alert(this.zakazs[id]);
                        $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                            $('#mes-edit').delay(1000).fadeOut();
                            //alert(id);
                        });
                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        $('#mes-edit1').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                            $('#mes-edit1').delay(1000).fadeOut();
                            //alert(id);
                        });
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
        },

        pleasePayMe(partner_name) {

            //alert(JSON.stringify(partner_name, null, 4));
            //var input = document.getElementById('dateInput');
            //alert(input.value);

            //console.log(partner_name.id);

            //if (partner_name)
            var id_zakazs_mas = [];
            this.zakazs.forEach(function (item, i) {
                if (item.id_partner.id_status_partner_pay.name == "Нет") {
                    id_zakazs_mas.push(item.id);
                }
            }, this);

            var send = {
                id: partner_name.id,
                money: this.not_paid,
                id_zakazs: id_zakazs_mas,
            };

            //console.log(JSON.stringify(send, null, 4));

            axios.post(this.path_axios_pay_to_partner, {
                data: send
            })
                .then(response => {
                    // handle success
                    //console.log(this.zakazs[id]);
                    //alert(this.zakazs[id]);
                    $('#mes-edit').text('Запрос на выплату отправлен').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                        //alert(id);
                    });
                    this.show_zakazs(partner_name);
                    console.log(response);
                })
                .catch(function (error) {
                    // handle error
                    $('#mes-edit1').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                        $('#mes-edit1').delay(1000).fadeOut();
                        //alert(id);
                    });
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        },

        add_jquery() {

	    let dtable = $('#datatable-details');

	    if ( $.fn.DataTable.isDataTable('#datatable-details') ) {
  		dtable.DataTable().destroy();
	    }


            dtable.DataTable({
                paging: true,
                bFilter: false,
                aoColumnDefs: [{
                    bSortable: false,
                    aTargets: [0, 1, 2, 3, 4]
                }]
                ,
                aaSorting: [
                    [1, 'desc']
                ],
                "bAutoWidth": false,
                "bPaginate": true,
                "bInfo": true,
            });



/*
            var datatableInit = function () {
                var $table = $('#datatable-details');

                // format function for row details
                var fnFormatDetails = function (datatable, tr) {
                    var data = datatable.fnGetData(tr);
                };

                // initialize
                var datatable = $table.dataTable({
                    aoColumnDefs: [{
                        bSortable: false,
                        aTargets: [0, 1, 2, 3, 4]
                    }],
                    aaSorting: [
                        [1, 'asc']
                    ]
                });
            };

            $(function () {
                datatableInit();
            });

*/
        },

        plus_minus(zakaz, id) {
            if (zakaz.isPlus == true) {
                zakaz.isPlus = false;
                zakaz.isMinus = true;
            } else {
                zakaz.isPlus = true;
                zakaz.isMinus = false;
            }
        },
    },
}

</script>
