<template>
    <div>
        <div class="row show-grid">
            <div class="panel-body" v-for="newprod in newproduct">
                <div class="col-md-6"><span class="show-grid-block">Выбрать товар <span
                        class="required">*</span>
                            <div class="input-group btn-group">
                                                <span class="input-group-addon">
														<i class="fa fa-shopping-cart"></i>
													</span>
                                <!--                                <v-select class="form-control populate" data-plugin-options='{ "minimumInputLength": 2 }'
                                                                         data-plugin-selectTwo
                                                                         >
                                                                        <option selected value="">Введите как минимум 2 символа для поиска</option>
                                                                        <option v-bind:value="product.id"
                                                                                v-for="product in products">{{product.name}}</option>
                                                                </v-select>-->
                                 <v-select :options="products" @input="plusp(newprod.id_on_page)" class="form-control populate" label="name"
                                           placeholder="Введите как минимум 2 символа для поиска"
                                           v-model="newprod.selected">
                                 </v-select>


                                <!-- <select v-model="newprod.id_tov" class="form-control populate">
                                                                         <option selected value="">Введите как минимум 2 символа для поиска</option>
                                                                         <option v-bind:value="product.id"
                                                                                 v-for="product in products">{{product.name}}</option>
                                                                 </select>-->
                            </div>
                            </span>
                </div>
                <div class="col-md-2">
                    <div class="layout-content-page-menu-block3-goods-quantity-text1"></div>
                    <span class="show-grid-block">Количество<div>
                                <div class="input-group" style="width:150px;">
                                    <input :value="newprod.kolvo" class="spinner-input form-control" maxlength="3" readonly type="text">
                                    <div class="spinner-buttons input-group-btn">
                                        <button @click="plus(newprod.id_on_page), all_kolvo()" class="btn btn-default spinner-up" type="button">
                                            <i class="fa fa-angle-up"></i>
                                        </button>

                                         <button @click="minus(newprod.id_on_page), all_kolvo()" class="btn btn-default spinner-down" type="button">
                                            <i class="fa fa-angle-down"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </span>
                </div>
                <div class="col-md-2">
                    <span class="show-grid-block">Цена, шт.
                        <input class="form-control" disabled
                               v-model="newprod.price * client.zakaz.skidka + ' руб'"/>
                            </span>
                </div>
                <div class="col-md-2"><span class="show-grid-block"><br><button @click="delProduct(newprod.id_on_page)"
                                                                                class="mb-xs mt-xs mr-xs btn btn-xs btn-primary"
                                                                                type="button">Удалить товар</button></span>
                </div>
                <div class="col-md-12">
                    <section class="panel">
                        <header class="panel-heading">
                            <div class="panel-actions">
                                <a class="fa fa-caret-down" href="#"></a>
                                <a class="fa fa-times" href="#"></a>
                            </div>


                            <h2 class="panel-title">Подробное описание товара</h2>
                            <p class="panel-subtitle">{{newprod.name}}</p>
                        </header>

                        <div class="panel-body">
                            <p class="pb-lg pre-formatted">{{newprod.description}}</p>
                            <gallery :images="newprod.img" :index="index" @close="index = null"></gallery>
                            <div
                                    :key="imageIndex"
                                    :style="{ backgroundImage: 'url(' + image + ')', width: '150px', height: '150px' }"
                                    @click="index = imageIndex"
                                    class="image"
                                    v-for="(image, imageIndex) in newprod.img"
                            ></div>
                        </div>


                        <!--<div class="panel-body">
                            <p class="pb-lg pre-formatted">{{newprod.description}}</p>
                            <div class="popup-gallery" v-for="img in newprod.img">
                                <a class="pull-left mb-xs mr-xs"  v-bind:href="img" v-bind:title="newprod.name">
                                    <div class="img-responsive">
                                        <img v-bind:src="img" width="105">
                                    </div>
                                </a>
                            </div>
                        </div>-->
                    </section>
                </div>


            </div>

            <div class="panel-body">
                <div class="col-md-3">
                    <button @click="addProduct" class="mb-xs mt-xs mr-xs btn btn-xs btn-primary" type="button">Добавить ещё товар</button>
                </div>
            </div>

            <div class="panel-body" style="float:left;">
                <div class="panel-body">
                    <div class="col-md-6">
                    <span class="show-grid-block">Ф.И.О. <span
                        class="required">*</span>
                        <input class="form-control" name="fullname" placeholder="напр.: Иванов Пётр Сидорович" required
                               type="text" v-model="client.name_1"/>
                            </span>
                    </div>
                    <div class="col-md-6">
                        <template v-if="foreign">
                    <span class="show-grid-block">Телефон
                        <span class="required">&nbsp</span>
                        <input v-model="client.tel" class="form-control" placeholder="+7 (123) 123-12-34">
                            </span>
                        </template>
                        <template v-else>
                            <span class="show-grid-block">Телефон <span
                                class="required">*</span>
                        <input v-model="client.tel" class="form-control" placeholder="+7 (123) 123-12-34">
                            </span>
                        </template>
                    </div>
                </div>

                <div class="panel-body">
                    <div class="col-md-6">
                    <span class="show-grid-block">Населённый пункт
                        <input class="form-control" name="fullname" placeholder="напр.: Москва" type="text"
                               v-model="client.city"/>
                            </span>
                    </div>
                    <div class="col-md-6">
                    <span class="show-grid-block">Улица
                        <input class="form-control" name="fullname" placeholder="напр.: Кутузовский проспект" type="text"
                               v-model="client.street"/>
                            </span>
                    </div>
                </div>



            </div>
            <div class="panel-body">
                <div class="panel-body">
                    <div class="col-md-4">
                    <span class="show-grid-block">Иностранный заказ
                        <textarea style="border-style: solid;"cols="45" name="text" rows="10" v-model="foreign"></textarea>
                            </span>
                    </div>
                </div>
            </div>

            <div class="panel-body">
                <div class="col-md-3">
                    <span class="show-grid-block">Дом
                        <input class="form-control" name="fullname" placeholder="напр.: 10" type="text"
                               v-model="client.house"/>
                            </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">Строение
                        <input class="form-control" name="fullname" placeholder="напр.: 4" type="text"
                               v-model="client.stroenie"/>
                            </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">Квартира
                        <input class="form-control" name="fullname" placeholder="напр.: 25" type="text"
                               v-model="client.flat"/>
                            </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">Индекс
                        <input class="form-control" name="fullname" placeholder="напр.: 600021" type="text"
                               v-model="client.index_adr"/>
                            </span>
                </div>
            </div>

            <div class="panel-body">
                <div class="col-md-3">
                    <span class="show-grid-block">Дата оплаты
                        <input :id="'dateInputPay'" @blur="setDate('Pay')" class="form-control" data-plugin-datepicker
                               type="text"
                               v-model="client.zakaz.day_pay">
                            </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">Дата доставки
                        <input :id="'dateInputDay'" @blur="setDate('Day')" class="form-control" data-plugin-datepicker
                               type="text"
                               v-model="client.zakaz.day_dost">
                            </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">Выбор доставки
                        <select class="form-control populate" name="select" v-model="client.zakaz.type_dostavka">
                            <option selected>Почтовая отправка</option>
                            <option>Доставка в пункт выдачи CDEK</option>
                            <option>Доставка курьером CDEK</option>
                            <option>Доставка в пункт выдачи Деловыми Линиями</option>
                            <option>Доставка другой транспортной компанией</option>
                        </select>
                    </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">Выбор оплаты
                        <select class="form-control populate" name="select" v-model="client.zakaz.type_oplata">
                            <option>Перевод между юрлицами</option>
                            <option>Зачисление на карту</option>
                            <option selected>Оплата при получении</option>
                        </select>
                    </span>
                </div>
            </div>

            <div class="panel-body">
                <div class="col-md-3">
                    <span class="show-grid-block">Комментарий к заказу
                        <textarea style="border-style: solid;"cols="45" name="text" rows="10" v-model="client.zakaz.comments"></textarea>
                            </span>
                </div>
            </div>

            <footer class="panel-footer">
                <div class="row">
                    <div class="col-sm-9 col-sm-offset-3">
                        <button @click="addToBase" class="btn btn-primary" id="show-modal">Добавить заказ в базу</button>
                    </div>
                </div>
            </footer>
        </div>
        <modal @close="showModal = false" v-if="showModal">
            <!--
              you can use custom content here to overwrite
              default content

            <h3 slot="header">custom header</h3>-->
        </modal>
        <div id="mes-edit2"></div>
    </div>
</template>
<script>
    import VueGallery from 'vue-gallery'

    Vue.component('vue-gallery', VueGallery);
    export default {
        created() {
            this.getProducts()
        },
        components: {
            'gallery': VueGallery
        },
        data() {
            return {
                images: [],
                index: null,
                products: [],
                newproduct: [],
                id_tov: '',
                nextId: 0,
                showModal: false,
                price: 0,
                flag: 0,
                foreign: '',
                foreign1: '',


                client: {
                    name_1: '',
                    name_2: '',
                    name_3: '',
                    tel: '',
                    city: '',
                    street: '',
                    house: '',
                    stroenie: '',
                    flat: '',
                    index_adr: '',
                    black_list: '0',
                    comments: '',
                    foreign_adr: '',
                    zakaz: {
                        id: '',
                        sum: 0,
                        id_status: 1,
                        id_client: 1,
                        id_courier: 1,
                        comments: '',
                        day_pay: '',
                        day_dost: '',
                        type_dostavka: '',
                        type_oplata: '',
                        kolvo: 0,
                        id_backend_users: 0,
                        opt: 1,
                        skidka: 1,
                    },
                    newproduct: [],
                },
            }
        },
        props: {
            path_axios_get_list_of_products_to_add: {
                type: String,
                default: ''
            },
            path_axios_get_list_of_products_to_update: {
                type: String,
                default: ''
            },
            path_axios_store_to_db_zakaz: {
                type: String,
                default: ''
            },
            path_open_new_page: {
                type: String,
                default: '/'
            },
            user_id: {
                type: String,
                default: ''
            },
        },
        watch: {
            foreign:function(val) {
                this.client.foreign_adr = val;
                if (val != '') this.foreign1 = true; else this.foreign1 = false;
            },
        },
        methods: {
            //получает список всех доступных продуктов для данной категории (блюдо или набор) Вызывается при создании компонента.
            getProducts() {
                axios.get(this.path_axios_get_list_of_products_to_add).then(response => {
                    this.products = response.data;

                    //alert(JSON.stringify(this.products[0], null, 4));;


                    /*                //запись нового поля в массив продуктов. в поле newid хранится id, которое соответствует положению продукта в списке всепх продуктов. Ввести пришлось из-за сортировки продуктов по алфавиту, а не id из базы
                                    var i = 0;
                                    this.products.forEach(function (prod) {
                                        prod.newid = i++;
                                    })*/

                    //условие добавления нового или обновления существующего блюда или набора
                    //if (this.path_axios_get_list_of_products_to_update != '') this.getProductsForThis(); else this.addProduct();
                    //alert(this.user_id);
                    this.addProduct();
                })
                    .then(function (response) {
                        console.log(response);
                    })
                    .catch(function (error) {
                        console.log(error.response);
                    });
            },
            //добавление нового продукта на страницу по нажатию кнопки "добавить продукт".
            addProduct() {
                this.nextId = this.newproduct.length;
                this.newproduct.push({id_on_page: this.nextId++, id_tov: '0', kolvo: '1', price: '0', description: ''});
                this.all_kolvo();
            },
            //удаление продукта по нажатию на кнопку "удалить продукт". В качестве параметра id продукта на странице.
            delProduct: function (ID) {
                //Удаляет из массива
                this.newproduct.splice(ID, 1);
                //теперь нужно заново присвоить индексы обновлённому массиву продуктов
                this.newproduct.forEach(function (newprod, index) {
                    newprod.id_on_page = index;
                });
            },
            plus(thisBludo) {
                this.newproduct[thisBludo].kolvo++;
                //alert(this.newproduct[thisBludo].selected.id);
                /*this.newproduct[thisBludo].price = this.products.find(item => item.id == (this.newproduct[thisBludo].selected.id)).price;
                this.newproduct[thisBludo].id_tov = this.products.find(item => item.id == (this.newproduct[thisBludo].selected.id)).id;*/
                //alert(JSON.stringify(this.zakaz, null, 4));
            },

            plusp: function (thisBludo) {
                this.newproduct[thisBludo].price = this.products.find(item => item.id == (this.newproduct[thisBludo].selected.id)).price;
                this.newproduct[thisBludo].id_tov = this.products.find(item => item.id == (this.newproduct[thisBludo].selected.id)).id;
                this.newproduct[thisBludo].description = this.products.find(item => item.id == (this.newproduct[thisBludo].selected.id)).description;
                this.newproduct[thisBludo].img = this.products.find(item => item.id == (this.newproduct[thisBludo].selected.id)).img.slice(0, -1).split('\n\n');
                this.newproduct[thisBludo].name = this.products.find(item => item.id == (this.newproduct[thisBludo].selected.id)).name;
                //console.log(JSON.stringify(this.newproduct[thisBludo].img, null, 4));
            },

            minus(thisBludo) {
                if (this.newproduct[thisBludo].kolvo > 0)
                    this.newproduct[thisBludo].kolvo--;
            },
            addToBase: function () {

                this.newproduct.forEach(function (newprod) {
                    //alert(newprod.kolvo * newprod.price);
                    this.sum += newprod.kolvo * newprod.price;
                    //var qwe = newprod.id_tov;
                    //newprod.id_tov = {"id":qwe};
                }, this.client.zakaz);

                this.client.zakaz.skidka = (1 - this.client.zakaz.skidka) * 100;
                this.client.zakaz.id_backend_users = this.user_id;
                this.client.wholesale = true;

                var proceed = 1;
                if (this.newproduct[0].id_tov == 0) proceed = 0;
                if (this.client.name_1 == '') proceed = 0;
                if ((this.client.tel == '') &&  (this.client.foreign == '')) proceed = 0;

                if (proceed == 1) {

                    this.client.newproduct = this.newproduct;
                    axios.post(this.path_axios_store_to_db_zakaz, {
                        data: this.client
                    })
                        .then(function (response) {
                            console.log(response);
                        })
                        .catch(function (error) {
                            console.log(error);
                        })

                    location.href = this.path_open_new_page;
                }
                else
                {
                    $('#mes-edit2').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                        $('#mes-edit2').delay(1000).fadeOut();
                        //alert(id);
                    });
                }
            },
            setDate(id) {
                $("#dateInput" + id).datepicker({}).on("changeDate", function (e) {
                    var event = new Event('input');
                    this.dispatchEvent(event);
                });
            },
            show_alert(text, skidka) {
                $('#mes-edit').text(text).delay(500).fadeIn(1000, function () {
                    $('#mes-edit').delay(1000).fadeOut();
                });
                this.client.zakaz.skidka = skidka;
            },
            all_kolvo()
            {
                let old_kolvo = this.client.zakaz.kolvo;
                this.client.zakaz.kolvo = 0;
                this.newproduct.forEach(function (newprod) {
                    this.kolvo += +newprod.kolvo;
                }, this.client.zakaz);

                switch(this.client.zakaz.kolvo) {
                    case(9)   :   if (old_kolvo > this.client.zakaz.kolvo)  this.show_alert('Товаров меньше 10, скидки нет.' , 1); break;
                    case(10)  :   if (old_kolvo < this.client.zakaz.kolvo)  this.show_alert('Товаров больше 10, скидка 25%.' , 0.75); break;
                    case(14)   :  if (old_kolvo > this.client.zakaz.kolvo)  this.show_alert('Товаров больше 10, скидка 25%.' , 0.75); break;
                    case(16)  :   if (old_kolvo < this.client.zakaz.kolvo)  this.show_alert('Товаров больше 15, скидка 35%.' , 0.65); break;
                }
            },
        },
    }

</script>
