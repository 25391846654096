<template>
    <div>
        <template v-if="show">
        <table class="table table-bordered table-striped mb-none" >
            <tr>
                <th class="courier">Параметр</th>
                <th>Значение</th>
            </tr>
            <tr>
                <td>ID заказа</td>
                <td>{{zakaz.zakaz.id}}</td>
            </tr>
            <tr>
                <td>Адрес клиента</td>
                <td v-if="zakaz.zakaz.id_client.stroenie">г. {{zakaz.zakaz.id_client.city}}, ул. {{zakaz.zakaz.id_client.street}}, д. {{zakaz.zakaz.id_client.house}},
                    строение. {{zakaz.zakaz.id_client.stroenie}}, кв. {{zakaz.zakaz.id_client.flat}}
                </td>
                <td v-else >г. {{zakaz.zakaz.id_client.city}}, ул. {{zakaz.zakaz.id_client.street}}, д. {{zakaz.zakaz.id_client.house}}, кв.
                    {{zakaz.zakaz.id_client.flat}}
                </td>
            </tr>
            <tr>
                <td>ФИО клиента</td>
                <td>{{zakaz.zakaz.id_client.name_1}}</td>
            </tr>
            <tr>
                <td>Телефон клиента</td>
                <td>{{zakaz.zakaz.id_client.tel}}</td>
            </tr>
            <tr>
                <td>Состав заказа</td>
                <td>
                    <ol type="1">
                        <li v-for="stuff in zakaz.stuff_to_zakaz">
                            {{stuff.id_tov.name}} - <b> {{stuff.id_tov.kolvo}} шт</b>

                        </li>
                    </ol>
                </td>
            </tr>
            <tr>
                <td>Сумма к оплате</td>
                <td>{{zakaz.zakaz.sum  * (100 - zakaz.zakaz.skidka) / 100}}</td>
            </tr>
            <tr>
                <td>Комментарий к заказу</td>
                <td>{{zakaz.zakaz.comments}}</td>
            </tr>
        </table>


        <div class="panel-body">
            <button class="mb-xs mt-xs mr-xs btn btn-primary" type="button" @click="zakazDone">Заказ сдан</button>
            <button class="mb-xs mt-xs mr-xs btn btn-danger" type="button" @click="addProblem">Возникла проблема</button>
        </div>
        </template>
        <template v-else>
            <h6 v-if="show_problem">Оператор проинформирован о проблеме, ждите ответа или посмотрите список доступных заказов для выполнения.</h6>
            <h6 v-if="show_done">Заказ сдан, посмотрите список доступных заказов для выполнения.</h6>
            <h6 v-if="!show_done && !show_problem">Посмотрите список доступных заказов для выполнения.</h6>
        </template>
    </div>

</template>
<script>//

export default {
    created() {
        this.getCurrentZakaz();
    },
    data() {
        return {
            zakaz: [],
            show: 0,
            show_problem: 0,
            show_done: 0,
        }
    },
    props:
    {
        path_axios_my_order_details: {
            type: String,
            default: ''
        },
        path_axios_add_problem_zakaz: {
            type: String,
            default: ''
        },
        path_axios_zakaz_done: {
            type: String,
            default: ''
        },
    },
    methods: {
        getCurrentZakaz() {
           axios.get(this.path_axios_my_order_details).then(response => {
               this.zakaz = response.data;
               //console.log(JSON.stringify(response.data, null, 4));
               if (this.zakaz != '') this.show = 1;
           })
               .then(response => {
                   //console.log(response);
               })
               .catch(function (error) {
                   console.log(error.response);
               });
        },
        addProblem()
        {
            //alert(this.zakaz.id);
            axios.post(this.path_axios_add_problem_zakaz, {
                data: this.zakaz.zakaz.id
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                })
            this.show = 0;
            this.show_problem = 1;
        },
        zakazDone()
        {
            axios.post(this.path_axios_zakaz_done, {
                data: this.zakaz.zakaz.id
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                })

            this.show = 0;
            this.show_done = 1;
        },
    },
}

</script>
