<template>
    <div class="panel-body">
        <p>&nbsp</p>
        <div style="    width: 100%;
    font-size: 20px;
    text-align: center;
    border: 1px solid #96c78e;
    border-radius: 4px;
    height: 150px;">{{partner.text}}
        </div>
        <p>&nbsp</p>
            <div class="col-md-6">
                <div style="
                    float: left;
                    width: 190px;
                    height: 100px;
                    font-size: 18px;">
                    Ваше партнёрское вознаграждение от стоимости заказа
                </div>
                <div style="
                    float: left;
                    width: 70px;
                    height: 100px;
                    font-size: 36px;
                    text-align: center;
                    border: 1px solid #96c78e;
                    border-radius: 4px;
                    height: 65px;">
                    <span style="
                            position: relative;
                            top: 33%;">
                        {{partner.percent}}
                    </span>
                </div>
                <div style="
                    float: left;
                    width: 50px;
                    height: 65px;
                    font-size: 34px;"><span style="
                            position: relative;
                            top: 33%;">&nbsp%
                    </span>
                </div>
            </div>

            <div class="col-md-6" style="top: 16px;">
                <div class="form-group">
                    <label class="col-sm-6 control-label" style="top: 6px; font-size: 18px;">UTM-метка</label>
                    <div class="col-sm-6 " >
                        <div class="form-control1" style="
                    float: left;
                    text-align: center;
                    border: 1px solid #96c78e;
                    border-radius: 4px;">
                    <span>
                        {{partner.utm}}
                    </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 center" style="font-size: 18px;">
                Данные для получения выплат
            </div>

            <div class="col-md-12" style="top: 16px;">
                <div class="form-group">
                    <label class="col-md-offset-3 col-sm-1 control-label" style="top: 6px; font-size: 18px;">ФИО</label>
                    <div class="col-sm-5">
                        <input @blur="onBlur()" @focus="onFocus()" class="form-control1"
                               type="text"
                               v-model="partner.p_name">
                    </div>
                </div>
            </div>

        <p>&nbsp</p>
        <p>&nbsp</p>
        <div class="col-md-12" style="top: 40px; font-size: 18px; padding-bottom: 40px;">
            <div class="col-md-3">
                    <span class="show-grid-block">Карта
                        <input @blur="onBlur()" @focus="onFocus()" class="form-control1"
                               type="text"
                               v-model="partner.card_number">
                            </span>
            </div>
            <div class="col-md-3">
                    <span class="show-grid-block">Яндекс
                        <input @blur="onBlur()" @focus="onFocus()" class="form-control1"
                               type="text"
                               v-model="partner.yandex">
                            </span>
            </div>
            <div class="col-md-3">
                    <span class="show-grid-block">Киви
                        <input @blur="onBlur()" @focus="onFocus()" class="form-control1"
                               type="text"
                               v-model="partner.qiwi">
                    </span>
            </div>
            <div class="col-md-3">
                    <span class="show-grid-block">PayPal
                        <input @blur="onBlur()" @focus="onFocus()" class="form-control1"
                               type="text"
                               v-model="partner.paypal">
                    </span>
            </div>

        </div>


        <div id="mes-edit"></div>

    </div>
</template>
<script>//

export default {
    created() {
        this.getPartnerInfo();
    },
    data() {
        return {
            partner: [],
            old_partner: [],
        }
    },
    props:
        {
            path_axios_save_partner_info: {
                type: String,
                default: ''
            },
            path_axios_get_partner_info: {
                type: String,
                default: ''
            },
        },
    methods: {
        getPartnerInfo() {
            axios.get(this.path_axios_get_partner_info).then(response => {
                this.partner = response.data;
                //console.log(JSON.stringify(response.data, null, 4))
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },

        onFocus() {
            this.old_partner = JSON.stringify(this.partner);
        },
        //записываем изменения в базу
        onBlur() {
            //console.log(JSON.stringify(this.partners[id], null, 4));

            if (this.old_partner != JSON.stringify(this.partner))

                axios.post(this.path_axios_save_partner_info, {
                    data: this.partner
                })
                    .then(function (response) {
                        // handle success
                        $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                            $('#mes-edit').delay(1000).fadeOut();
                        });
                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        $('#mes-edit1').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                            $('#mes-edit1').delay(1000).fadeOut();
                            //alert(id);
                        });
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
        },
    },
}

</script>
