<style>
.tborder {
    border: 1px solid #dddddd;
    border-collapse: collapse;  
}
</style>
<template>
    <div>

        <table class="table table-bordered mb-none" id="datatable-details">
            <thead>
            <tr>
                <th></th>
                <th style="pointer-events: none; cursor: default;">ID</th>
                <th>Наименование</th>
                <th>Цена</th>
                <th>Остатки</th>
                <th>Поставщик</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="tovar in tovars">
                <template v-if="!tovar.show">
                    <tr>
                        <td v-on:click=" tovar.show = !tovar.show">
                            <i class="fa text-primary h5 m-none" data-toggle style="cursor: pointer;"
                                :class="{ 'fa-plus-square-o': !tovar.show, 'fa-minus-square-o': tovar.show }">
                            </i>
                        </td>
                        <td class="center">{{tovar.id}}</td>
                        <td >{{tovar.name}}</td>
                        <td class="center">{{tovar.price}}</td>
                        <td><input class="form-control1" @blur="onBlur(tovar)" @focus="onFocus(tovar)" v-model="tovar.kolvo"></td>
                        <td><select  class="form-control1" @change="onBlur(tovar)" v-model="tovar.provider">
                            <option :value="provider.name" v-for="provider in providers" >{{provider.name}}</option>
                        </select></td>
                    </tr>
                </template>

                <template v-else>
                    <tr>
                    <td colspan="6" style="margin: 0; padding: 0;">
                    <table class="table mb-none">
                    <tbody>

                    <tr>
                        <td class="tborder" v-on:click="tovar.show = !tovar.show" style="width:3.1956%;">
                            <i class="fa text-primary h5 m-none" data-toggle style="cursor: pointer;"
                                :class="{ 'fa-plus-square-o': !tovar.show, 'fa-minus-square-o': tovar.show }">
                            </i>
                        </td>
                        <td class="tborder center" style="width:3.9938%;">{{tovar.id}}</td>
                        <td class="tborder center" style="width:41.9755%;">{{tovar.name}}</td>
                        <td class="tborder center">{{tovar.price}}</td>
                        <td class="tborder" style="width:24.4732%;"><input class="form-control1" @blur="onBlur(tovar)" @focus="onFocus(tovar)" v-model="tovar.kolvo"></td>
                        <td class="tborder" style="width:20.1891%;">
                            <select  class="form-control1" @change="onBlur(tovar)" v-model="tovar.provider">
                                <option :value="provider.name" v-for="provider in providers" >{{provider.name}}</option>
                            </select>
                        </td>
                    </tr>
                    <tr class="details">
                        <td class='details' colspan="6">
                            <table class="table mb-none">
                                <tbody>
                                    <tr>
                                        <td colspan="6">Описание &darr;</td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" width="100%">
                                            <table class="table-striped mb-none">
                                                <tr>
                                                    <td colspan="3">
                                                        {{tovar.name}} - <b> {{tovar.kolvo}} шт</b>
                                                    </td>
                                                    <td colspan="3"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="6">
                                                        <gallery :images="tovar.img1" :index="tovar.index" @close="tovar.index = null"></gallery>
                                                        <div
                                                            :key="imageIndex"
                                                            :style="{ backgroundImage: 'url(' + image + ')', width: '120px', height: '120px' }"
                                                            @click="tovar.index = imageIndex"
                                                            class="image"
                                                            v-for="(image, imageIndex) in tovar.img1"
                                                        ></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="6">Характеристики &darr;</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="6"><p>{{ tovar.characteristics != "" ? tovar.characteristics : 'Нет'}}</p></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="6"></td>
                                                </tr>                                               
                                                <tr>
                                                    <td colspan="6">Краткое описание &darr;</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="6"><p>{{tovar.description != "" ? tovar.description : 'Нет' }}</p></td>
                                                </tr>                                                       
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="6">Всего &rarr; <b>{{tovar.kolvo}} шт.</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    </tbody>
                    </table>
                    </td>
                    </tr>
                </template>
            </template>
            </tbody>
        </table>
        <div id="loader"><span></span></div>
        <div id="mes-edit"></div>

    </div>
</template>
<script>

import VueGallery from 'vue-gallery'

Vue.component('vue-gallery', VueGallery);

export default {
    created() {

        this.getTovars();
        this.getProviders();
    },
    data() {
        return {
            tovars: [],
            selected: 0,
            show: 0,
            pay_money: 0,
            pay_times: 0,
            providers: [],
            old_tovar: '',
        }
    },
    props:
        {
            path_axios_get_list_tovars: {
                type: String,
                default: ''
            },
            path_axios_get_list_providers_for_select: {
                type: String,
                default: ''
            },
            path_axios_save_tovar_from_page_list_tovar: {
                type: String,
                default: ''
            },
        },
    components: {
        'gallery': VueGallery
    },
    methods: {
        getTovars() {
            axios.get(this.path_axios_get_list_tovars).then(response => {
                response.data.forEach(function (item, i) {
                    item.id_provider ? item.provider = item.id_provider.id_backend_users.name : item.provider = null;
                    item.show = false;

                    item.img1 = item.img.split('\n').filter(function (el) {
                        return el != "";
                    });
                    item.index = null;
                
                });
                this.tovars = response.data;
                //console.log(JSON.stringify(response.data, null, 4));
            })
                .then(response => {
                    //this.plus_minus();
                    setTimeout(() => this.add_jquery(), 700);
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },

        getProviders() {
            axios.get(this.path_axios_get_list_providers_for_select).then(response => {
                //console.log(JSON.stringify(response.data, null, 4));
                this.providers = response.data;
                this.providers.push({
                    name: 'Снять поставщика',
                });
            })
            .then(response => {
                //this.plus_minus();
                //setTimeout(() => this.add_jquery(), 700);
                console.log(response);
            })
            .catch(function (error) {
                console.log(error.response);
            });
        },

        add_jquery() {

            $('#datatable-details').DataTable({
                paging: true,
                aoColumnDefs: [{
                    bSortable: false,
                    aTargets: [0,1,2,3,4]
                }],
            });
        },

        onFocus(tovar)
        {
            this.old_tovar = JSON.stringify(tovar);
            //console.log(this.zakazs[id].zakaz_date);
        },

        onBlur(tovar) {

            //console.log(JSON.stringify(tovar, null, 4));

            if (this.old_tovar != JSON.stringify(tovar))

            axios.post(this.path_axios_save_tovar_from_page_list_tovar, {
                data: tovar
            })
                .then(function (response) {
                    // handle success
                    //console.log(this.zakazs[id]);
                    //alert(this.zakazs[id]);
                    $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                        //alert(id);
                    });
                    console.log(response);
                })
                .catch(function (error) {
                    // handle error
                    $('#mes-edit1').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                        $('#mes-edit1').delay(1000).fadeOut();
                        //alert(id);
                    });
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        },
        plus_minus(zakaz, id) {
            if (zakaz.isPlus == true) {
                zakaz.isPlus = false;
                zakaz.isMinus = true;
            } else {
                zakaz.isPlus = true;
                zakaz.isMinus = false;
            }
        },
    },
}

</script>
