<template>
    <div>
        <template v-if="alert === 'yes'">
            <template v-if="show">
                <template v-for="zakaz in zakazs">
                    <table class="table table-bordered table-striped mb-none">
                        <tr>
                            <th class="courier">Параметр</th>
                            <th>Значение</th>
                        </tr>
                        <tr>
                            <td>ID заказа</td>
                            <td>{{zakaz.zakaz.id}}</td>
                        </tr>
                        <tr>
                            <td>Адрес клиента</td>
                            <td v-if="zakaz.zakaz.id_client.stroenie">г. {{zakaz.zakaz.id_client.city}}, ул.
                                {{zakaz.zakaz.id_client.street}},
                                д. {{zakaz.zakaz.id_client.house}},
                                строение. {{zakaz.zakaz.id_client.stroenie}}, кв. {{zakaz.zakaz.id_client.flat}}
                            </td>
                            <td v-else>г. {{zakaz.zakaz.id_client.city}}, ул. {{zakaz.zakaz.id_client.street}}, д.
                                {{zakaz.zakaz.id_client.house}}, кв.
                                {{zakaz.zakaz.id_client.flat}}
                            </td>
                        </tr>
                        <tr>
                            <td>ФИО клиента</td>
                            <td>{{zakaz.zakaz.id_client.name_1}}</td>
                        </tr>
                        <tr>
                            <td>Телефон клиента</td>
                            <td>{{zakaz.zakaz.id_client.tel}}</td>
                        </tr>
                        <tr>
                            <td>Состав заказа</td>
                            <td>
                                <ol type="1">
                                    <li v-for="stuff in zakaz.stuff_to_zakaz">
                                        {{stuff.id_tov.name}} - <b> {{stuff.kolvo}} шт</b>

                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr>
                            <td>Сумма к оплате</td>
                            <td>{{zakaz.zakaz.sum  * (100 - zakaz.zakaz.skidka) / 100}}</td>
                        </tr>
                        <tr>
                            <td>Комментарий к заказу</td>
                            <td>{{zakaz.zakaz.comments}}</td>
                        </tr>
                    </table>


                    <div class="panel-body">
                        <button class="mb-xs mt-xs mr-xs btn btn-primary" type="button" @click="addZakaz(zakaz.zakaz.id)">
                            Взять заказ
                        </button>
                    </div>
                </template>

            </template>
            <template v-else>
                <h6>Доступных заказов на данный момент нет.</h6>
            </template>
        </template>
        <template v-else>
            <h6>У Вас уже есть заказ на доставке, сначала завершите его.</h6>
        </template>
    </div>

</template>
<script>//

export default {
    created() {
        this.getCurrentZakaz();
    },
    data() {
        return {
            zakazs: [],
            show: 0,
            alert: '',
        }
    },
    props:
        {
            path_axios_get_courier_available_zakazs: {
                type: String,
                default: ''
            },
            path_axios_post_courier_add_zakaz: {
                type: String,
                default: ''
            },
        },
    methods: {
        getCurrentZakaz() {
            axios.get(this.path_axios_get_courier_available_zakazs).then(response => {
                this.alert = response.data['no'];
                //console.log(JSON.stringify(response.data, null, 4));
                delete response.data['no'];
                this.zakazs = response.data;
                //console.log(JSON.stringify(typeof(this.zakazs[0]), null, 4));
                if (typeof (this.zakazs[0]) != "undefined") this.show = 1;
            })
                .then(response => {
                    //console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
        addZakaz(id) {
            axios.post(this.path_axios_post_courier_add_zakaz, {
                data: id
            })
                .then(response => {
                    location.href = 'my_order';
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
    },
}

</script>
