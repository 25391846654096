<template>
    <div>
            <template v-if="show">
                <template v-for="zakaz in zakazs">
                    <table class="table table-bordered table-striped mb-none">
                        <tr>
                            <th class="courier">Параметр</th>
                            <th>Значение</th>
                        </tr>
                        <tr>
                            <td>ID заказа</td>
                            <td>{{zakaz.zakaz.id}}</td>
                        </tr>
                        <tr style = "color: red;">
                            <td>CDEK</td>
                            <td>{{zakaz.zakaz.id_delivery_type.name}}</td>
                        </tr>
                        <tr>
                            <td>Адрес клиента</td>
                            <td v-if="zakaz.zakaz.id_client.stroenie">г. {{zakaz.zakaz.id_client.city}}, ул.
                                {{zakaz.zakaz.id_client.street}},
                                д. {{zakaz.zakaz.id_client.house}},
                                строение. {{zakaz.zakaz.id_client.stroenie}}, кв. {{zakaz.zakaz.id_client.flat}}
                            </td>
                            <td v-else>г. {{zakaz.zakaz.id_client.city}}, ул. {{zakaz.zakaz.id_client.street}}, д.
                                {{zakaz.zakaz.id_client.house}}, кв.
                                {{zakaz.zakaz.id_client.flat}}
                            </td>
                        </tr>
                        <tr>
                            <td>ФИО клиента</td>
                            <td>{{zakaz.zakaz.id_client.name_1}}</td>
                        </tr>
                        <tr>
                            <td>Телефон клиента</td>
                            <td>{{zakaz.zakaz.id_client.tel}}</td>
                        </tr>
                        <tr>
                            <td>Состав заказа</td>
                            <td>
                                <ol type="1">
                                    <li v-for="stuff in zakaz.stuff_to_zakaz">
                                        {{stuff.id_tov.name}} - <b> {{stuff.kolvo}} шт</b>

                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr>
                            <td>Почтовые расходы</td>
                            <td>{{zakaz.zakaz.pochta_costs}}</td>
                        </tr>

                        <tr>
                            <td>Комментарий к заказу</td>
                            <td>{{zakaz.zakaz.comments}}</td>
                        </tr>
                    </table>


                    <div class="panel-body">
                        <template v-if="zakaz.zakaz.id_status.id == 4">
                        <button class="mb-xs mt-xs mr-xs btn btn-primary" type="button" @click="addZakaz(zakaz.zakaz.id)">
                            Завершить заказ
                        </button>
                        </template>
                        <template v-else>
                            <button disabled class="mb-xs mt-xs mr-xs btn btn-primary" type="button" @click="addZakaz(zakaz.zakaz.id)">
                                Завершить заказ
                            </button>
                        </template>
                    </div>
                </template>
                <div id="mes-edit"></div>
            </template>
            <template v-else>
                <h6>Доступных заказов на данный момент нет.</h6>
            </template>

    </div>

</template>
<script>//

export default {
    created() {
        this.getCurrentZakaz();
    },
    data() {
        return {
            zakazs: [],
            show: 0,
        }
    },
    props:
        {
            path_axios_get_cdek_zakazs: {
                type: String,
                default: ''
            },
            path_axios_post_cdek_done_zakaz: {
                type: String,
                default: ''
            },
        },
    methods: {
        getCurrentZakaz() {
            axios.get(this.path_axios_get_cdek_zakazs).then(response => {
                this.zakazs = response.data;
                if (typeof (this.zakazs[0]) != "undefined") this.show = 1;
            })
                .then(response => {
                    //console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
        addZakaz(id) {
            let track_number = prompt('Введите трек-номер CDEK');
            if (track_number != null) {
                
                let send = {
                    track_number: track_number,
                    id: id,
                };

                axios.post(this.path_axios_post_cdek_done_zakaz, {
                    data: send
                })
                    .then(response => {
                        location.reload();
                        console.log(response);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {

                $('#mes-edit').text('Ошибка! Укажите трек-номер!').delay(500).fadeIn(1000, function () {
                    $('#mes-edit').delay(1000).fadeOut();
                });
            }
        },
    },
}

</script>
