<template>
    <div>

        <br>
        <br>

        <table class="table table-bordered mb-none" id="datatable-details">
            <thead>
            <tr>
                <th>Дата</th>
                <th>Сумма</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="pay in pays">
                    <td>{{pay.date}}</td>
                    <td>{{pay.sum}}</td>
                </tr>
            <tr>
                <td><b>Всего</b></td>
                <td><b>{{all_pay}}</b></td>
            </tr>
            </tbody>
        </table>

        <p>&nbsp;</p>
        <div class="form-group">
            <label class="col-md-3 control-label">Выбрать промежуток</label>
            <div class="col-md-8">
                <div class="input-daterange col-md-8 col-md-offset-1 input-group" data-plugin-datepicker>
														<span class="input-group-addon">
															<i class="fa fa-calendar"></i>
														</span>
                    <input class="form-control" name="start" id="start" type="text" v-model="start_date">
                    <span class="input-group-addon">до</span>
                    <input class="form-control" name="end" type="text" v-model="end_date">
                </div>
            </div>
        </div>
        <div class="col-md-5"></div>
        <button class="mb-xs mt-xs mr-xs btn btn-primary col-md-2 col-md-offset-5" @click="makeMagic2()"
                type="button">Показать за выбранный промежуток
        </button>

        <div id="loader"><span></span></div>
        <div id="mes-edit"></div>

    </div>
</template>
<script>//

export default {
    created() {
        var date = new Date();
        date.setMonth(date.getMonth() + 1);
        this.start_date = date.getFullYear() + '-' + ('0' + (date.getMonth())).slice(-2) + '-' + '01';
        this.end_date = date.getFullYear() + '-' + ('0' + (date.getMonth())).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        localStorage.setItem('date0', this.start_date);
        localStorage.setItem('date1', this.end_date);
        this.makeMagic2();
    },
    data() {
        return {
            pays: [],
            start_date: '',
            end_date: '',
            all_pay: 0,
        }
    },
    props:
        {
            path_axios_get_provider_self_pays: {
                type: String,
                default: ''
            },
        },
    methods: {
        makeMagic2() {
            let mass = {};
            let self = this;
            mass['date0'] = localStorage.getItem('date0');
            mass['date1'] = localStorage.getItem('date1');

            var day_start = new Date(localStorage.getItem('date0'));
            var day_end = new Date(localStorage.getItem('date1'));

            this.start_date = day_start.getFullYear() + '-' + ('0' + (day_start.getMonth()+1)).slice(-2) + '-' + ('0' + (day_start.getDate())).slice(-2);
            this.end_date = day_end.getFullYear() + '-' + ('0' + (day_end.getMonth()+1)).slice(-2) + '-' + ('0' + (day_end.getDate())).slice(-2);

            axios.post(this.path_axios_get_provider_self_pays, {
                data: mass
            })
                .then(response => {
                    //console.log(JSON.stringify(response.data, null, 4));
                    self.all_pay = 0;
                    self.pays = response.data;
                    self.pays.forEach(function (item, i) {
                        self.all_pay += item.sum;
                    }, self);
                    //console.log(response);
                })
                .then(response => {
                    //this.add_jquery();
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
    },
}

</script>
