<template>
    <div>
            <textarea @blur="onBlur(text)" @focus="onFocus(text)" class="form-control1" name="comment" rows="5" style = "width:100%; font-size: 20px; text-align: center; " v-model="text"> </textarea>
        <div id="mes-edit"></div>
    </div>
</template>
<script>//

export default {
    created() {
        this.getHello();
    },
    data() {
        return {
            text: '',
            old_text: '',
        }
    },
    props:
        {
            path_axios_get_hello: {
                type: String,
                default: ''
            },
            path_axios_save_hello: {
                type: String,
                default: ''
            },
            role: {
                type: String,
                default: ''
            },
        },
    methods: {

        getHello() {
            axios.post(this.path_axios_get_hello, {
                data: this.role
            })
                .then(response => {
                    this.text = response.data;
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },

        onFocus(text)
        {
            this.old_text = text;
        },

        //записываем изменения в базу
        onBlur(text) {

            var send = {
                text: text,
                role: this.role,
            };

            if (this.old_text != text)

            axios.post(this.path_axios_save_hello, {
                data: send
            })
                .then(function (response) {
                    // handle success
                    $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                        //alert(id);
                    });
                    console.log(response);
                })
                .catch(function (error) {
                    // handle error
                    $('#mes-edit1').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                        $('#mes-edit1').delay(1000).fadeOut();
                        //alert(id);
                    });
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        },
    },
}

</script>
