<template>
<span>
       {{price}}
</span>
</template>
<script>//

/*import Echo from "laravel-echo"
window.io = require('socket.io-client');

let echo = new Echo({
    broadcaster: 'socket.io',
    host: 'http://localhost:6001'
});*/

export default {
    created() {
        this.price = this.qwe;
/*           echo
                   .channel('chat.' + this.user_id)
                   .listen('ChatMessage', (e) => {
                          this.price = e.comment.count_messages;
                          this.showModal = true;
                          //alert(JSON.stringify(e.comment.taskId, null, 4));
                   });*/
    },
    data() {
        return {
            price: 0,
            showModal: false
        }
    },
       mounted()
       {

       },
    props:
    {
        qwe: {
            type: String,
            default: ''
        },
           user_id: {
                  type: String,
                  default: ''
           }
    },
    methods: {
        //получает список всех доступных продуктов для данной категории (блюдо или набор) Вызывается при создании компонента.

        //добавление нового продукта на страницу по нажатию кнопки "добавить продукт".
        addProduct() {
            this.nextId = this.newproduct.length;
            this.newproduct.push({id_on_page: this.nextId++, id_in_db: '0', kolvo: '1', price: '0', description: ''});
        },
    },
}

</script>
