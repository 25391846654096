<template>
<span>
       <label>Для начала синхронизации нажмите:
       <br>
       <button @click="syncMail()" class="btn btn-primary">Старт</button></label>
</span>
</template>
<script>//

export default {
    created() {

    },
    props:
    {
        path_axios_start_sync: {
                  type: String,
                  default: ''
           },
    },
    methods: {

        syncMail()
        {
               axios.get(this.path_axios_start_sync, {
               })
                       .then(function (response) {
                            $('#mes-edit').text('Данные обновлены.').delay(500).fadeIn(1000, function () {
                                   $('#mes-edit').delay(1000).fadeOut();
                            });
                            console.log(response);
                       })
                       .catch(function (error) {
                            $('#mes-edit').text('Ошибка! Данные не удалось обновить/').delay(500).fadeIn(1000, function () {
                                   $('#mes-edit').delay(1000).fadeOut();
                            });
                              console.log(error);
                       })
        },
    },
}

</script>
