<template>
    <div>
        <table class="table table-bordered table-striped mb-none" id="datatable-details">
            <thead>
            <tr>
                <th></th>
                <th style="pointer-events: none; cursor: default;">ID</th>
                <th>Статус</th>
                <th>Кто заказал</th>
                <th>Скидка, %</th>
                <th>Дата</th>
                <th>Стоим. посылки</th>
                <th>Почтовые расходы</th>
                <th>Кол-во товара</th>
                <th>Служба доставки</th>
                <th>Трек-номер</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="zakaz in all_zakazs">
                <template v-if="zakaz.show">
                    <tr class="gradeX">
                        <td class="text-center" v-on:click="plus_minus(zakaz, zakaz.id_in_vue), zakaz.show = !zakaz.show"><i class="fa text-primary h5 m-none"
                                                                                                                             data-toggle style="cursor: pointer;" v-bind:class="{ 'fa-plus-square-o': zakaz.isPlus, 'fa-minus-square-o': zakaz.isMinus}"></i>
                        </td>
                        <td>{{zakaz.id}}</td>
                        <td>
                            <select @change="onBlur(zakaz.id_in_vue)" class="form-control1" v-model="zakaz.id_status">
                                <option :value="select.id" v-for="select in selects_status_name">{{select.status}}</option>
                            </select>
                        </td>
                        <td>{{zakaz.id_backend_users.name}}</td>
                        <td><input class="form-control1" @focus="onFocus(zakaz.id_in_vue)" @blur="onBlur(zakaz.id_in_vue)" v-model="zakaz.skidka"></td>
                        <td>{{zakaz.created_at}}</td>
                        <td>{{Number(zakaz.sum * (100 - zakaz.skidka) / 100) + Number(zakaz.pochta_costs)}}</td>
                        <td><input class="form-control1" @focus="onFocus(zakaz.id_in_vue)" @blur="onBlur(zakaz.id_in_vue)" v-model="zakaz.pochta_costs"></td>
                        <td><input @focus="onFocus(zakaz.id_in_vue)" @blur="onBlur(zakaz.id_in_vue)" v-model="zakaz.kolvo"></td>
                        <td><input class="form-control1" @focus="onFocus(zakaz.id_in_vue)" @blur="onBlur(zakaz.id_in_vue)" v-model="zakaz.delivery"></td>
                        <td><input class="form-control1" @focus="onFocus(zakaz.id_in_vue)" @blur="onBlur(zakaz.id_in_vue)" v-model="zakaz.track_number"></td>
                    </tr>
                    <tr class="details">
                        <td class='details' colspan="14">
                            <table class="table mb-none">
                                <tbody>


                                <tr>
                                    <td>Состав заказа &darr;</td>
                                    <td></td>
                                    <td>Данные клиента &darr;</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td width="40%">
                                        <ol type="1">
                                            <li v-for="stuff in zakaz.stuff">

                                                <br>
                                                <br>

                                                <table class="table-bordered">
                                                    <tr>
                                                        <td colspan="4">
                                                            <span class="show-grid-block" style="padding-left: 5px;">Цена: <b>{{stuff.id_tov.price || 0}}</b> руб., Стоимость: <b>{{stuff.id_tov.price * stuff.kolvo || 0}}</b> руб.</span>
                                                            <div class="input-group btn-group"
                                                                 style="width: 386px; top: 6px; margin-left: 5px; margin-right: 5px;">
                                                                <span class="input-group-addon">
														            <i class="fa fa-shopping-cart"></i>
													            </span>
                                                                <v-select :options="products" @input="connect(stuff, zakaz)" class="form-control populate"
                                                                          label="name"
                                                                          placeholder="Введите как минимум 2 символа для поиска" style=""
                                                                          v-model="stuff.id_tov.name">
                                                                </v-select>
                                                            </div>
                                                            <br>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-left: 5px;">Количество</td>
                                                        <td style="width: 20px;"></td>
                                                        <td style="padding-left: 5px;">Фото</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-left: 5px;">
                                                            <div class="input-group form-control1" style="width:150px;">
                                                                <input :value="stuff.kolvo"
                                                                       maxlength="3" readonly style="float: left;" type="text">
                                                                <div class="spinner-buttons input-group-btn" style="top: -7px; position: relative; left: 15px;">
                                                                    <button @click="plus(zakaz, stuff), onBlur(zakaz.id_in_vue)"
                                                                            class="btn btn-default spinner-up"
                                                                            style="border: 1px solid #af7171; line-height: 1.31;"
                                                                            type="button">
                                                                        <i class="fa fa-angle-up"></i>
                                                                    </button>
                                                                    <button @click="minus(zakaz, stuff), onBlur(zakaz.id_in_vue)"
                                                                            class="btn btn-default spinner-down"
                                                                            style="border: 1px solid #af7171; line-height: 1.31;"
                                                                            type="button">
                                                                        <i class="fa fa-angle-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td></td>
                                                        <td rowspan="3">
                                                            <gallery :images="stuff.img" :index="stuff.index" @close="stuff.index = null"></gallery>
                                                            <div
                                                                :key="imageIndex"
                                                                :style="{ backgroundImage: 'url(' + image + ')', width: '100px', height: '100px' }"
                                                                @click="stuff.index = imageIndex"
                                                                class="image"
                                                                v-for="(image, imageIndex) in stuff.img"
                                                            ></div>
                                                        </td>
                                                        <td>

                                                            <div class="col-md-2"><span class="show-grid-block"><br><button
                                                                @click="delProduct(zakaz, stuff.id_in_vue), onBlur(zakaz.id_in_vue)"
                                                                class="mb-xs mt-xs mr-xs btn btn-xs btn-primary"
                                                                type="button">Удалить товар</button></span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-left: 5px;">Размер</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-left: 5px; padding-bottom: 5px; padding-top: 6px;">
                                                            <input @blur="onBlur(zakaz.id_in_vue)"
                                                                   @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                                   style="width: 150px;"
                                                                   v-model="stuff.size">
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </table>
                                                <br>
                                            </li>
                                        </ol>
                                        <div class="panel-body">
                                            <div class="col-md-6">
                                                <button @click="addProduct(zakaz)" class="mb-xs mt-xs mr-xs btn btn-xs btn-primary" type="button">Добавить ещё
                                                    товар
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <template v-if="zakaz.id_client.foreign_adr == null">

                                        <td>

                                            <table class="table-striped mb-none">
                                                <tr>
                                                    <td>ФИО:</td>
                                                    <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                    <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                               @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                               v-model="zakaz.id_client.name_1">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Телефон:</td>
                                                    <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                    <td>
                                                        <input @blur="onBlur(zakaz.id_in_vue)"
                                                               @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                               v-model="zakaz.id_client.tel">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Город:</td>
                                                    <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                    <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                               @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                               v-model="zakaz.id_client.city"></td>
                                                </tr>
                                                <tr>
                                                    <td>Улица:</td>
                                                    <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                    <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                               @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                               v-model="zakaz.id_client.street">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Дом:</td>
                                                    <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                    <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                               @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                               v-model="zakaz.id_client.house"></td>
                                                </tr>
                                                <tr>
                                                    <td>Строение:</td>
                                                    <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                    <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                               @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                               v-model="zakaz.id_client.stroenie">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Квартира:</td>
                                                    <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                    <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                               @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                               v-model="zakaz.id_client.flat"></td>
                                                </tr>
                                                <tr>
                                                    <td>Индекс:</td>
                                                    <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                    <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                               @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                               v-model="zakaz.id_client.index_adr">
                                                    </td>
                                                </tr>
                                            </table>

                                        </td>
                                    </template>

                                    <template v-else>
                                        <table class="table-striped mb-none">
                                            <tr>
                                                <td>ФИО:</td>
                                                <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                <td><input @blur="onBlur(zakaz.id_in_vue)"
                                                           @focus="onFocus(zakaz.id_in_vue)" class="form-control1"
                                                           v-model="zakaz.id_client.name_1">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Иностранный заказ:</td>
                                                <td>&nbsp&nbsp&nbsp&nbsp&nbsp</td>
                                                <td>
                                                    <input @blur="onBlur(zakaz.id_in_vue)"
                                                           @focus="onFocus(zakaz.id_in_vue)" class="form-control1" style="height: 100px"
                                                           v-model="zakaz.id_client.foreign_adr">
                                                </td>
                                            </tr>
                                        </table>
                                    </template>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Всего &rarr; <b>{{zakaz.kolvo}} шт.</b></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Комментарий к заказу &darr;</td>

                                </tr>

                                <tr>
                                    <td>
                                        <div class="panel-body">
                                            <div class="col-md-3">
                    <span class="show-grid-block">Комментарий к заказу
                        <textarea @blur="onBlur(zakaz.id_in_vue)" @focus="onFocus(zakaz.id_in_vue)" cols="45"
                                  name="text" rows="10" v-model="zakaz.comments"></textarea>
                            </span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>


                                </tbody>
                            </table>
                        </td>
                    </tr>
                </template>

                <template v-else>
                    <tr class="gradeX">
                        <td class="text-center" v-on:click="plus_minus(zakaz, zakaz.id_in_vue), zakaz.show = !zakaz.show"><i class="fa text-primary h5 m-none"
                                data-toggle style="cursor: pointer;" v-bind:class="{ 'fa-plus-square-o': zakaz.isPlus, 'fa-minus-square-o': zakaz.isMinus}"></i>
                        </td>
                        <td>{{zakaz.id}}
                            <span style="display: none;">
                                    {{zakaz.id_client.name_1}}
                                    {{zakaz.track_number}}
                                    {{zakaz.delivery}}
                                        <li v-for="stuff in zakaz.stuff">
                                            {{stuff.name}}
                                        </li>
                                </span>
                        </td>
                        <td>
                            <select @change="onBlur(zakaz.id_in_vue)" class="form-control1" v-model="zakaz.id_status">
                                <option :value="select.id" v-for="select in selects_status_name">{{select.status}}</option>
                            </select>
                        </td>
                        <td>{{zakaz.id_backend_users.name}}</td>
                        <td><input class="form-control1" @focus="onFocus(zakaz.id_in_vue)" @blur="onBlur(zakaz.id_in_vue)" v-model="zakaz.skidka"></td>
                        <td>{{zakaz.created_at}}</td>
                        <td>{{Number(zakaz.sum * (100 - zakaz.skidka) / 100) + Number(zakaz.pochta_costs)}}</td>
                        <td><input class="form-control1" @focus="onFocus(zakaz.id_in_vue)" @blur="onBlur(zakaz.id_in_vue)" v-model="zakaz.pochta_costs"></td>
                        <td><input @focus="onFocus(zakaz.id_in_vue)" @blur="onBlur(zakaz.id_in_vue)" v-model="zakaz.kolvo"></td>
                        <td><input class="form-control1" @focus="onFocus(zakaz.id_in_vue)" @blur="onBlur(zakaz.id_in_vue)" v-model="zakaz.delivery"></td>
                        <td><input class="form-control1" @focus="onFocus(zakaz.id_in_vue)" @blur="onBlur(zakaz.id_in_vue)" v-model="zakaz.track_number"></td>
                    </tr>
                </template>
            </template>
            </tbody>
        </table>

        <div class="form-group">
            <label class="col-md-3 control-label">Выбрать промежуток</label>
            <div class="col-md-8">
                <div class="input-daterange col-md-8 col-md-offset-1 input-group" data-plugin-datepicker>
														<span class="input-group-addon">
															<i class="fa fa-calendar"></i>
														</span>
                    <input class="form-control" name="start" id="start" type="text" v-model="start_date">
                    <span class="input-group-addon">до</span>
                    <input class="form-control" name="end" type="text" v-model="end_date">
                </div>
            </div>
        </div>
        <div class="col-md-5"></div>
        <button class="mb-xs mt-xs mr-xs btn btn-primary" @click="makeMagic1()" type="button">Показать</button>

        <div id="loader"><span></span></div>
        <div id="mes-edit"></div>

    </div>
</template>
<script>//

import VueGallery from 'vue-gallery'

Vue.component('vue-gallery', VueGallery);

export default {
    created() {
        var date = new Date();
        date.setMonth(date.getMonth() - 1);
        this.start_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        date.setMonth(date.getMonth() + 1);
        this.end_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        localStorage.setItem('date0', this.start_date);
        localStorage.setItem('date1', this.end_date);
        this.makeMagic2();
    },
    components: {
        'gallery': VueGallery
    },
    data() {
        return {
            all_zakazs: [],
            selects_status_name: [],
            old_zakaz: '',
            start_date: '',
            end_date: '',
        }
    },
    store: ['range'],
    props:
        {
            path_axios_get_wholesale_list_zakaz: {
                type: String,
                default: ''
            },
            path_axios_save_updated_wholesale_zakaz_from_list: {
                type: String,
                default: ''
            },
        },
    methods: {
        makeMagic1() {
            $('#datatable-details').DataTable().destroy();
            this.makeMagic2();
        },
        makeMagic2() {

            let mass = {};
            mass['date0'] = localStorage.getItem('date0');
            mass['date1'] = localStorage.getItem('date1');

            var day_start = new Date(localStorage.getItem('date0'));
            var day_end = new Date(localStorage.getItem('date1'));

            this.start_date = day_start.getFullYear() + '-' + ('0' + (day_start.getMonth() + 1)).slice(-2) + '-' + ('0' + (day_start.getDate())).slice(-2);
            this.end_date = day_end.getFullYear() + '-' + ('0' + (day_end.getMonth() + 1)).slice(-2) + '-' + ('0' + day_end.getDate()).slice(-2);

            axios.post(this.path_axios_get_wholesale_list_zakaz, {
                data: mass
            })
                .then(response => {
                    this.selects_status_name = response.data['status'];
                    this.products = response.data['products'];
                    delete response.data['status'];
                    delete response.data['products'];

                    for (let key in response.data) {
                        response.data[key]['isPlus'] = true;
                        response.data[key]['isMinus'] = false;
                        response.data[key]['id_in_vue'] = key;
                    }
                    this.all_zakazs = response.data;
                    console.log(JSON.stringify(response.data, null, 4));
                })
                .then(response => {
                    //$('#datatable-details').DataTable().destroy();
                    this.add_jquery();
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },

        plus: function (zakaz, tovar) {
            if (tovar.id_tov.price) {
                zakaz.kolvo++;
                tovar.kolvo++;
            }
            var sum = 0;
            zakaz.stuff.forEach(function (tovar, index) {
                if (tovar.id_tov.price) sum += tovar.id_tov.price * tovar.kolvo * (100 - zakaz.skidka) / 100;
            }, this);
            zakaz.sum = sum;
        },

        minus(zakaz, tovar) {
            if (tovar.kolvo > 1 && tovar.id_tov.price) {
                tovar.kolvo--;
                zakaz.kolvo--;
            }
            var sum = 0;
            zakaz.stuff.forEach(function (tovar, index) {
                if (tovar.id_tov.price) sum += tovar.id_tov.price * tovar.kolvo * (100 - zakaz.skidka) / 100;
            }, this);
            zakaz.sum = sum;
        },
        //добавление нового продукта на страницу по нажатию кнопки "добавить продукт".
        addProduct(zakaz) {
            zakaz.stuff.push({id_tov: '1', img: ['images/crm/noimage.jpg'], index: null, id_in_vue: zakaz.stuff.length, kolvo: '1', name: '', size: null, price: ''});
        },
        //удаление продукта по нажатию на кнопку "удалить продукт".
        delProduct(zakaz, id) {
            zakaz.stuff.splice(id, 1);
            zakaz.stuff.forEach(function (tovar, index) {
                tovar.id_in_vue = index;
            });
            var sum = 0;
            var kolvo = 0;
            zakaz.stuff.forEach(function (tovar, index) {
                sum += tovar.price * tovar.kolvo * (100 - zakaz.skidka) / 100;
                kolvo += tovar.kolvo;
            }, this);
            zakaz.sum = sum;
            zakaz.kolvo = kolvo;
        },

        onFocus(id) {
            this.old_zakaz = JSON.stringify(this.all_zakazs[id]);
        },

        //записываем изменения в базу
        onBlur(id) {
            if (this.old_zakaz != JSON.stringify(this.all_zakazs[id])) {
                if (this.all_zakazs[id].id_status == '16') this.all_zakazs[id].back = 1;
                axios.post(this.path_axios_save_updated_wholesale_zakaz_from_list, {
                    data: this.all_zakazs[id]
                })
                    .then(function (response) {
                        // handle success
                        $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                            $('#mes-edit').delay(1000).fadeOut();
                            //alert(id);
                        });
                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        $('#mes-edit1').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                            $('#mes-edit1').delay(1000).fadeOut();
                            //alert(id);
                        });
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
            }
        },

        add_jquery() {
            $('#datatable-details').DataTable({
                paging: true,
                bFilter: true,
                aoColumnDefs: [{
                    bSortable: false,
                    aTargets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                }],
                /*aaSorting: [
                    [1, 'desc']
                ],*/
                "bAutoWidth": false,
                "bPaginate": true,
                "bInfo": true,
            });
        },

        plus_minus(zakaz, id) {
            if (zakaz.isPlus == true) {
                zakaz.isPlus = false;
                zakaz.isMinus = true;
            } else {
                zakaz.isPlus = true;
                zakaz.isMinus = false;
            }
        },

        setDate(id) {
            $("#dateInput" + id).datepicker({}).on("changeDate", function (e) {
                var event = new Event('input');
                this.dispatchEvent(event);
            });
            this.onBlur(id);

        }
    },
}

</script>
