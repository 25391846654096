<template>
    <div>
        <div class="row show-grid">
            <template v-if="newproduct.length > 0">
                <div class="panel-body" v-for="newprod in newproduct">
                    <div class="col-md-5">
                        <span class="show-grid-block">Выбрать товар <span class="required">*</span>
                            <div class="input-group btn-group">
                                <span class="input-group-addon">
                                    <i class="fa fa-shopping-cart"></i>
                                </span>


                                <v-select :options="products" @input="plusp(newprod.id_on_page)" class="form-control populate" label="name"
                                    placeholder="Введите как минимум 2 символа для поиска"
                                    v-model="newprod.selected">
                                </v-select>

                            </div>
                        </span>
                    </div>
                    <div class="col-md-2">
                        <div class="layout-content-page-menu-block3-goods-quantity-text1"></div>
                            <span class="show-grid-block">Количество<div>
                                <div class="input-group" style="width:150px;">
                                    <input :value="newprod.kolvo" class="spinner-input form-control" maxlength="3" readonly type="text">
                                    <div class="spinner-buttons input-group-btn">
                                        <button @click="plus(newprod.id_on_page), all_kolvo()" class="btn btn-default spinner-up" type="button">
                                            <i class="fa fa-angle-up"></i>
                                        </button>

                                        <button @click="minus(newprod.id_on_page), all_kolvo()" class="btn btn-default spinner-down" type="button">
                                            <i class="fa fa-angle-down"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div class="col-md-2">
                        <span class="show-grid-block">Цена, шт.
                            <input class="form-control" disabled v-model="newprod.price +' руб'"/>
                        </span>
                    </div>                
                    <div class="col-md-2">
                        <span class="show-grid-block" style="padding-left: 5px;">Размер
                            <input class="form-control1" id="form-control-size" v-model="newprod.size" placeholder="напр.:52">
                        </span>
                    </div>
                    <div class="col-md-1">
                        <button @click="delProduct(newprod.id_on_page)" type="button" class="mb-xs mt-xs mr-xs btn btn-primary btn-del">
                            <i class="fa fa-trash-o"></i>
                        </button>
                    </div>
                </div>
            </template>



            <div class="panel-body">
                <div class="col-md-12">
                    <div class="btn-space btn-margin">
                        <button @click="addProduct" class="btn btn-primary" type="button">Добавить ещё товар</button>
                    </div>
                </div>
            </div>



            <div class="panel-body">
                <div class="panel-body">
                    <div class="col-md-6">
                        <span class="show-grid-block">Ф.И.О.
                            <span class="required">*</span>
                            <input class="form-control" name="fullname" placeholder="напр.: Иванов Пётр Сидорович" required
                                type="text" v-model="client.name_1"/>
                        </span>
                    </div>
                    <div class="col-md-6">
                        <template v-if="foreign">
                            <span class="show-grid-block">Телефон
                                <span class="required">&nbsp</span>
                                <input v-model="client.tel" class="form-control" placeholder="+7 (123) 123-12-34">
                            </span>
                        </template>
                        <template v-else>
                            <span class="show-grid-block">Телефон 
                                <span class="required">*</span>
                                <input v-model="client.tel" class="form-control" placeholder="+7 (123) 123-12-34">
                            </span>
                        </template>
                    </div>
                </div>

                <div class="panel-body">
                    <div class="col-md-6">
                        <span class="show-grid-block">Населённый пункт
                            <input class="form-control" name="fullname" placeholder="напр.: Москва" type="text"
                                v-model="client.city"/>
                        </span>
                    </div>
                    <div class="col-md-6">
                        <span class="show-grid-block">Улица
                            <input class="form-control" name="fullname" placeholder="напр.: Кутузовский проспект" type="text"
                                v-model="client.street"/>
                        </span>
                    </div>
                </div>

                <div class="panel-body">
                    <div class="col-md-6">
                        <span class="show-grid-block">Иностранный заказ
                            <textarea class="form-control" style="border-style: solid;" cols="45" name="text" rows="10"
                                v-model="foreign" placeholder="напр.:30 Rose Ln, New Hyde Park, NY 11040"></textarea>
                        </span>
                    </div>
                </div>
            </div>


            <div class="panel-body">
                <div class="col-md-3">
                    <span class="show-grid-block">Дом
                        <input class="form-control" name="fullname" placeholder="напр.: 10" type="text"
                               v-model="client.house"/>
                            </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">Строение
                        <input class="form-control" name="fullname" placeholder="напр.: 4" type="text"
                               v-model="client.stroenie"/>
                            </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">Квартира
                        <input class="form-control" name="fullname" placeholder="напр.: 25" type="text"
                               v-model="client.flat"/>
                            </span>
                </div>
                <div class="col-md-3">
                    <span class="show-grid-block">Индекс
                        <input class="form-control" name="fullname" placeholder="напр.: 600021" type="text"
                               v-model="client.index_adr"/>
                            </span>
                </div>
            </div>

            <div class="panel-body">
                <div class="col-md-12">
                    <div class="btn-space btn-margin">
                        <button @click="addToBase" class="btn btn-success" id="show-modal">Добавить заказ в базу</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="mes-edit2"></div>
    </div>
</template>

<script>
export default {
    created() {
        this.getProducts()
    },
    data() {
        return {
            products: [],
            newproduct: [],
            id_tov: '',
            nextId: 0,
            showModal: false,
            price: 0,
            foreign: '',
            foreign1: '',

            client: {
                name_1: '',
                name_2: '',
                name_3: '',
                tel: '',
                city: '',
                street: '',
                house: '',
                stroenie: '',
                flat: '',
                index_adr: '',
                black_list: '0',
                comments: '',
                foreign_adr: '',
                zakaz: {
                    id: '',
                    sum: 0,
                    id_status: 1,
                    id_client: 1,
                    kolvo: 0,
                },
                newproduct: [],
            },
        }
    },
    props: {
        path_axios_get_list_of_products_to_add: {
            type: String,
            default: ''
        },
        path_axios_get_list_of_products_to_update: {
            type: String,
            default: ''
        },
        path_axios_store_to_db_zakaz: {
            type: String,
            default: ''
        },
        path_open_new_page: {
            type: String,
            default: ''
        },
    },
    watch: {
        foreign:function(val) {
            this.client.foreign_adr = val;
            if (val != '') this.foreign1 = true; else this.foreign1 = false;
        },
    },
    methods: {
        getProducts() {
            axios.get(this.path_axios_get_list_of_products_to_add).then(response => {
                this.products = response.data;
                //console.log(JSON.stringify(this.products, null, 4));
                this.addProduct();
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
        getProductsForThis: function () {
            axios.get(this.path_axios_get_list_of_products_to_update, {
                params: {
                    id: this.$store[this.store_name].id
                }
            }).then(response => {
                var tempId = 0;
                response.data.forEach(function (newprod) {
                    this.push({
                        id: tempId++,
                        id_tov: newprod.id,
                        kolvo: 4
                        
                    });
                }, this.newproduct);
                this.nextId = tempId;  //в заивисмости от кол-ва уже добавленных продуктов выбирается следующий id для добавления нового продукта
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
        //добавление нового продукта на страницу по нажатию кнопки "добавить продукт".
        addProduct() {
            this.nextId = this.newproduct.length;
            this.newproduct.push({id_on_page: this.nextId++, id_tov: '0', kolvo: '1', price: '0', size: ''});
            this.all_kolvo();
        },
        //удаление продукта по нажатию на кнопку "удалить продукт". В качестве параметра id продукта на странице.
        delProduct: function (ID) {
            //Удаляет из массива
            this.newproduct.splice(ID, 1);
            //теперь нужно заново присвоить индексы обновлённому массиву продуктов
            this.newproduct.forEach(function (newprod, index) {
                newprod.id_on_page = index;
            });
        },
        plus(thisBludo) {
            this.newproduct[thisBludo].kolvo++;
        },

        plusp: function (thisBludo) {
            if (typeof (this.newproduct[thisBludo].selected) !== 'undefined') {
                let id = -1;
                for (let key in this.products) {
                    if (this.products[key].id == this.newproduct[thisBludo].selected.id) {
                        id = key; break;
                    }
                }
                if (id >= 0) {
                    this.newproduct[thisBludo].price = this.products[id].price;
                    this.newproduct[thisBludo].id_tov = this.products[id].id;
                }
            }
        },

        minus(thisBludo) {
            if (this.newproduct[thisBludo].kolvo > 0)
                this.newproduct[thisBludo].kolvo--;
        },
        addToBase: function () {

            this.newproduct.forEach(function (newprod) {
                this.sum += newprod.kolvo * newprod.price;
            }, this.client.zakaz);

            var proceed = 1;
            if (this.newproduct[0].id_tov == 0) proceed = 0;
            if (this.client.name_1 == '') proceed = 0;
            if ((this.client.tel == '') &&  (this.client.foreign_adr == '')) proceed = 0;

            if (proceed == 1) {

                this.client.newproduct = this.newproduct;
                axios.post(this.path_axios_store_to_db_zakaz, {
                    data: this.client
                }).then(response => {
                location.href = this.path_open_new_page;
                })
                    .then(function (response) {
                        console.log(response);
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            }
            else
                {
                    $('#mes-edit2').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                        $('#mes-edit2').delay(1000).fadeOut();
                        //alert(id);
                    });
                }

        },
        all_kolvo() {
            this.client.zakaz.kolvo = 0;
            this.newproduct.forEach(function (newprod) {
                this.kolvo += +newprod.kolvo;
            }, this.client.zakaz);

        },
    },
}
</script>

<style>
.panel-body {
    box-shadow: none !important;
}
.btn-margin button {
    margin: 0 5%;
}
.btn-space button{
    width: 15%;
    float: left;
}
#form-control-size {
    width: 150px;
}

.btn-del {
    position: relative;
    top: 15px;
}

@media only screen and (max-width: 767px) {
body {
    overflow-y: auto;
}
.content-body {
    padding: 0;
}
.page-header,
.col-md-12 {
    padding: 0;
    margin:0;
}
.btn-space button{
    width: 40%;
    float: left;
    margin-top: 5%;
}
.row {
    margin-left: 0;
    margin-right: 0;
}
#form-control-size {
    width: 100%;
}
.panel-body {
    padding: 5px 10px 5px 10px;
}
.panel-body .panel-body {
    padding: 5px 0 5px 0;
}
.input-group .form-control {
    position: initial;
}
}
</style>