<template>
    <div>
        <table class="table table-bordered mb-none" id="datatable-details">
            <thead>
            <tr>
                <th style="pointer-events: none; cursor: default;">ID</th>
                <th>ФИО</th>
		<th>Дата</th>
                <th>Сумма к выплате</th>
                <th>Яндекс</th>
                <th>Киви</th>
                <th>PayPal</th>
                <th>Карта</th>
                <th>UTM</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <template v-for="partner_pay in partners_pays">
                    <tr v-bind:class="{'paid': partner_pay.paid == 1}">
                        <td>{{partner_pay.id}}</td>
                        <td>{{partner_pay.id_partner.p_name}}</td>
                        <td>{{partner_pay.created_at}}</td>
                        <td>{{partner_pay.sum}}</td>
                        <td>{{partner_pay.id_partner.yandex}}</td>
                        <td>{{partner_pay.id_partner.qiwi}}</td>
                        <td>{{partner_pay.id_partner.paypal}}</td>
                        <td>{{partner_pay.id_partner.card_number}}</td>
                        <td>{{partner_pay.id_partner.utm}}</td>
                        <td><button @click="payToPartner(partner_pay), partner_pay.paid = 1" class="mb-xs mt-xs mr-xs btn btn-primary" type="button">Выплатить</button></td>
                    </tr>
            </template>
            </tbody>
        </table>

        <div id="loader"><span></span></div>
        <div id="mes-edit"></div>

    </div>
</template>
<script>//

export default {
    created() {
        this.getPartnersPays();
    },
    data() {
        return {
            partners_pays: [],
        }
    },
    props:
        {
            path_axios_get_partners_pays: {
                type: String,
                default: ''
            },
            path_axios_post_pay_to_partner_done: {
                type: String,
                default: ''
            },
        },
    methods: {
        getPartnersPays() {
            axios.get(this.path_axios_get_partners_pays).then(response => {
                this.partners_pays = response.data;
/*                this.partners_pays.forEach(function (item, i) {
                    if (item.paid == '1') console.log(this.partners_pays.length);
//console.log(item.id, item.paid);
                }, this);*/
                //console.log(JSON.stringify(response.data, null, 4))
            })
                .then(response => {
                    this.add_jquery();
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },

      payToPartner(partner_pay)
      {
          axios.post(this.path_axios_post_pay_to_partner_done, {
              data: partner_pay
          })
              .then(function (response) {
                  $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                      $('#mes-edit').delay(1000).fadeOut();
                      //alert(id);
                  });
                  console.log(response);
              })
              .catch(function (error) {
                  console.log(error);
              })
      },

        add_jquery() {

            $('#datatable-details').DataTable({
                paging: true,
                aoColumnDefs: [{
                    bSortable: false,
                    aTargets: [0, 1, 2, 3, 4, 5, 6, 7, 8]
                }],
                aaSorting: [
                    [1, 'desc']
                ]
            });
        },
    },
}

</script>
