/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */



import 'vuetify/dist/vuetify.min.css'

require('./bootstrap');

window.Vue = require('vue');

import Vuetify from 'vuetify'
Vue.use(Vuetify)

require('vue-stash');


import Select2 from 'v-select2-component';
Vue.component('Select2', Select2);

import vSelect from 'vue-select'
Vue.component('v-select', vSelect);
import 'vue-select/dist/vue-select.css';

Vue.component('modal', {
    template: '#modal-template'
});

import VueGallery from 'vue-gallery'
Vue.component('vue-gallery', VueGallery);

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.component('admin_hello', require('./components/admin_hello.vue').default);
Vue.component('admin_add_zakaz', require('./components/admin_add_zakaz.vue').default);
Vue.component('admin_table_tovar', require('./components/admin_table_tovar.vue').default);
Vue.component('admin_table_partners_pays', require('./components/admin_table_partners_pays.vue').default);
Vue.component('admin_table_messages', require('./components/admin_table_messages.vue').default);
Vue.component('admin_table_notes', require('./components/admin_table_notes.vue').default);
Vue.component('admin_provider_table_of_tovars', require('./components/admin_provider_table_of_tovars.vue').default);
Vue.component('function_count_messages', require('./components/function_count_messages.vue').default);
Vue.component('wholesale_add_zakaz', require('./components/wholesale_add_zakaz.vue').default);
Vue.component('wholesale_table_zakaz', require('./components/wholesale_table_zakaz.vue').default);
Vue.component('admin_table_zakaz', require('./components/admin_table_zakaz.vue').default);
Vue.component('operator_table_zakaz', require('./components/operator_table_zakaz.vue').default);
Vue.component('operator_collection_of_orders', require('./components/operator_collection_of_orders.vue').default);
Vue.component('partners_stat', require('./components/partners_stat.vue').default);
Vue.component('partners_table', require('./components/partners_table.vue').default);
Vue.component('partner_table_self_pays', require('./components/partner_table_self_pays.vue').default);
Vue.component('partner_table_of_zakazs', require('./components/partner_table_of_zakazs.vue').default);
Vue.component('partner_table_of_zakazs_self', require('./components/partner_table_of_zakazs_self.vue').default);
Vue.component('partner_dashboard', require('./components/partner_dashboard.vue').default);
Vue.component('courier_my_zakaz', require('./components/courier_my_zakaz.vue').default);
Vue.component('courier_available_zakazs', require('./components/courier_available_zakazs.vue').default);
Vue.component('providers_table', require('./components/providers_table.vue').default);
Vue.component('providers_stat', require('./components/providers_stat.vue').default);
Vue.component('provider_table_of_tovars', require('./components/provider_table_of_tovars.vue').default);
Vue.component('provider_table_of_zakazs', require('./components/provider_table_of_zakazs.vue').default);
Vue.component('provider_add_tovar_to_sklad', require('./components/provider_add_tovar_to_sklad.vue').default);
Vue.component('provider_table_of_pays', require('./components/provider_table_of_pays.vue').default);
Vue.component('provider_self_pays', require('./components/provider_self_pays.vue').default);
Vue.component('admin_mail_test', require('./components/admin_mail_test.vue').default);
Vue.component('cdek_zakazs', require('./components/cdek_zakazs.vue').default);

    const app = new Vue({
        el: '#app',
        data: {
            images: [],
            store: {
                bludoStore: {
                    id: '',
                    name: '',
                    price: '',
                    time: '',
                    weight: '',
                    kkal: '',
                    img: '',
                    id_nacenka: '1',
                    id_complexity: '',
                    id_type_zoys: '',
                    id_type_drv: '',
                    id_author: '1',
                    zip1: '0',
                    zip2: '0',
                    zip3: '0',
                    vakuum1: '0',
                    vakuum2: '0',
                    products: [],
                    ingr: '0',
                    chekedMyaso: '0',
                    chekedRyba: '0',
                    chekedPasta: '0',
                    chekedBezDuhovki: '0',
                    chekedVeg: '0',
                    cheked20min: '0',
                },
                errors1: {
                    errors1: []
                },
                userStore:
                    {
                        bludos: [],
                    },
                range:
                    {
                        date0: '',
                        date1: '',
                    }
            }
        }
    });

/*
window.axios = require('axios');

window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

/*
axios.defaults.headers.common = {
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
}


/*
export default axios.create({
    headers: {
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
});

/*
axios.defaults.headers.common = {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    'X-Requested-With': 'XMLHttpRequest',
};
*/