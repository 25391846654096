<template>
    <div>
        <template v-if="show_sel">
        <v-select :options="providers" @input="show_tovars(selected.id_in_vue)" class="form-control"
                  label="name"
                  placeholder="Введите как минимум 2 символа для поиска"
                  v-model="selected">
        </v-select>
        </template>

        <br>
        <br>

        <template v-if="show">

            <table class="table table-bordered mb-none col-md-3">
                <tbody>
                <tr>
                    <th>Всего отстатков: </th>
                    <td><b>{{all_balance}} шт.</b></td>
                </tr>

                <tr>
                    <th>Всего отправлено: </th>
                    <td><b>{{all_send}} шт.</b></td>
                </tr>

                <tr>
                    <th>Всего продано: </th>
                    <td><b>{{all_sold}} шт.</b></td>
                </tr>

                <tr>
                    <th>Всего поступило: </th>
                    <td><b>{{all_add}} шт.</b></td>
                </tr>

                </tbody>
            </table>
            <p>&nbsp;</p>
        </template>

        <table :id="'datatable-details'" class="table table-bordered mb-none">
            <thead>
            <tr>
                <th style="pointer-events: none; cursor: default;">ID</th>
                <th>Наименование</th>
                <th>Остатки на складе</th>
                <th>Добавить, шт.</th>
                <template v-if="show_sel"><th>Убавить, шт.</th></template>
                <th>Отправлено</th>
                <th>Продано</th>
                <th>Поступило</th>
                <th>Отвязать товар</th>
            </tr>
            </thead>
            <tbody>

            <template v-if="show">
                <template v-for="tovar in tovars_stat1">
            <tr>
                <td>{{tovar.id}}</td>
                <td>{{tovar.name}}</td>
                <td>{{tovar.tov_kolvo}}</td>
                <td><input class="form-control1" @blur="onBlur(tovar)" @focus="onFocus(tovar)" v-model="tovar.add"></td>
                <template v-if="show_sel"><td><input class="form-control1" @blur="onBlur(tovar)" @focus="onFocus(tovar)" v-model="tovar.del"></td></template>
                <td>{{tovar.tov_send}}</td>
                <td>{{tovar.tov_sell}}</td>
                <td>{{tovar.tov_add || 0}}</td>
                <td><input style="width: 30px;" type="checkbox" v-model="tovar.to_reject"></td>
            </tr>
            </template>
            </template>
            </tbody>
        </table>

        <br>
        <div class="form-group">
            <button @click="reject()" class="mb-xs mt-xs mr-xs btn btn-primary col-md-2 col-md-offset-5" type="button">
                Отвязать выбранные заказы
            </button>
        </div>
        <br>
        <br>
        <div class="form-group">
            <label class="col-md-3 control-label">Выбрать промежуток</label>
            <div class="col-md-8">
                <div class="input-daterange col-md-8 col-md-offset-1 input-group" data-plugin-datepicker>
														<span class="input-group-addon">
															<i class="fa fa-calendar"></i>
														</span>
                    <input class="form-control" name="start" id="start" type="text" v-model="start_date">
                    <span class="input-group-addon">до</span>
                    <input class="form-control" name="end" type="text" v-model="end_date">
                </div>
            </div>
        </div>
        <div class="col-md-5"></div>
        <button @click="makeMagic1()" class="mb-xs mt-xs mr-xs btn btn-primary" type="button">Показать</button>

        <div id="loader"><span></span></div>
        <div id="mes-edit"></div>

    </div>
</template>
<script>//

export default {
    created() {
        this.getCurrentUser();
        var date = new Date();
        date.setMonth(date.getMonth() - 1);
        this.start_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        date.setMonth(date.getMonth() + 1);
        this.end_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        localStorage.setItem('date0', this.start_date);
        localStorage.setItem('date1', this.end_date);

    },
    data() {
        return {
            providers: [],
            tovars_stat: [],
            tovars_stat1: [],
            selected: 0,
            show: 0,
            all_balance: 0,
            all_send: 0,
            all_sold: 0,
            all_add: 0,
            currentUser: [],
            show_sel: 0,
            start_date: '',
            end_date: '',
            old_tovar: 0,

        }
    },
    store: ['range'],
    props:
        {

            path_axios_save_updated_tovar_from_provider: {
                type: String,
                default: ''
            },
            path_axios_get_list_provider_for_table_of_tovar: {
                type: String,
                default: ''
            },
            path_axios_get_list_tovar_of_provider_for_table_of_tovar: {
                type: String,
                default: ''
            },
            path_axios_get_current_user: {
                type: String,
                default: ''
            },
            path_axios_post_reject_tovars: {
                type: String,
                default: ''
            }
        },
    methods: {
        makeMagic1() {
//            $('#datatable-details').DataTable().destroy();

            //console.log(localStorage.getItem('date0'));
            //console.log(localStorage.getItem('date1'));

            this.range['date0'] = localStorage.getItem('date0');
            this.range['date1'] = localStorage.getItem('date1');

            var day_start = new Date(localStorage.getItem('date0'));
            //day_start.setDate(day_start.getDate() - 1);
            var day_end = new Date(localStorage.getItem('date1'));

            this.start_date = day_start.getFullYear() + '-' + ('0' + (day_start.getMonth() + 1)).slice(-2) + '-' + ('0' + (day_start.getDate())).slice(-2);
            this.end_date = day_end.getFullYear() + '-' + ('0' + (day_end.getMonth() + 1)).slice(-2) + '-' + ('0' + day_end.getDate()).slice(-2);
            day_end.setDate(day_end.getDate() + 1);

            this.tovars_stat1 = [];
            var temp_mas = [];
            var keys = Object.keys(this.tovars_stat);
            //console.log(JSON.stringify(keys, null, 4));
            for (let i = 0; i < keys.length; i++) {
                //console.log(JSON.stringify(this.partners[keys[i]], null, 4));
                temp_mas = [];
                temp_mas.tov_add = 0;
                temp_mas.tov_sell = 0;
                temp_mas.tov_send = 0;
                temp_mas.tov_kolvo = 0;
                temp_mas.to_reject = false;
                temp_mas.name = 0;
                temp_mas.id = 0;
                temp_mas.add = 0;
                temp_mas.del = 0;
                this.tovars_stat[keys[i]].forEach(function (item1, i) {
                    var mydate = new Date(item1.created_at);
                    var show10 = 0;
                    var show11 = 0;

                    if (this.range['date0'] != 'ull' && day_start >= mydate) show10 = 0; else show10 = 1;
                    if (this.range['date1'] != 'ull' && day_end <= mydate) show11 = 0; else show11 = 1;
                    if (show10 == 1 && show11 == 1)
                    {
                        temp_mas.tov_add += item1.tov_add;
                        temp_mas.tov_sell += item1.tov_sell;
                        temp_mas.tov_send += item1.tov_send;
                    }
                    temp_mas.tov_kolvo = item1.id_tovar.kolvo;
                    temp_mas.name = item1.id_tovar.name;
                    temp_mas.id = item1.id_tovar.id;
                }, this);

                //console.log(JSON.stringify(temp_mas, null, 4));
                this.tovars_stat1.push(temp_mas);
            }   
            //setTimeout(() => this.add_jquery(), 500);
	        this.add_jquery();
            this.show = 1;

        },
        getCurrentUser()
        {
            //console.log(JSON.stringify('response.data', null, 4));
            axios.get(this.path_axios_get_current_user).then(response => {
                //response.data.role == 1 ? item.show = true : item.show = false;
                this.currentUser = response.data;
                this.currentUser.role == 1 ? this.show_sel = true : this.show_sel = false;
                //console.log(JSON.stringify(this.currentUser.role, null, 4));
            })
                .then(response => {
                    this.getProviders();
                    console.log(response);
                })
                .catch(function (error) {
                    //alert(error.response);
                    console.log(error.response);
                });
        },
        getProviders() {
            axios.get(this.path_axios_get_list_provider_for_table_of_tovar).then(response => {
                response.data.forEach(function (item, i) {
                    item.selected = '0';
                    item.id_in_vue = i;
                });
                this.providers = response.data;
                //console.log(JSON.stringify(this.providers, null, 4));
            })
                .then(response => {
                    this.providers.forEach(function (item, i) {
                        if (item.id == this.currentUser.id /*&& !this.show_sel*/) {this.show_tovars(item.id_in_vue);}
                    }, this);
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
        show_tovars(provider_id_in_vue) {
            //console.log(JSON.stringify(this.providers, null, 4));
            axios.post(this.path_axios_get_list_tovar_of_provider_for_table_of_tovar, {
                data: this.providers[provider_id_in_vue].id
            })
                .then(response => {
                this.tovars_stat = response.data;
                    this.all_send = 0;
                    this.all_sold = 0;
                    this.all_add = 0;
                    this.all_balance = 0;

                    var keys = Object.keys(this.tovars_stat);
                    for (let i = 0; i < keys.length; i++) {
                        //console.log(JSON.stringify(this.tovars_stat[keys[i]][0].id_tovar.kolvo, null, 4));
                        this.all_balance += this.tovars_stat[keys[i]][0].id_tovar.kolvo;
                        this.tovars_stat[keys[i]].forEach(function (item1, i) {
                            //console.log(JSON.stringify(item1, null, 4));
                            this.all_add += item1.tov_add;
                            this.all_sold += item1.tov_sell;
                            this.all_send += item1.tov_send;
                        }, this);
                    }
                //console.log(JSON.stringify(this.tovars_stat, null, 4));
            })
                .then(response => {
                    //this.add_jquery();
                    this.makeMagic1();
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },

        add_jquery() {

    	    let dtable = $('#datatable-details');

            if ( $.fn.DataTable.isDataTable('#datatable-details') ) {
                dtable.DataTable().clear().destroy();
            }


            setTimeout(()=> {

            $('#datatable-details').DataTable({
                paging: true,
                aoColumnDefs: [{
                    bSortable: false,
                    aTargets: [0, 1, 2, 3, 4, 5, 6]
                }],
                aaSorting: [
                    [0, 'asc']
                ]
            });
            }, 300);

        },

        reject()
        {
            let goodsArray = [], j=0;
            for (let i in this.tovars_stat) {

                if (this.tovars_stat1[j].to_reject == true) {
                    goodsArray.push({
                        id: i,
                        kolvo: this.tovars_stat1[j].tov_kolvo,         
                    });
                }
                j++;
            }
            if (!goodsArray.length) {
                    $('#mes-edit').text('Объединение не удалось').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                    });
                    return -1;
            }
            axios.post(this.path_axios_post_reject_tovars, {
                data: { 
                    goods : goodsArray,
                    provider: this.providers[this.selected.id_in_vue],
                }
            })
                .then(function (response) {
                    // handle success
                    $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                        //alert(id);
                    });
                    location.reload();
                    console.log(response);
                })
                .catch(function (error) {
                    // handle error
                    $('#mes-edit').text('Объединение не удалось').delay(500).fadeIn(1000, function () {
                        $('#mes-edit').delay(1000).fadeOut();
                        //alert(id);
                    });
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
        },

        onFocus(tovar)
        {
            this.old_tovar = tovar.add - tovar.del;
            //console.log(this.old_tovar);
        },

        onBlur(tovar) {

            let id = 0;
            if (this.selected == 0) id = this.currentUser.id;
            else id = this.selected.id;

            //console.log(id);

            var send = {
                id: tovar.id,
                kolvo: tovar.add,
                del: tovar.del,
                id_backend_users: id,
            };

            if (this.old_tovar != tovar.add - tovar.del)

                axios.post(this.path_axios_save_updated_tovar_from_provider, {
                    data: send
                })
                    .then(response => {
                        // handle success
                        //console.log(this.zakazs[id]);
                        //alert(this.zakazs[id]);
                        $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                            $('#mes-edit').delay(1000).fadeOut();
                            //alert(id);
                        });
                        $('#datatable-details').DataTable().destroy();

                        if (this.selected == 0) {
                            this.providers.forEach(function (item, i) {
                                if (item.id == this.currentUser.id) {this.show_tovars(item.id_in_vue);}
                            }, this);
                        }
                        else this.show_tovars(this.selected.id_in_vue);

                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        $('#mes-edit1').text('Проверьте правильность заполнения полей').delay(500).fadeIn(1000, function () {
                            $('#mes-edit1').delay(1000).fadeOut();
                            //alert(id);
                        });
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
        },
    },
}

</script>
