<template>
    <div>

        <v-select :options="providers" class="form-control"
                  label="name"
                  placeholder="Введите как минимум 2 символа для поиска"
                  v-model="selected">
        </v-select>

        <br>
        <br>
        <div class="panel-body">
            <div class="col-md-6">
            <span class="show-grid-block">Выбрать товар <span
                    class="required">*</span>
                            <div class="input-group btn-group">
                                                <span class="input-group-addon">
														<i class="fa fa-shopping-cart"></i>
													</span>

                                 <v-select :options="products" class="form-control" label="name"
                                           placeholder="Введите как минимум 2 символа для поиска"
                                           v-model="selected1">
                                 </v-select>

                            </div>
                            </span>
            </div>

            <div>
            <span class="show-grid-block">Количество<div>
                                <div class="input-group" style="width:150px;">
                                    <input :value="kolvo" class="spinner-input form-control" maxlength="3" readonly type="text">
                                    <div class="spinner-buttons input-group-btn">
                                        <button @click="plus()" class="btn btn-default spinner-up" type="button">
                                            <i class="fa fa-angle-up"></i>
                                        </button>

                                         <button @click="minus()" class="btn btn-default spinner-down" type="button">
                                            <i class="fa fa-angle-down"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </span>
            </div>
        </div>

        <div class="panel-body">
            <div class="col-md-3">
                <button @click="save()" class="mb-xs mt-xs mr-xs btn btn-primary" type="button">Сохранить изменения</button>
            </div>
        </div>

        <div id="loader"><span></span></div>
        <div id="mes-edit"></div>
        <div id="mes-edit1"></div>

    </div>
</template>
<script>//

export default {
    created() {

        this.getProviders();
        this.getProducts();
        //this.add_jquery();

    },
    data() {
        return {
            providers: [],
            tovars: [],
            selected: 0,
            selected1: 0,
            products: [],
            kolvo: 1,
            saved: {
                id_backend_users: '',
                id_tovar: '',
                tov_add: ''
            },
        }
    },
    props:
        {

            path_axios_get_list_provider: {
                type: String,
                default: ''
            },

            path_axios_get_list_of_products_to_add: {
                type: String,
                default: ''
            },

            path_axios_save_tovar_provider: {
                type: String,
                default: ''
            },
        },
    methods: {
        getProviders() {
            axios.get(this.path_axios_get_list_provider).then(response => {
                response.data.forEach(function (item, i) {
                    item.selected = '0';
                    item.id_in_vue = i;
                });
                this.providers = response.data;
            })
                .then(function (response) {
                    //this.plus_minus();
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
        getProducts() {
            axios.get(this.path_axios_get_list_of_products_to_add).then(response => {
                this.products = response.data;
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        },
        plus() {
            this.kolvo++;
        },

        minus() {
            if (this.kolvo > 0)
                this.kolvo--;
        },
        save() {
            //this.saved.push({id_backend_users: this.selected.id, id_tovar: this.selected1.id, tov_send: this.kolvo});
            //console.log(this.saved);
            //console.log(JSON.stringify(this.saved, null, 4));
            if (!this.kolvo || !this.selected1.name || !this.selected.name) $('#mes-edit1').text('Заполните все поля').delay(500).fadeIn(1000, function () {
                $('#mes-edit1').delay(1000).fadeOut();
            });

            else {

                this.saved.id_backend_users = this.selected.id;
                this.saved.id_tovar = this.selected1.id;
                this.saved.tov_add = this.kolvo;
                axios.post(this.path_axios_save_tovar_provider, {
                    data: this.saved
                })
                    .then(function (response) {
                        // handle success
                        $('#mes-edit').text('Изменения сохранены').delay(500).fadeIn(1000, function () {
                            $('#mes-edit').delay(1000).fadeOut();
                            //alert(id);
                        });
                        console.log(response);
                    })
                    .catch(function (error) {
                        // handle error
                        $('#mes-edit1').text('Что-то пошло не так').delay(500).fadeIn(1000, function () {
                            $('#mes-edit1').delay(1000).fadeOut();
                            //alert(id);
                        });
                        console.log(error);
                    })
                    .finally(function () {
                        // always executed
                    });
            }
        },
    },
}

</script>
